import React from 'react';
import { Container, Row, Col, Card, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Banner from '../Assests/banner.jpeg';
import Header from './Header';
import { connect } from 'react-redux';

class Home extends React.Component {
    state = {
        featured: [],
        isOpen: false,
        locations: {},
        distance: undefined,
        loading: false,
    };

    getRadius = () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/delivery-charges`,
        };

        axios(config).then((response) => {
            this.props.deliveryHandler(response.data.response.data);
        })
            .catch(function (error) {
                console.log(error);
            });

    }

    componentDidMount() {
        this.getFeatured();
        this.getRadius();
    }

    getFeatured = async () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/featured/products`,
            headers: {
                Accept: 'application/json',
            },
        };

        const response = await axios(config);
        const quantitySet = response.data.response.data.products
            .filter((filter) => filter.category !== "Pizza" && filter.category !== "Stromboli").map((i) => {
                return Object.assign({}, i, { basePrice: i.price ? i.price : i.smallPrice, quantity: 1, price: i.price ? i.price : i.smallPrice, size: "Small Size" });
            });

        const pizza = response.data.response.data.products
            .filter((filter) => filter.category === "Pizza").map((i) => ({
                ...i,
                groupsIngredients: i.groupsIngredients.map((ing) => ({ ...ing, addedIngredients: ing.ingredientsItems.filter((filter) => filter.isSelected) })),
                price: i.smallPrice,
                basePrice: i.smallPrice,
                quantity: 1,
                size: 'Small Size',
            }));

        const pizzaAdded = pizza.map((i) => ({
            ...i,
            groupsIngredients: i.groupsIngredients.map((f) => ({
                ...f,
                addedIngredients: f.addedIngredients.map((ing) => ({
                    ...ing,
                    size: f.name === 'Choice of Sauce' ? 'Medium Size' : f.name === 'Cheese' ? 'Medium Size' : f.name === 'Meats' ? 'Small Size' : f.name === 'Veggies' ? 'Small Size' : ing.size,
                    topping: f.name === 'Veggies' || 'Meats' ? 'whole' : null,
                })),
            })),
        }));

        const stromboli = response.data.response.data.products
            .filter((filter) => filter.category === "Stromboli").map((i) => ({
                ...i,
                groupsIngredients: i.groupsIngredients.map((ing) => ({ ...ing, type: ing.name === 'Choice of Ingredients' ? 'PreSelected' : 'AddExtra' })),
                groupsIngredients: i.groupsIngredients.map((ing) => ({ ...ing, addedIngredients: ing.ingredientsItems.filter((filter) => filter.isSelected) })),
                basePrice: i.price,
                quantity: 1,
                size: 'Small Size',
            }));

        const stromboliAdded = stromboli.map((i) => ({
            ...i,
            groupsIngredients: i.groupsIngredients.map((f) => ({
                ...f,
                addedIngredients: f.addedIngredients.map((ing) => ({
                    ...ing,
                    size: f.name === 'Choice of Sauce' ? 'Medium Size' : f.name === 'Cheese' ? 'Medium Size' : 'Small Size',
                })),
            })),
        }));

        let data = quantitySet.concat(pizzaAdded, stromboliAdded);

        this.setState({ featured: data, loading: true });
    };
    customHandler = (i) => {
        this.props.productHandler([i]);
        if (i.category === "Stromboli") {
            this.props.history.push(`/stromboli_maker`);
        }
        else if (i.category === "Pizza") {
            this.props.history.push(`/pizza_maker`);
        }
        else {
            this.props.history.push(`/item/${i.name.replace(/\s+/g, '_').toLowerCase()}`);
        }
    };

    StoreHandler = (value) => {
        if (this.props.token) {
            window.localStorage.setItem("delivery", value);
        } else {
            window.sessionStorage.setItem("delivery", value);
        }
    }

    distanceHandler = (distanceItem) => {
        this.setState(({ distance }) => ({ distance: { ...distance, distanceItem } }));
    }
    render() {
        return (
            <div>
                <Header props={this.props} />
                <Container>
                    <Row className="mt-4">
                        <img src={Banner} className="heroImg" />
                    </Row>
                    <Row>
                        <Row className="mt-4">
                            <div className="d-flex featured">
                                <h2>Featured</h2>
                            </div>
                        </Row>
                        {this.state.featured.map((i, k) => {
                            return (
                                <Col sm="12" lg="6" xl="4" className="respFeature mb-4" key={k} onClick={() => this.customHandler(i)}>
                                    <Card style={{ cursor: 'pointer' }}>
                                        <img className="card-img-top" src={i.image} alt="Card image cap" />
                                        <CardBody>
                                            <h5 className="card-title">{i.name}</h5>
                                            <span>Carryout</span>
                                        </CardBody>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
                <Modal isOpen={this.state.isOpen}>
                    <ModalHeader>
                        <h3>Find A Store To Get Started</h3>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm="6" onClick={() => this.StoreHandler(true)} role="button">
                                <h2>Delivery</h2>
                            </Col>
                            <Col sm="6" onClick={() => this.StoreHandler(false)} role="button">
                                <h2>Carryout</h2>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        Menu: state.Pizza.Menu,
        productId: state.Pizza.productId,
        cart: state.Pizza.cart,
        token: state.Pizza.token,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        cartHandler: (value) => dispatch({ type: 'CART', value: value }),
        productHandler: (value) => dispatch({ type: 'PRODUCT', value: value }),
        deliveryHandler: (value) => dispatch({ type: 'DELIVERY', value: value }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
