import React from 'react';
import { Row, Col, Spinner, Button } from 'reactstrap';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
const Orders = (props) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(10);

  const getOrders = async () => {
    var axios = require('axios');

    setLoading(false);

    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/v1/user/orders`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props.token.jwt}`,
      },
    };

    const response = await axios(config);
    setData(response.data.response.data.orders);
    setLoading(true);
  };
  React.useEffect(() => {
    if (props.token.jwt) {
      getOrders();
    }
    return () => {
      setData([]);
    };
  }, [props.token.jwt]);

  const reOrderHandler = (i) => {
    const orders = i.items
      .map((items) => ({ ...items, groupsIngredients: items.groupName.map((ing) => ({ ...ing, addedIngredients: ing.ingredients.map((add) => add) })) }))
      .map((n) => ({ ...n, name: n.productName }))
      .map((n) => ({ ...n, image: n.productImage }))
      .map((n) => ({ ...n, size: n.productSize }))
      .map((n) => ({ ...n, price: n.productPrice * n.quantity }))
      .map((n) => ({ ...n, basePrice: n.productPrice }))
      .map((n) => ({ ...n, category: n.product.category }));

    const cart = props.cart;

    let result = cart.filter(o1 => orders.some(o2 => o1.productName === o2.productName));

    if (cart.length === 0) {
      props.cartHandler(orders);
      toast.success(`Order Added To Cart`);
    } else {
      if (!result[0]) {
        const data1 = cart.concat(orders);
        props.cartHandler(data1);
        toast.success(`Order Added To Cart`);
      } else {
        toast.success(`Already Added To Cart`);
      }
    }
  };

  const pageHandler = React.useCallback(() => {
    if (page < data.length) {
      setPage(page + 10);
    } else if (page > data.length) {
      setPage(10);
    }
  }, [page, setPage, data]);

  return (
    <React.Fragment>
      {loading
        ? <Row>
          {data
            .filter((f) => f.items.length)
            .sort((a, b) => b.orderNo - a.orderNo)
            .splice(0, page)
            .map((i, k) => {
              return (
                <div key={k} className="cartProducts orders mt-2">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4>Order No #{i.orderNo}&nbsp;<span style={{ padding: "0px 5px", background: "#ffc000", borderRadius: 10, color: "#fff" }}>{i.status}</span></h4>
                    <h4>Total ${i.subTotal}</h4>
                  </div>

                  {i.items.map((item, itemKey) => {
                    return (
                      <Row key={itemKey} className="pb-2">
                        <Col sm="3">
                          <img src={item.productImage} style={{ width: '100%', height: '100px', objectFit: 'cover', borderRadius: '10px' }} />
                        </Col>
                        <Col sm="6">
                          <div>
                            <h4>{item.productName}</h4>
                            {item.groupName.map((main) => {
                              return main.ingredients?.map((ing, ingKey) => {
                                return (
                                  <span style={{ fontSize: '14px', color: '#677078' }} className="item" key={`demo_snap_${ingKey}`}>
                                    {ing.ingredientName}
                                  </span>
                                );
                              });
                            })}
                          </div>
                        </Col>
                        <Col sm="3">
                          <div>
                            <h4>${Number(item.productPrice).toFixed(2)}</h4>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  {!i.isDealOrder ?
                    <span style={{ color: '#ffc000', cursor: 'pointer', float: 'right', fontSize: '20px', fontWeight: 'bold' }} onClick={() => reOrderHandler(i)}>
                      Re Order
                    </span>
                    : null}
                </div>
              );
            })}
        </Row>
        : <div className="text-center mt-4">
          <Spinner style={{ color: "#ffc000" }} />
        </div>
      }
      {loading && data.length > 10 && <div className="text-center">
        <Button
          style={{ backgroundColor: "#ffc000", borderColor: "#ffc000" }}
          className="w-25"
          onClick={() => pageHandler()}
        >
          {page < data.length ? "Show More" : "Show Less"}
        </Button>
      </div>}

    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.Pizza.token,
    cart: state.Pizza.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cartHandler: (value) => dispatch({ type: 'CART', value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
