import React from 'react';
import { Container, Spinner, FormGroup } from 'reactstrap';
import { ChevronDown, ShoppingCart, X, MapPin, ChevronRight, ChevronUp, User, Zap, Truck, ShoppingBag } from 'react-feather';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import toast from 'react-hot-toast';

import Logo from '../Assests/logo.png';

let schema = yup.object().shape({
    address: yup.string().required("Address is required!"),
    apartment: yup.string().nullable("Apartment is required!"),
    city: yup.string().required("City is required!"),
    state: yup.string().required("State is required!"),
    zip: yup.string().test('len', 'Must be exactly 5 characters', val => val?.length === 5).required("Zip Code is required!"),
});
const Header = (props) => {
    const [cat, setCat] = React.useState([]);
    const [Loader, setLoader] = React.useState(false);
    const [address, setAddress] = React.useState(false);
    const [option, setOption] = React.useState(props.option);
    const [addressField, setAddressField] = React.useState("");
    const [city, setCity] = React.useState("");
    const [street, setStreet] = React.useState("");
    const [state, setState] = React.useState("");
    const [zip, setZip] = React.useState("");
    const [lat, setLat] = React.useState("");
    const [lang, setLang] = React.useState("");
    const [time, setTime] = React.useState("");
    const [store, setStore] = React.useState({});

    let autocomplete = null;

    const getCat = async () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/categories`,
            headers: {
                Accept: 'application/json',
            },
        };

        const Response = await axios(config);
        const items = Response.data.response.data.categories.filter((f) => f.categoryId !== 13);
        setCat(items);
    };

    const storeTiming = async () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/store-timing`,
            headers: {
                'Accept': 'application/json'
            }
        };

        const response = await axios(config);
        setStore(response.data.response.data.storeTime);

    }

    React.useEffect(() => {
        getCat();
        storeTiming();
    }, []);

    const logoutHandler = () => {
        setLoader(true);
        props.tokenHandler([]);
        props.signHandler(false);
        props.props.history.push('/');
        props.cartHandler([]);
        setLoader(false);
    };

    const optionHandler = React.useCallback((value) => {
        if (value === "DELIVERY") {
            setOption("DELIVERY");
            props.deliverOptionHandler("DELIVERY");
        }
        else {
            setOption("CARRYOUT");
            props.deliverOptionHandler("CARRYOUT");
        }
    }, [props.deliverOptionHandler]);

    const Addresshandler = React.useCallback((values, actions) => {
        if (props.user.jwt) {
            var axios = require('axios');
            var data = new FormData();
            data.append('address', values.address);
            data.append('apartment', values.apartment);
            data.append('city', values.city);
            data.append('state', values.state);
            data.append('zipCode', values.zip);
            data.append('lat', lat);
            data.append('lng', lang);
            data.append('lng', lang);
            data.append('deliveryTime', time);

            var config = {
                method: 'post',
                url: '${process.env.REACT_APP_BASE_URL}v1/user/profile/update',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${props.user.jwt}`,
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    actions.setSubmitting(false);
                    toast.success("Address Updated");
                    setAddress(false);
                    bodyScrollOn();
                    const token = { jwt: props.user.jwt }
                    const returnedTarget = Object.assign(response?.data?.response?.data?.auth, token);
                    props.tokenHandler(returnedTarget);
                })
        } else {
            const user = {
                street: street,
                apartment: values.apartment,
                city: values.city,
                state: values.state,
                zipCode: values.zip,
                address: addressField !== "" ? addressField : values.address,
                lat: lat,
                lang: lang,
                time: time

            }
            window.sessionStorage.setItem("user", JSON.stringify(user));
            setAddress(false);
            bodyScrollOn();
        }

    }, [props, setAddress, street, addressField, lang, lat, time]);

    const bodyScroll = () => {
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
    };

    const bodyScrollOn = () => {
        document.body.style.overflow = "auto";
    };

    const getLocation = React.useCallback((e) => {
        e.preventDefault();
        const input = document.getElementById("pac-input");
        const options = {
            types: ['address'],
        };
        // eslint-disable-next-line no-undef
        let auto_complete = new google.maps.places.Autocomplete(
            input,
            options,
        );

        auto_complete.setFields(['address_components', 'name', "geometry"]);

        auto_complete.addListener('place_changed', () => {
            autocomplete = auto_complete.getPlace();
            if (autocomplete) {
                checkAddress(autocomplete);
            }
        });

    }, [autocomplete]);

    const checkAddress = React.useCallback(async (autocomplete) => {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()

        var start = '39.27574376183423, -77.52916387534584';
        var end = `${autocomplete.geometry.location.lat()}, ${autocomplete.geometry.location.lng()}`;

        const results = await directionsService.route(
            {
                origin: start,
                destination: end,
                travelMode: "DRIVING",
                // eslint-disable-next-line no-undef
                unitSystem: google.maps.UnitSystem.METRIC
            }
        )
        const address = results.routes[0].legs[0].distance.value;
        const miles = address * 0.000621;

        if (miles < 10) {
            let address = autocomplete.address_components;
            const street = address.filter((i) => i.types[0] === "street_number");
            const route = address.filter((i) => i.types[0] === "route");
            const city = address.filter((i) => i.types[0] === "locality" || i.types[0] === "sublocality_level_1");
            const state = address.filter((i) => i.types[0] === "administrative_area_level_1");
            const zip = address.filter((i) => i.types[0] === "postal_code");
            setAddressField(autocomplete.name);
            setStreet(`${route[0]?.long_name} ${street[0]?.long_name}`);
            setCity(city[0]?.long_name);
            setState(state[0]?.long_name);
            setZip(zip[0]?.short_name);
            setLang(autocomplete.geometry.location.lng());
            setLat(autocomplete.geometry.location.lat());
            setTime(results.routes[0].legs[0].duration.text);
        } else {
            toast.error("Sorry we don’t deliver in your area but you can place an order for pickup");
        }

    }, [props, autocomplete, setTime]);

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const addressRender = React.useMemo(() => {
        if (props.user.address) {
            return `${props.user.address} (${props.user.deliveryTime})`
        } else if (JSON.parse(window.sessionStorage.getItem("user"))) {
            return `${JSON.parse(window.sessionStorage.getItem("user")).address} (${JSON.parse(window.sessionStorage.getItem("user")).time})`;
        } else {
            return "1595 B. Bowis Dr Point of Rocks, MD 21777";
        }
    }, [props.user, window.sessionStorage.getItem("user")]);

    return (
        <div>
            <div className="header">
                <Container className="d-flex" style={{ height: '100%' }}>
                    <div className="menuLeft">
                        <Link to="/" className="p-0">
                            <img src={Logo} style={{ marginRight: '24px', height: '50px' }} />
                        </Link>

                        <span>
                            <Link style={{ color: '#000', textDecoration: 'none' }} to="/Deals">
                                Deals
                            </Link>
                        </span>
                        <div className="dropdown">
                            <span className="dropbtn">Menu <ChevronDown /></span>
                            <div className="dropdown-content">
                                {cat.map((i, k) => {
                                    return (
                                        <Link key={k} className="d-flex" to={`/menu/${i.categoryTitle.replace(/\s+/g, '_').toLowerCase()}`} style={{ textDecoration: 'none' }} onClick={() => props.productIdHandler(i.categoryId)}>
                                            <li className="d-flex justify-content-between">{i.categoryTitle} <ChevronRight style={{ color: "#000" }} /></li>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="menuRight">
                        <div className="d-none d-lg-flex addresDesktop" role="button" onClick={() => {
                            setAddress(true);
                            bodyScroll();
                        }}>
                            <div className="d-flex justify-content-center align-items-center" style={{ paddingLeft: 5 }}>
                                {option === "CARRYOUT" ? <ShoppingBag style={{ color: "#ffc000" }} /> : <Truck style={{ color: "#ffc000" }} />}&nbsp;
                            </div>
                            <div style={{ padding: "10px" }}>
                                <span className="d-block text-center" style={{ fontSize: "12px" }}>
                                    {props.option} {store?.offOnDay === "On" ? "Open" : "Closed"} {store?.openTime} - {store?.closeTime}
                                </span>
                                <span className="d-block text-center " style={{ fontSize: "12px" }}>{addressRender}</span>
                            </div>
                            <div className='addresDesktopInner'>
                                <ChevronRight style={{ color: "#fff" }} />
                            </div>
                        </div>
                        {props.signedIn ? (
                            <div className="signIn">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {props.user.image ? (
                                        <img src={props.user.image} style={{ width: '40px', height: '40px', margin: '0 auto', borderRadius: '50%', objectFit: 'cover' }} />
                                    ) : (
                                        <img src={User} style={{ width: '30px', margin: '0 auto' }} />
                                    )}
                                    <div className="dropdown2">
                                        <span>
                                            {props.user.firstName ? props.user.firstName.concat(' ', props.user.lastName) : null} <ChevronDown style={{ color: '#ffc000' }} />
                                        </span>
                                        <div className="dropdown-content2">
                                            <li
                                                onClick={() => props.props.history.push({
                                                    pathname: '/dashboard',
                                                    state: { some: 'Profile' }
                                                })}
                                            >
                                                Profile
                                            </li>
                                            <li onClick={() => props.props.history.push({
                                                pathname: '/dashboard',
                                                state: { some: 'Orders' }
                                            })}
                                            >
                                                Orders
                                            </li>
                                            <li>
                                                {Loader
                                                    ? <Spinner style={{ color: "#ffc000" }} />
                                                    : <span onClick={() => logoutHandler()}>Logout</span>
                                                }
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Link to="/sign_in" className="signIn">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ width: '30px', margin: '0 auto', }}>
                                        <User />
                                    </div>
                                    {/* <img src={User} style={{ width: '30px', margin: '0 auto' }} /> */}
                                    <label style={{ cursor: 'pointer' }}><b>Sign In/Join</b></label>
                                </div>
                            </Link>
                        )}
                        <Link style={{ color: '#000', textDecoration: 'none' }} to="/cart">
                            <div className="credit">
                                <span className="carts">{props.cart?.length}</span>
                                <ShoppingCart />
                                <span>${props.cart?.reduce((a, b) => +a + +b.price, 0).toFixed(2)}</span>
                            </div>
                        </Link>
                    </div>
                </Container>
                {address && <div className="overLayCustom"></div>}
                {
                    address &&
                    <div className="addressMenu">
                        <div className="d-flex align-items-center justify-content-between p-2">
                            <h2>Start Here</h2>
                            <X role="button" onClick={() => {
                                setAddress(false);
                                bodyScrollOn();
                            }} />
                        </div>
                        <div className="p-4">
                            <div className="d-flex m-2 justify-content-between">
                                <div className={`${option === "CARRYOUT" && "active-option"} option`} onClick={() => optionHandler("CARRYOUT")}>
                                    <span>CARRYOUT</span>
                                </div>
                                <div className={`${option === "DELIVERY" && "active-option"} option`} onClick={() => optionHandler("DELIVERY")}>
                                    <span>DELIVERY</span>
                                </div>
                            </div>
                            <div>
                                {option === "CARRYOUT"
                                    ? <div>
                                        <b className="d-block">1595 B. Bowis Dr Point of Rocks, MD 21777</b>
                                        <a href="tel:301-874-8100">
                                            <b>301-874-8100</b>
                                        </a>
                                    </div>
                                    : option === "DELIVERY" ? <Formik
                                        initialValues={{
                                            address: addressField ? addressField : props.user.address,
                                            apartment: props.user.apartment === undefined || props.user.apartment === "null" ? "" : props.user.apartment,
                                            city: city ? city : props.user.city,
                                            state: state ? state : props.user.state,
                                            zip: zip ? zip : props.user.zipCode
                                        }}
                                        validationSchema={schema}
                                        validateOnChange={false}
                                        onSubmit={(values, actions) => {
                                            Addresshandler(values, actions);
                                        }}
                                        enableReinitialize
                                    >
                                        {({ errors, touched, values, handleChange, isSubmitting, setFieldValue }) => (
                                            <Form className="registerForm" onKeyDown={onKeyDown}>
                                                <label>Address</label>
                                                <FormGroup className="mb-2">
                                                    <Field name="address" id="pac-input"
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            getLocation(e);
                                                            setFieldValue("address", e.target.value)
                                                        }}
                                                        type="text" className={`form-control ${errors.address && touched.address && 'is-invalid'}`} />
                                                    {errors.address && touched.address ? <div className="text-danger">{errors.address}</div> : null}
                                                </FormGroup>
                                                <label>Apartment</label>
                                                <FormGroup className="mb-2">
                                                    <Field name="apartment" value={values.apartment} onChange={handleChange} type="text" className={`form-control ${errors.apartment && touched.apartment && 'is-invalid'}`} />
                                                    {errors.apartment ? <div className="text-danger">{errors.apartment}</div> : null}
                                                </FormGroup>
                                                <label>City</label>
                                                <FormGroup className="mb-2">
                                                    <Field onChange={(e) => setCity(e.target.value)} name="city" type="text" value={values.city} className={`form-control ${errors.city && touched.city && 'is-invalid'}`} />
                                                    {errors.city ? <div className="text-danger">{errors.city}</div> : null}
                                                </FormGroup>
                                                <label>State</label>
                                                <FormGroup className="mb-2">
                                                    <Field onChange={(e) => setState(e.target.value)} name="state" type="text" value={values.state} className={`form-control ${errors.state && touched.state && 'is-invalid'}`} />
                                                    {errors.state ? <div className="text-danger">{errors.state}</div> : null}
                                                </FormGroup>
                                                <label>ZIP</label>
                                                <FormGroup className="mb-2">
                                                    <Field onChange={(e) => setZip(e.target.value)} name="zip" type="text" value={values.zip} className={`form-control ${errors.zip && touched.zip && 'is-invalid'}`} />
                                                    {errors.zip && touched.zip ? <div className="text-danger">{errors.zip}</div> : null}
                                                </FormGroup>
                                                <div style={{ textAlign: 'center' }}>
                                                    <button type="submit" className="cartOrder w-100" disabled={Loader}>
                                                        {!isSubmitting ? 'Submit' : <Spinner style={{ color: '#fff' }} />}
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                        : null}
                            </div>
                        </div>

                    </div>
                }
            </div>
            <div className="d-lg-none d-flex addresDesktop mobileAddress" role="button" onClick={() => {
                setAddress(true);
                bodyScroll();
            }}>
                <div className="d-flex justify-content-center align-items-center" style={{ paddingLeft: 5 }}>
                    {option === "CARRYOUT" ? <ShoppingBag style={{ color: "#ffc000" }} /> : <Truck style={{ color: "#ffc000" }} />}&nbsp;
                </div>
                <div style={{ padding: "10px" }}>
                    <span className="d-block text-center" style={{ fontSize: "12px" }}>
                        {props.option} {store?.offOnDay === "On" ? "Open" : "Closed"} {store?.openTime} - {store?.closeTime}
                    </span>
                    <span className="d-block text-center " style={{ fontSize: "12px" }}>1595 B. Bowis Dr Point of Rocks, MD 21777</span>
                </div>
                <div className='addresDesktopInner'>
                    <ChevronRight style={{ color: "#fff" }} />
                </div>
            </div>
        </div>

    );
};

const MapStateToProps = (state) => {
    return {
        signedIn: state.Pizza.signedIn,
        user: state.Pizza.token,
        cart: state.Pizza.cart,
        option: state.Pizza.option,
        delivery: state.Pizza.delivery
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        menuHandeler: (value) => dispatch({ type: 'MENU', value: value }),
        productIdHandler: (value) => dispatch({ type: 'PRODUCTID', value: value }),
        tokenHandler: (value) => dispatch({ type: 'TOKEN', value: value }),
        signHandler: (value) => dispatch({ type: 'SIGNED', value: value }),
        deliverOptionHandler: (value) => dispatch({ type: 'OPTION', value: value }),
        cartHandler: (value) => dispatch({ type: 'CART', value: value }),
    };
};

export default connect(MapStateToProps, mapDispatchToProps)(Header);