import React, { useState } from 'react';
import { Container, Row, Spinner, Col } from 'reactstrap';
import { AlertCircle, X } from 'react-feather';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Header from './Header';
const Subs = (props) => {
    const [desc, setdesc] = useState(-1);
    const [pizza, setPizza] = useState([]);
    const [, setPizzaFilter] = useState([]);
    const [loader, setLoader] = useState(false);

    const getProduct = async () => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/categories/6/products`,
            headers: {
                Accept: 'application/json',
            },
        };

        const Response = await axios(config);
        const types = Response.data.response.data.products.map((i) => ({
            ...i,
            groupsIngredients: i.groupsIngredients.map((ing) => ({
                ...ing,
                addedIngredients: ing.ingredientsItems.filter((filter) => filter.isSelected),
            })),
        }));
        const quantitySet = types.map((i) => {
            return Object.assign({}, i, { basePrice: i.price ? i.price : i.smallPrice, quantity: 1, price: i.smallPrice });
        });
        setPizza(quantitySet);
        setPizzaFilter(quantitySet);
        setLoader(true);
    };
    React.useEffect(() => {
        if (props.productId) {
            getProduct();
        }
    }, [props.productId]);

    const addtocartHandler = (i) => {
        const data = [...pizza];
        const filterIngedeints = data.filter((f) => {
            return f.name === i.name;
        });

        const cutCheck = i.isHotCold && filterIngedeints.map((f) => f.groupsIngredients.filter((f) => f.name === "Choose One"));
        const cut = i.isHotCold && cutCheck[0][0]?.addedIngredients.length;
        const sameProduct = props.cart.map((item) => item.id === i.id);
        const sameProductCount = sameProduct.filter(Boolean);

        if (i.isHotCold) {
            if (cut) {
                if (i.size) {
                    const object = Object.assign({ productName: `${i.name}${sameProductCount.length}` }, i);
                    props.cartHandler(props.cart.concat(object));
                    toast.success(`${i.name} added in the cart`);
                } else {
                    toast.error('Please Select Size');
                }
            } else {
                toast.error('Please Select Cut');
            }
        } else {
            if (i.size) {
                const object = Object.assign({ productName: `${i.name}${sameProductCount.length}` }, i);
                props.cartHandler(props.cart.concat(object));
                toast.success(`${i.name} added in the cart`);
            } else {
                toast.error('Please Select Size');
            }
        }
    };

    const sizeHandler = (e) => {
        const data = [...pizza];
        const quantitySet = data.map((i) => {
            if (i.name == e.target.name)
                return Object.assign({}, i, {
                    size: e.target.value,
                    price: e.target.value === '8″' ? i.smallPrice * i.quantity : e.target.value === '12″' ? i.mediumPrice * i.quantity : null,
                    basePrice: e.target.value === '8″' ? i.smallPrice : e.target.value === '12″' ? i.mediumPrice : null,
                });
            return i;
        });
        setPizza(quantitySet);
    };
    const customHandler = (i) => {
        const sameProduct = props.cart.map((item) => item.id === i.id);
        const sameProductCount = sameProduct.filter(Boolean);
        const object = Object.assign({ productName: `${i.name}${sameProductCount.length}` }, i);
        props.productHandler([object]);
        props.history.push(`/item/${i.name.replace(/\s+/g, '_').toLowerCase()}`);
    };
    const qunatityHandler = (e) => {
        const data = [...pizza];
        const filterIngedeints = data.filter((f) => {
            return f.name === e.target.name;
        });
        const add = filterIngedeints.map((add) => ({ ...add, quantity: e.target.value, price: e.target.value * add.basePrice }));
        var editedUser = add[0];
        const users = pizza.map((u) => (u.id !== add[0].id ? u : editedUser));
        setPizza(users);
    };

    const cutHandler = (e) => {
        const data = [...pizza];
        const filter = data.filter((f) => f.name === e.target.name);

        const group = filter.map((i) => {
            return i.groupsIngredients.filter((filter) => {
                return filter.name === 'Choose One';
            });
        });
        const removedIngredient = group[0].map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === e.target.value) }));
        var editedUser = removedIngredient[0];
        const users = filter.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
        var editedUser = users[0];
        const users2 = pizza.map((u) => (u.id !== editedUser.id ? u : editedUser));
        setPizza(users2);
    };

    return (
        <React.Fragment>
            <Header props={props} />
            {loader ? (
                <Container>
                    <Row className="mt-3">
                        <h1 style={{ fontWeight: '600' }}>Subs</h1>
                    </Row>
                    <Row>
                        <Row>
                            {pizza.map((i, k) => {
                                return (
                                    <Col sm="6" className="mt-3 position-relative" key={k}>
                                        <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                                            <Col sm="12" md="12" lg="6">
                                                <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            </Col>
                                            <Col sm="12" md="12" lg="6">
                                                <div style={{ paddingLeft: '10px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <label>{i.name}</label>
                                                        <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                                                    </div>

                                                    <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                                    <p>{i.detail}</p>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="MenuInner">
                                            <Col sm="12" md="12" lg="8" className="p-0">
                                                <label>{i.name}</label>
                                                <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                                <div className="form-group mt-2">
                                                    <select className="form-control productSelect" name={i.name} onChange={(e) => sizeHandler(e)}>
                                                        <option className="d-none">Size</option>
                                                        <option value="8″">8″ ${i.smallPrice}</option>
                                                        <option value="12″">12″ ${i.mediumPrice}</option>
                                                    </select>
                                                </div>
                                                {i.isHotCold && <div className="form-group mt-2">
                                                    <div className="mt-3">
                                                        <select className="form-control productSelect" name={i.name} onChange={(e) => cutHandler(e)}>
                                                            <option className="d-none">Choose Cut</option>
                                                            {i.groupsIngredients.map((group) => group.name === "Choose One" && group.ingredientsItems.map((ing, key) => {
                                                                return <option value={ing.name} key={key}>{ing.name}</option>
                                                            }))}
                                                        </select>
                                                    </div>
                                                </div>}
                                                <div className="mt-2 d-flex">
                                                    <select className="pizzaInput" name={i.name} onChange={(e) => qunatityHandler(e)}>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                    </select>
                                                    <span className="pizzaBtn" onClick={() => addtocartHandler(i)}>
                                                        Add To Cart
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col sm="12" md="12" lg="4" className="productImgDiv">
                                                <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                                                <img src={i.image} className="ProductImg" />
                                                <span style={{ color: '#3434fb', marginTop: '5px', cursor: 'pointer' }} onClick={() => customHandler(i)}>
                                                    Customize
                                                </span>
                                            </Col>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Row>
                </Container>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Spinner style={{ color: '#ffc000' }} />
                </div>
            )}
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        Menu: state.Pizza.Menu,
        productId: state.Pizza.productId,
        cart: state.Pizza.cart,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        cartHandler: (value) => dispatch({ type: 'CART', value: value }),
        productHandler: (value) => dispatch({ type: 'PRODUCT', value: value }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Subs);
