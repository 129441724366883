import React, { useState } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { AlertCircle, X } from 'react-feather';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Header from './Header';
const Stromboli = (props) => {
    const [desc, setdesc] = useState(-1);
    const [pizza, setPizza] = useState([]);
    const [loader, setLoader] = useState(false);

    const getProduct = async () => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/categories/2/products`,
            headers: {
                Accept: 'application/json',
            },
        };

        const Response = await axios(config);
        const types = Response.data.response.data.products.map((i) => ({
            ...i,
            groupsIngredients: i.groupsIngredients.map((ing) => ({ ...ing, type: ing.name === 'Choice of Ingredients' ? 'PreSelected' : 'AddExtra' })),
            groupsIngredients: i.groupsIngredients.map((ing) => ({ ...ing, addedIngredients: ing.ingredientsItems.filter((filter) => filter.isSelected) })),
            basePrice: i.price,
            quantity: 1,
            size: 'Small Size',
        }));
        const Added = types.map((i) => ({
            ...i,
            groupsIngredients: i.groupsIngredients.map((f) => ({
                ...f,
                addedIngredients: f.addedIngredients.map((ing) => ({
                    ...ing,
                    size: f.name === 'Choice of Sauce' ? 'Medium Size' : f.name === 'Cheese' ? 'Medium Size' : 'Small Size',
                })),
            })),
        }));
        setPizza(Added);
        setLoader(true);
    };
    React.useEffect(() => {
        if (props.productId) {
            getProduct();
        }
        return () => {
            setPizza([]);
            setdesc(-1);
            setLoader(false);
        };
    }, [props.productId]);

    const customHandler = (i) => {
        const sameProduct = props.cart.map((item) => item.id === i.id);
        const sameProductCount = sameProduct.filter(Boolean);
        const object = Object.assign({ productName: `${i.name}${sameProductCount.length}` }, i);
        props.productHandler([object]);
        props.history.push(`/stromboli_maker`);
    };

    const qunatityHandler = (e) => {
        const data = [...pizza];
        const filterIngedeints = data.filter((f) => {
            return f.name === e.target.name;
        });

        const add = filterIngedeints.map((add) => ({ ...add, quantity: e.target.value, price: e.target.value * add.basePrice }));
        var editedUser = add[0];
        const users = pizza.map((u) => (u.id !== add[0].id ? u : editedUser));
        setPizza(users);
    };

    const addtocartHandler = (i) => {
        const sameProduct = props.cart.map((item) => item.id === i.id);
        const sameProductCount = sameProduct.filter(Boolean);
        const object = Object.assign({ productName: `${i.name}${sameProductCount.length}` }, i);
        props.cartHandler(props.cart.concat(object));
    };

    return (
        <React.Fragment>
            <Header props={props} />
            {loader ? (
                <Container>
                    <Row className="mt-3">
                        <h1 style={{ fontWeight: '600' }}>Stromboli</h1>
                    </Row>
                    <Row>
                        <Row>
                            {pizza.map((i, k) => {
                                return (
                                    <Col sm="6" className="mt-3 position-relative" key={k}>
                                        <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                                            <Col sm="6">
                                                <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            </Col>
                                            <Col sm="6">
                                                <div style={{ paddingLeft: '10px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <label>{i.name}</label>
                                                        <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                                                    </div>

                                                    <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                                    <p>{i.detail}</p>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="MenuInner">
                                            <Col sm="12" md="12" lg="8" className="p-0">
                                                <label>{i.name}</label>
                                                <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                                <div className="d-flex customPizzaBtn respCustimBtn">
                                                    <select className="stromboliInput" name={i.name} onChange={(e) => qunatityHandler(e)}>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                    </select>
                                                    <span className="pizzaBtn" onClick={() => addtocartHandler(i)}>
                                                        Add To Cart
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col sm="12" md="12" lg="4" className="productImgDiv">
                                                <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                                                <img src={i.image} className="ProductImg" />
                                                <span style={{ color: '#3434fb', marginTop: '5px', cursor: 'pointer' }} onClick={() => customHandler(i)}>
                                                    Customize
                                                </span>
                                            </Col>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Row>
                </Container>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Spinner style={{ color: '#ffc000' }} />
                </div>
            )}
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        Menu: state.Pizza.Menu,
        productId: state.Pizza.productId,
        cart: state.Pizza.cart,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        cartHandler: (value) => dispatch({ type: 'CART', value: value }),
        productHandler: (value) => dispatch({ type: 'PRODUCT', value: value }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Stromboli);
