import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { connect } from 'react-redux';
import axios from "axios";

import CardSection from "./card";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";

class CheckoutForm extends React.Component {
    state = {
        loading: false
    }

    handleSubmit = async event => {
        event.preventDefault();
        this.setState({ loading: true });

        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            toast.error(result.error.message);
            this.setState({ loading: false });
        } else {
            var data = JSON.stringify({
                "token": result.token.id
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/v1/user/cards`,
                headers: {
                    'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    this.props.order(response.data.response.data.id);
                    this.setState({ loading: false });
                })
                .catch(function (error) {
                    toast.error("Your Card is not Valid!");
                    this.setState({ loading: false });
                });
        }
    };

    render() {
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <CardSection />
                    <button disabled={this.state.loading || this.props.loading} className="btn-pay">
                        {this.state.loading || this.props.loading ? <Spinner style={{ color: "#fff" }} /> : "Submit"}
                    </button>
                </form>
            </div>
        );
    }
}

function InjectedCheckoutForm(props) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} hidePostalCode={true} token={props.token.jwt} order={props.order} tip={props.tip} loading={props.loading} />
            )}
        </ElementsConsumer>
    );
}

const MapStateToProps = (state) => {
    return {
        token: state.Pizza.token,
    };
};

export default connect(MapStateToProps)(InjectedCheckoutForm);