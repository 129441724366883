import { CRUST, SAUCE, CHEESE, MENU, CART, TOKEN, SIGNED, PRODUCTID, PRODUCT, DEALS, DEAL, DISCOUNT, OPTION, DELIVERY, COUPON } from '../../actions/app/appActions';

const initialState = {
  crust: 'Original Pan Pizza',
  sauce: 'Classic Marinara',
  cheese: 'Regular',
  Menu: '',
  signedIn: false,
  cart: [],
  token: [],
  productId: '',
  product: [],
  deals: [],
  deal: true,
  discount: "",
  option: "CARRYOUT",
  delivery: {},
  coupon: "",
};
const app = (state = initialState, action) => {
  switch (action.type) {
    case CRUST:
      return {
        ...state,
        crust: action.value,
      };
    case SAUCE:
      return {
        ...state,
        sauce: action.value,
      };
    case CHEESE:
      return {
        ...state,
        cheese: action.value,
      };
    case MENU:
      return {
        ...state,
        Menu: action.value,
      };
    case CART:
      return {
        ...state,
        cart: action.value,
      };
    case TOKEN:
      return {
        ...state,
        token: action.value,
      };
    case SIGNED:
      return {
        ...state,
        signedIn: action.value,
      };
    case PRODUCTID:
      return {
        ...state,
        productId: action.value,
      };
    case PRODUCT:
      return {
        ...state,
        product: action.value,
      };
    case DEALS:
      return {
        ...state,
        deals: action.value,
      };
    case DISCOUNT:
      return {
        ...state,
        discount: action.value,
      };
    case OPTION:
      return {
        ...state,
        option: action.value,
      };
    case DELIVERY:
      return {
        ...state,
        delivery: action.value,
      };
    case COUPON:
      return {
        ...state,
        coupon: action.value,
      };
    default:
      return state;
  }
};
export default app;
