import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Facebook } from 'react-feather';
import { Link } from 'react-router-dom';

const Footer = (props) => {
    const [cat, setCat] = React.useState([]);
    const getCat = async () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/categories`,
            headers: {
                Accept: 'application/json',
            },
        };

        const Response = await axios(config);
        const items = Response.data.response.data.categories.filter((f) => f.categoryId !== 13);
        setCat(items);
    };
    React.useEffect(() => {
        getCat();
    }, []);
    return (
        <Container>
            <Row className="footer">
                <hr />
                <Col sm="2">
                    <div>
                        <a href="https://m.facebook.com/aroma8100/" target="_blank"><Facebook style={{ marginRight: '10px', cursor: 'pointer' }} /></a>
                    </div>
                </Col>
                <Col sm="10" className="d-flex">
                    <Col sm="3">
                        <div className="footerMenu">
                            <label>Menu</label>
                            <ul>
                                {cat.slice(0, 6).map((i, k) => {
                                    return (
                                        <li key={k}>
                                            <Link to={`/menu/${i.categoryTitle.replace(/\s+/g, '_').toLowerCase()}`} className="p-0" style={{ color: '#000', textDecoration: 'none' }}>
                                                {i.categoryTitle}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className="footerMenu">
                            <ul>
                                {cat.slice(6).map((i, k) => {
                                    return (
                                        <li key={k}>
                                            <Link to={`/menu/${i.categoryTitle.replace(/\s+/g, '_').toLowerCase()}`} className="p-0" style={{ color: '#000', textDecoration: 'none' }}>
                                                {i.categoryTitle}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className="footerMenu">
                            <label>My Account</label>
                            <ul>
                                <Link style={{ color: '#000', textDecoration: 'none' }} to="/register">
                                    <li style={{ listStyle: 'none' }}>Create an account</li>
                                </Link>
                                <Link style={{ color: '#000', textDecoration: 'none' }} to="/sign_in">
                                    <li style={{ listStyle: 'none' }}>sign in</li>
                                </Link>
                            </ul>
                            <label>Contact Us</label>
                            <ul>
                                <li><a href="mailto:info@aromagrillhouse.com">info@aromagrillhouse.com</a></li>
                                <li><a href="tel:+1 301-874-8100">301-874-8100</a></li>
                                <li>1595 B. Bowis Dr
                                    Point of Rocks, MD 21777</li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm="3">
                        <div className="footerMenu ctn">
                            <label>About Us</label>
                            <ul>
                                <li>Aroma House has been serving the community since 1999 with the best Pizzas,
                                    Strombolis and Subs. At Aroma House, every dish is created using only the freshest and the finest ingredients.
                                    All of our pizzas are hand tossed and topped with only the freshest toppings.
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Col>
            </Row>
            <Row className="mobileFooter">
                <hr />
                <Col sm="12" className="mb-2">
                    <div>
                        <a href="https://m.facebook.com/aroma8100/" target="_blank"><Facebook style={{ marginRight: '10px', cursor: 'pointer' }} /></a>
                    </div>
                </Col>
                <Row>
                    <Col sm="12" className="d-flex">
                        <Col sm="4">
                            <div className="footerMenu">
                                <label>Menu</label>
                                <ul>
                                    {cat.map((i, k) => {
                                        return (
                                            <li key={k}>
                                                <Link key={k} className="d-flex" to={`/menu/${i.categoryTitle.replace(/\s+/g, '_').toLowerCase()}`} style={{ textDecoration: 'none' }} onClick={() => props.productIdHandler(i.categoryId)}>
                                                    <li className="d-flex justify-content-between">{i.categoryTitle}</li>
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </Col>
                        <Col sm="4">
                            <div className="footerMenu">
                                <label>My Account</label>
                                <ul>
                                    <Link style={{ color: '#000', textDecoration: 'none' }} to="/register">
                                        <li style={{ listStyle: 'none' }}>Create an account</li>
                                    </Link>
                                    <Link style={{ color: '#000', textDecoration: 'none' }} to="/sign_in">
                                        <li style={{ listStyle: 'none' }}>sign in</li>
                                    </Link>
                                </ul>
                            </div>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <div className="footerMenu">
                            <label>About Us</label>
                            <ul>
                                <li>lorem ipsum</li>
                            </ul>
                            <label>Contact Us</label>
                            <ul>
                                <li>Email</li>
                                <li><a href="tel:+1 301-874-8100">301-874-8100</a></li>
                                <li>Address</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Row>
            <hr />
        </Container>
    );
};
export default Footer;
