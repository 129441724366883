import React, { useState } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { AlertCircle, X } from 'react-feather';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Tabs from 'react-bootstrap/Tabs';
import TabPane from 'react-bootstrap/TabPane';
import Header from './Header';
const Pizza = (props) => {
  const [desc, setdesc] = useState(-1);
  const [pizza, setPizza] = useState([]);
  const [, setPizzaFilter] = useState([]);
  const [, setModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const getProduct = async () => {
    var axios = require('axios');
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/v1/categories/1/products`,
      headers: {
        Accept: 'application/json',
      },
    };

    const Response = await axios(config);
    const types = Response.data.response.data.products.map((i) => ({
      ...i,
      groupsIngredients: i.groupsIngredients.map((ing) => ({ ...ing, addedIngredients: ing.ingredientsItems.filter((filter) => filter.isSelected) })),
      price: i.smallPrice,
      basePrice: i.smallPrice,
      quantity: 1,
      size: i.name === "Custom Pizza" ? "Small Size" : ""
    }));

    const Added = types.map((i) => ({
      ...i,
      groupsIngredients: i.groupsIngredients.map((f) => ({
        ...f,
        addedIngredients: f.addedIngredients.map((ing) => ({
          ...ing,
          size: f.name === 'Choice of Sauce' ? 'Medium Size' : f.name === 'Cheese' ? 'Small Size' : f.name === 'Meats' ? 'Small Size' : f.name === 'Veggies' ? 'Small Size' : ing.size,
          mainSize: f.name === "Cheese" && "Small Size",
          topping: f.name === 'Veggies' || 'Meats' ? 'whole' : null,
        })),
      })),
    }));
    setPizza(Added.reverse());
    setPizzaFilter(Added.reverse());
    setLoader(true);
  };
  React.useEffect(() => {
    if (props.productId) {
      getProduct();
    }
    return () => {
      setPizza([]);
      setdesc(-1);
      setPizzaFilter([]);
      setModal(false);
      setLoader(false);
    };
  }, [props.productId]);

  const sizeHandler = (e) => {
    const data = [...pizza];
    const filter = data.filter((f) => f.name === e.target.name);
    const crustPrice = filter.map((i) =>
      i.groupsIngredients
        .filter((f) => f.name === 'Choice of Crust')
        .map((k) =>
          k.addedIngredients.map((price) => {
            if (e.target.value === 'Small Size') {
              return price.smallPrice;
            } else if (e.target.value === 'Medium Size') {
              return price.mediumPrice;
            } else if (e.target.value === 'Large Size') {
              return price.largePrice;
            } else if (e.target.value === 'Extra Large Size') {
              return price.extraLargePrice;
            }
          })
        )
    );
    const test = crustPrice.flat();
    const price = test.flat();
    const quantitySet = data.map((i) => {
      if (i.name == e.target.name) {
        return Object.assign({}, i, {
          size: e.target.value,
          price:
            e.target.value === 'Small Size'
              ? +i.smallPrice + +price[0] * i.quantity
              : e.target.value === 'Medium Size'
                ? +i.mediumPrice + +price[0] * i.quantity
                : e.target.value === 'Large Size'
                  ? +i.largePrice + +price[0] * i.quantity
                  : e.target.value === 'Extra Large Size'
                    ? +i.extraLargePrice + +price[0] * i.quantity
                    : null,
          basePrice:
            e.target.value === 'Small Size'
              ? +i.smallPrice + +price[0] * i.quantity
              : e.target.value === 'Medium Size'
                ? +i.mediumPrice + +price[0] * i.quantity
                : e.target.value === 'Large Size'
                  ? +i.largePrice + +price[0] * i.quantity
                  : e.target.value === 'Extra Large Size'
                    ? +i.extraLargePrice + +price[0] * i.quantity
                    : null,
        });
      }
    });

    var editedUser = quantitySet.filter(Boolean);
    const users = pizza.map((u) => (u.id !== editedUser[0].id ? u : editedUser[0]));
    const Added = users.map((i) => ({
      ...i,
      groupsIngredients: i.groupsIngredients.map((f) => ({
        ...f,
        addedIngredients: f.addedIngredients.map((ing) => ({
          ...ing,
          mainSize: f.name === "Cheese" && i.size,
        })),
      })),
    }));

    setPizza(Added);
  };

  const qunatityHandler = (e) => {
    const data = [...pizza];
    const filterIngedeints = data.filter((f) => {
      return f.name === e.target.name;
    });
    const add = filterIngedeints.map((add) => ({ ...add, quantity: e.target.value }));
    var editedUser = add[0];
    const users = pizza.map((u) => (u.id !== add[0].id ? u : editedUser));
    setPizza(users);
  };

  const crustHandler = (e) => {
    const data = [...pizza];
    const filter = data.filter((f) => f.name === e.target.name);

    const group = filter.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === 'Choice of Crust';
      });
    });
    const removedIngredient = group[0].map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === e.target.value) }));
    var editedUser = removedIngredient[0];
    const users = filter.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));

    const crustPrice = removedIngredient.map((k) =>
      k.addedIngredients.map((price) => {
        if (filter[0].size === 'Small Size') {
          return price.smallPrice;
        } else if (filter[0].size === 'Medium Size') {
          return price.mediumPrice;
        } else if (filter[0].size === 'Large Size') {
          return price.largePrice;
        } else if (filter[0].size === 'Extra Large Size') {
          return price.extraLargePrice;
        }
      })
    );

    const priceFlat = crustPrice.flat();
    const price = priceFlat.flat();
    const quantitySet = users.map((i) => {
      return Object.assign({}, i, {
        basePrice:
          i.size ? i.size === 'Small Size'
            ? +i.smallPrice + +price[0]
            : i.size === 'Medium Size'
              ? +i.mediumPrice + +price[0]
              : i.size === 'Large Size'
                ? +i.largePrice + +price[0]
                : i.size === 'Extra Large Size'
                  ? +i.extraLargePrice + +price[0]
                  : null : i.price,
      });
    });
    var editedUser2 = quantitySet[0];
    const users2 = data.map((u) => (u.id !== editedUser2.id ? u : editedUser2));
    setPizza(users2);
  };

  const addtocartHandler = (i) => {
    const sameProduct = props.cart.map((item) => item.id === i.id);
    const sameProductCount = sameProduct.filter(Boolean);
    if (i.size) {
      const object = Object.assign({ productName: `${i.name}${sameProductCount.length}` }, i);
      props.cartHandler(props.cart.concat(object));
      toast.success(`${i.name} added in the cart`);
    } else {
      toast.error("Please Select Size");
    }
  };

  const customHandler = (i) => {
    const sameProduct = props.cart.map((item) => item.id === i.id);
    const sameProductCount = sameProduct.filter(Boolean);
    if (i.size) {
      const data = [i];
      const quantitySet = data.map((i) => {
        return Object.assign({}, i, {
          price: i.basePrice,
          quantity: 1,
          productName: `${i.name}${sameProductCount.length}`
        });
      });
      props.productHandler(quantitySet);
      props.history.push(`/pizza_maker`);
    } else {
      toast.error("Please Select Size")
    }

  };
  return (
    <React.Fragment>
      <Header props={props} />
      <Container>
        <Row className="mt-3">
          <h1 style={{ fontWeight: '600' }}>Pizza</h1>
        </Row>
        {loader ? (
          <Tabs defaultActiveKey="home" transition={true} id="noanim-tab-example" className="mb-3">
            <TabPane eventKey="home" title="White Pizza">
              <Row>
                {pizza
                  .filter((f) => f.subCategory === 'White Pizza' || f.subCategory === 'Custom Pizza')
                  .map((i, k) => {
                    const disabled = i.groupsIngredients.filter((f) => f.name === 'Choice of Crust').map((l) => l.addedIngredients?.map((ing) => ing.name == 'Gluten Free Crust'));
                    return (
                      <Col sm="6" className="mt-3 position-relative" key={k}>
                        <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                          <Col sm="6">
                            <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                          </Col>
                          <Col sm="6">
                            <div style={{ paddingLeft: '10px' }}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label>{i.name}</label>
                                <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                              </div>

                              <span className="d-block">${(Number(i.basePrice) * i.quantity).toFixed(2)}</span>
                              <p>{i.detail}</p>
                            </div>
                          </Col>
                        </div>
                        <div className="MenuInner">
                          <React.Fragment>
                            {i.subCategory !== 'Custom Pizza' ? (
                              <Col sm="12" md="12" lg="8" className="p-0">
                                <label>{i.name}</label>
                                <span className="d-block">${(Number(i.basePrice) * i.quantity).toFixed(2)}</span>
                                <select className="mt-2" name={i.name} onChange={(e) => crustHandler(e)}>
                                  {i.groupsIngredients
                                    .filter((filter) => {
                                      return filter.name === 'Choice of Crust';
                                    })
                                    .map((group) => {
                                      return group.ingredientsItems.map((crust, crustKey) => {
                                        return (
                                          <option key={crustKey} value={crust.name} disabled={i.size === 'Extra Large Size' && crust.name === 'Gluten Free Crust'}>
                                            {crust.name}{' '}
                                            {`${i.size === 'Small Size' && crust.smallPrice !== '0.00'
                                              ? `Extra ($${crust.smallPrice})`
                                              : i.size === 'Medium Size' && crust.mediumPrice !== '0.00'
                                                ? `Extra ($${crust.mediumPrice})`
                                                : i.size === 'Large Size' && crust.largePrice !== '0.00'
                                                  ? `Extra ($${crust.largePrice})`
                                                  : i.size === 'Extra Large Size' && crust.extraLargePrice !== '0.00'
                                                    ? `Extra ($${crust.extraLargePrice})`
                                                    : ''
                                              }`}
                                          </option>
                                        );
                                      });
                                    })}
                                </select>
                                <select className="mt-2" name={i.name} onChange={(e) => sizeHandler(e, i)}>
                                  <option className="d-none" value="">Select Size</option>
                                  {i.sizes.map((size, sizeKey) => {
                                    return (
                                      <option key={sizeKey} value={size} disabled={disabled[0][0] && size === 'Extra Large Size'}>
                                        {size}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="mt-2 d-flex">
                                  <select className="pizzaInput" name={i.name} onChange={(e) => qunatityHandler(e)}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                  <span className="pizzaBtn" onClick={() => addtocartHandler(i)}>
                                    Add To Cart
                                  </span>
                                </div>
                              </Col>
                            ) : (
                              <Col sm="12" md="12" lg="8" className="p-0">
                                <label>{i.name}</label>
                                <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                <div className="mt-5 d-flex customPizzaBtn">
                                  <span className="pizzaBtn" onClick={() => customHandler(i)}>
                                    Customize
                                  </span>
                                </div>
                              </Col>
                            )}
                          </React.Fragment>
                          <Col sm="12" md="12" lg="4" className="productImgDiv">
                            <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                            <img src={i.image} className="ProductImg" />
                            {i.subCategory !== 'Custom Pizza' ? (
                              <span style={{ color: '#3434fb', marginTop: '5px', cursor: 'pointer' }} onClick={() => customHandler(i)}>
                                Customize
                              </span>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </TabPane>
            <TabPane eventKey="profile" title="Specialty Pizza">
              <Row>
                {pizza
                  .filter((f) => f.subCategory === 'Specialty Pizza' || f.subCategory === 'Custom Pizza')
                  .map((i, k) => {
                    const disabled = i.groupsIngredients.filter((f) => f.name === 'Choice of Crust').map((l) => l.addedIngredients?.map((ing) => ing.name == 'Gluten Free Crust'));
                    return (
                      <Col sm="6" className="mt-3 position-relative" key={k}>
                        <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                          <Col sm="6">
                            <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                          </Col>
                          <Col sm="6">
                            <div style={{ paddingLeft: '10px' }}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label>{i.name}</label>
                                <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                              </div>

                              <span className="d-block">${(Number(i.basePrice) * i.quantity).toFixed(2)}</span>
                            </div>
                          </Col>
                        </div>
                        <div className="MenuInner">
                          <React.Fragment>
                            {i.subCategory !== 'Custom Pizza' ? (
                              <Col sm="12" md="12" lg="8" className="p-0">
                                <label>{i.name}</label>
                                <span className="d-block">${(Number(i.basePrice) * i.quantity).toFixed(2)}</span>
                                <select className=" " name={i.name} onChange={(e) => crustHandler(e)}>
                                  {i.groupsIngredients
                                    .filter((filter) => {
                                      return filter.name === 'Choice of Crust';
                                    })
                                    .map((group) => {
                                      return group.ingredientsItems.map((crust, crustKey) => {
                                        return (
                                          <option key={crustKey} value={crust.name} disabled={i.size === 'Extra Large Size' && crust.name === 'Gluten Free Crust'}>
                                            {crust.name}{' '}
                                            {`${i.size === 'Small Size' && crust.smallPrice !== '0.00'
                                              ? `Extra ($${crust.smallPrice})`
                                              : i.size === 'Medium Size' && crust.mediumPrice !== '0.00'
                                                ? `Extra ($${crust.mediumPrice})`
                                                : i.size === 'Large Size' && crust.largePrice !== '0.00'
                                                  ? `Extra ($${crust.largePrice})`
                                                  : i.size === 'Extra Large Size' && crust.extraLargePrice !== '0.00'
                                                    ? `Extra ($${crust.extraLargePrice})`
                                                    : ''
                                              }`}
                                          </option>
                                        );
                                      });
                                    })}
                                </select>
                                <select className="mt-2" name={i.name} onChange={(e) => sizeHandler(e)}>
                                  {i.sizes.map((size, sizeKey) => {
                                    return (
                                      <option key={sizeKey} value={size} disabled={disabled[0][0] && size === 'Extra Large Size'}>
                                        {size}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="mt-2 d-flex">
                                  <select className="pizzaInput" name={i.name} onChange={(e) => qunatityHandler(e)}>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </select>
                                  <span className="pizzaBtn" onClick={() => addtocartHandler(i)}>
                                    Add To Cart
                                  </span>
                                </div>
                              </Col>
                            ) : (
                              <Col sm="12" md="12" lg="8" className="p-0">
                                <label>{i.name}</label>
                                <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                <div className="mt-5 d-flex customPizzaBtn">
                                  <span className="pizzaBtn" onClick={() => customHandler(i)}>
                                    Customize
                                  </span>
                                </div>
                              </Col>
                            )}
                          </React.Fragment>

                          <Col sm="12" md="12" lg="4" className="productImgDiv">
                            <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                            <img src={i.image} className="ProductImg" />
                            {i.subCategory !== 'Custom Pizza' ? (
                              <span style={{ color: '#3434fb', marginTop: '5px', cursor: 'pointer' }} onClick={() => customHandler(i)}>
                                Customize
                              </span>
                            ) : null}
                          </Col>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </TabPane>
            <TabPane eventKey="contact" title="Create your own">
              <Row>
                {pizza
                  .filter((f) => f.subCategory === 'Custom Pizza')
                  .map((i, k) => {
                    return (
                      <Col sm="6" className="mt-3 position-relative" key={k}>
                        <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                          <Col sm="6">
                            <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                          </Col>
                          <Col sm="6">
                            <div style={{ paddingLeft: '10px' }}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label>{i.name}</label>
                                <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                              </div>

                              <span className="d-block">${Number(i.price).toFixed(2)}</span>
                            </div>
                          </Col>
                        </div>
                        <div className="MenuInner">
                          <Col sm="8" className="p-0">
                            <label>{i.name}</label>
                            <span className="d-block">${Number(i.price).toFixed(2)}</span>
                            <div className="mt-5 d-flex customPizzaBtn">
                              <span className="pizzaBtn" onClick={() => customHandler(i)}>
                                Get started
                              </span>
                            </div>
                          </Col>
                          <Col sm="4" className="productImgDiv">
                            <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                            <img src={i.image} className="ProductImg" />
                          </Col>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </TabPane>
          </Tabs>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spinner style={{ color: '#ffc000' }} />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    Menu: state.Pizza.Menu,
    productId: state.Pizza.productId,
    cart: state.Pizza.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cartHandler: (value) => dispatch({ type: 'CART', value: value }),
    productHandler: (value) => dispatch({ type: 'PRODUCT', value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pizza);
