import React from "react";
import { Col, Spinner, Table } from "reactstrap";
import { connect } from 'react-redux';
import axios from "axios";
import toast from 'react-hot-toast'

const Cards = (props) => {
    const [cards, setCards] = React.useState([]);
    const [cardId, setCardId] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [cardLoading, setCardLoading] = React.useState(false);

    const getCards = React.useCallback(() => {
        setLoading(false);
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/user/cards`,
            headers: {
                'Authorization': `Bearer ${props.user.jwt}`
            }
        };

        axios(config)
            .then(function (response) {
                setCards(response.data.response.data);
                setLoading(true);
            })
            .catch(function () {
                setLoading(true);
            });

    }, [props.user.jwt]);

    React.useEffect(() => {
        getCards();
    }, []);


    const deleteCard = React.useCallback((card) => {
        setCardId(cardId => [...cardId, card]);
        setCardLoading(true);

        var config = {
            method: 'delete',
            url: `${process.env.REACT_APP_BASE_URL}/v1/user/cards/${card.id}`,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${props.user.jwt}`
            }
        };

        axios(config)
            .then(function () {
                const deletedCards = cards.filter((id) => id.id !== card);
                const deletedCardId = cardId.filter((id) => id !== card);
                setCards(deletedCards);
                setCardId(deletedCardId);
                getCards();
                toast.success("Card Deleted");
                setCardLoading(false);
            })
            .catch(function (error) {
                setCardLoading(false);
                console.log(error);
            });

    }, [props.user.jwt, cardId, setCardId]);

    const updateCard = React.useCallback((card) => {
        setCardLoading(true);
        var config = {
            method: 'patch',
            url: `${process.env.REACT_APP_BASE_URL}/v1/user/cards/${card.id}`,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${props.user.jwt}`
            }
        };

        axios(config)
            .then(function () {
                getCards();
                setCardLoading(false);
            })
            .catch(function () {
                setCardLoading(false);
            });


    }, [props.user.jwt]);

    return <Col sm="12">
        {loading
            ?
            <div>
                {cards.length ?
                    <div>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Card #</th>
                                    <th>Card</th>
                                    <th>Default</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cards.map((card, index) => (
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>{card.lastDigits}</td>
                                        <td><img src={card.cardIcon} /></td>
                                        {!cardLoading ? <td>
                                            {
                                                card.isDefault
                                                    ? "Default"
                                                    : <button className="cartOrder" style={{ margin: 0, padding: "0 5px" }} onClick={() => updateCard(card)}>Set Default</button>
                                            }
                                        </td>
                                            : <td>
                                                <Spinner style={{ color: "#ffc000" }} />
                                            </td>}
                                        {
                                            !cardLoading ?
                                                <td>
                                                    <button style={{ border: "none", background: "#ffc000", color: "#fff", borderRadius: 8 }} onClick={() => deleteCard(card)}>Delete</button>
                                                </td>
                                                :
                                                <td>
                                                    <Spinner style={{ color: "#ffc000" }} />
                                                </td>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    : <h2 className="text-center">No Card added</h2>}
            </div>
            : <div className="text-center mt-4">
                <Spinner style={{ color: "#ffc000" }} />
            </div>}
    </Col >
}

const MapStateToProps = (state) => {
    return {
        user: state.Pizza.token,
    };
};

export default connect(MapStateToProps)(Cards);