import React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import toast from 'react-hot-toast';
import Header from './Header';
const Cart = (props) => {
   const [data, setData] = React.useState([]);
   const [coupon, setCoupon] = React.useState('');
   const [discount, setDiscount] = React.useState(0);
   const [submitting] = React.useState(false);

   React.useEffect(() => {
      if (props.cart) {
         setData(props.cart);
         props.discountHandler("");
      }
   }, [props.cart]);


   const removeHandler = React.useCallback((i, k) => {
      if (i.dealItems) {
         const cart = data.filter((f) => {
            return f.name !== i.name;
         });
         setData(cart);
         props.cartHandler(cart);
         props.dealHandler(true);
      } else {
         const filter = data.filter((_value, index) => index !== k);
         props.cartHandler(filter);
      }
   }, [data]);

   const plus = React.useCallback((price) => {
      const product = [...data];
      const addedPrice = +price.quantity + 1;
      const filterIngedeints = product.filter((f) => {
         return f.name === price.name;
      });
      const add = filterIngedeints.map((add) => ({ ...add, quantity: addedPrice, price: addedPrice * add.basePrice }));
      var editedUser = add[0];
      const users = product.map((u) => (u.id !== add[0].id ? u : editedUser));
      setData(users);
   }, [data]);

   const minus = React.useCallback((price) => {
      const product = [...data];
      const addedPrice = Number(price.quantity) - 1;
      const filterIngedeints = product.filter((f) => {
         return f.name === price.name;
      });
      const add = filterIngedeints.map((add) => ({ ...add, quantity: addedPrice, price: addedPrice * add.basePrice }));
      var editedUser = add[0];
      const users = product.map((u) => (u.id !== add[0].id ? u : editedUser));
      setData(users);
   }, [data]);

   const orderHandler = async () => {
      if (props.token.jwt) {
         const discounted = (discount / 100) * data.reduce((a, b) => +a + +b.price, 0);
         props.discountHandler(discounted);
         const quantitySet = data.map((i) => {
            return Object.assign({}, i, {
               discountAmount: (discount / 100) * data.reduce((a, b) => +a + +b.price, 0),
            });
         });
         props.cartHandler(quantitySet);
         props.history.push("/checkout");
      }
      else {
         props.history.push('/sign_in');
      }
   };

   const couponVerify = React.useCallback(async () => {
      if (coupon !== '') {
         try {
            var config = {
               method: 'get',
               url: `${process.env.REACT_APP_BASE_URL}/v1/promocode/verify/${coupon}`,
               headers: {
                  Authorization: `Bearer ${props.token.jwt}`,
               },
            };

            await axios(config);
            couponHandler();
         } catch {
            toast.error('Coupon Code Is Already Used!');
         }
      } else {
         toast.error('Coupon Field is Empty');
      }
   }, [props?.user?.jwt, coupon])

   const couponHandler = async () => {
      if (props.token.jwt) {
         if (coupon !== '') {
            try {
               var config = {
                  method: 'get',
                  url: `${process.env.REACT_APP_BASE_URL}/v1/promocode/${coupon}`,
                  headers: {
                     Authorization: `Bearer ${props.token.jwt}`,
                  },
               };

               const response = await axios(config);

               setDiscount(response.data.response.data.discountedPercentage);
               props.couponReduxHandler(coupon);
               toast.success('Coupon Code Added');
            } catch {
               toast.error('Coupon Code Is Invalid');
            }
         } else {
            toast.error('Coupon Field is Empty');
         }
      } else {
         props.history.push('/sign_in');
      }
   };

   const customHandler = (i) => {
      if (!i.product) {
         props.productHandler([i]);
         if (i.category === 'Pizza') {
            props.history.push(`/pizza_maker`);
         } else if (i.category === 'Stromboli') {
            props.history.push(`/stromboli_maker`);
         } else {
            props.history.push(`/item/${i.name.replace(/\s+/g, '_').toLowerCase()}`);
         }
      } else {
         props.productHandler(i.product);
         if (i.category === 'Pizza') {
            props.history.push(`/pizza_maker`);
         } else if (i.category === 'Stromboli') {
            props.history.push(`/stromboli_maker`);
         } else {
            props.history.push(`/item/${i.name.replace(/\s+/g, '_').toLowerCase()}`);
         }
      }
   };

   const discounted = (discount / 100) * data.reduce((a, b) => +a + +b.price, 0);

   return (
      <React.Fragment>
         <Header props={props} />
         <Container>
            <Row className="mt-3">
               <h2>Cart</h2>
            </Row>
            <Row className="mt-1 mb-2">
               {props.option === "CARRYOUT"
                  ? <span style={{ fontSize: "14px" }}><b>CARRYOUT FROM: </b>1595 B. Bowis Dr Point of Rocks, MD 21777</span>
                  : <span style={{ fontSize: "14px" }}><b>DELIVERY FROM: </b>1595 B. Bowis Dr Point of Rocks, MD 21777</span>}
            </Row>
            {data.length ? <Row>
               {data
                  .filter((f) => !f.dealItems)
                  .map((i, k) => {
                     const added = i.groupsIngredients?.map((m) => {
                        return m.addedIngredients?.map((m1) => {
                           return m1.name ? m1.name : m1.ingredientName;
                        });
                     });
                     const extra = i.groupsIngredients.map((m) => {
                        return m.addedExtra?.map((m1) => {
                           return m1.name ? m1.name : m1.ingredientName;
                        });
                     });
                     const array = added.concat(extra);

                     const alterArray = array.flat();
                     return (
                        <Row key={k} className="cartProducts">
                           <Col sm="3">
                              <img src={i.image} style={{ width: '100%', height: '100px', objectFit: 'cover' }} />
                           </Col>
                           <Col sm="6">
                              <div>
                                 <h4>{i.name} {i.size ? `(${i.size === "8 inch" ? "8″" : i.size === "12 inch" ? "12″" : i.size})` : ""}</h4>
                                 <>
                                    {alterArray.filter(Boolean).map((ing, ingKey) => {
                                       return (
                                          <span style={{ fontSize: '14px', color: '#677078' }} key={`demo_snap_${ingKey}`}>
                                             {(ingKey ? ', ' : '') + ing}
                                          </span>
                                       );
                                    })}
                                 </>

                                 {i.category !== 'Fried Chicken' &&
                                    i.category !== 'Wings' &&
                                    i.category !== 'Sides' &&
                                    i.category !== 'Drinks' &&
                                    i.category !== 'Desserts' &&
                                    i.category !== 'Kids Menu' &&
                                    i.category !== 'Pasta' &&
                                    !i.product ? (
                                    <p style={{ color: '#0000ae', cursor: 'pointer', fontSize: '14px' }} onClick={() => customHandler(i)}>
                                       + Edit
                                    </p>
                                 ) : null}
                              </div>
                           </Col>
                           <Col sm="3">
                              <div>
                                 <h4>${Number(i.price).toFixed(2)}</h4>
                                 <div className="quantity">
                                    <button onClick={() => minus(i)} disabled={i.quantity === 1}>
                                       -
                                    </button>
                                    <input disabled value={i.quantity} />
                                    <button onClick={() => plus(i)}>+</button>
                                 </div>
                                 <p style={{ color: '#0000ae', cursor: 'pointer', textAlign: 'right', marginTop: '10px' }} onClick={() => removeHandler(i, k)}>
                                    remove
                                 </p>
                              </div>
                           </Col>
                        </Row>
                     );
                  })}
               {data
                  .filter((f) => f.dealItems)
                  .map((deal, dealKey) => {
                     return (
                        <div style={{ borderBottom: '1px solid silver', marginBottom: '10px' }}>
                           <h4 key={dealKey} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                              {deal.name}
                           </h4>
                           {deal.dealItems.map((dealItems) => {
                              return dealItems.productDetail?.map((i, k) => {
                                 const added = i.groupsIngredients.map((m) => {
                                    return m.addedIngredients?.map((m1) => {
                                       return m1.name ? m1.name : m1.ingredientName;
                                    });
                                 });
                                 const extra = i.groupsIngredients.map((m) => {
                                    return m.addedExtra?.map((m1) => {
                                       return m1.name ? m1.name : m1.ingredientName;
                                    });
                                 });
                                 const array = added.concat(extra);

                                 const alterArray = array.flat();
                                 return (
                                    <Row key={k} className="cartProducts dealProducts">
                                       <Col sm="3">
                                          <img src={i.image} style={{ width: '100%', height: '100px', objectFit: 'cover' }} />
                                       </Col>
                                       <Col sm="6">
                                          <div>
                                             <h4>{i.name}</h4>
                                             {alterArray.filter(Boolean).map((ing, ingKey) => {
                                                return (
                                                   <span style={{ fontSize: '14px', color: '#677078' }} key={`demo_snap_${ingKey}`}>
                                                      {(ingKey ? ', ' : '') + ing}
                                                   </span>
                                                );
                                             })}
                                          </div>
                                       </Col>
                                    </Row>
                                 );
                              });
                           })}
                           <p style={{ color: '#0000ae', cursor: 'pointer', textAlign: 'right' }} onClick={() => removeHandler(deal)}>
                              remove
                           </p>
                        </div>
                     );
                  })}

               {data.length ? (
                  <Row>
                     <Col sm="6">
                        {props.signedIn && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                           <input className="cartInput" placeholder="Enter coupon code" onChange={(e) => setCoupon(e.target.value)} />
                           <div className="cartApply" onClick={() => couponVerify()}>
                              APPLY
                           </div>
                        </div>}
                        <hr />
                        <div>
                           <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                              <label style={{ fontWeight: 'bold', fontSize: '14px' }}>SubTotal</label>
                              <label style={{ fontWeight: 'bold', fontSize: '14px' }}>${data.reduce((a, b) => +a + +b.price, 0).toFixed(2)}</label>
                           </div>
                           {discount !== 0 ? (
                              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                                 <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Discount</label>
                                 <label style={{ fontWeight: 'bold', fontSize: '14px' }}>${discounted.toFixed(2)}</label>
                              </div>
                           ) : null}
                           <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                              <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Total</label>
                              <label style={{ fontWeight: 'bold', fontSize: '14px' }}>${(data.reduce((a, b) => +a + +b.price, 0).toFixed(2) - discounted).toFixed(2)}</label>
                           </div>
                        </div>
                        <div className="cartOrder" onClick={() => orderHandler()} disabled={submitting}>
                           {submitting ? <Spinner style={{ color: '#fff' }} /> : "CHECK OUT"}
                        </div>
                     </Col>
                  </Row>
               ) : null}
            </Row>
               : <Row>
                  <h2 className='text-center'>Cart is Empty</h2>
               </Row>
            }
         </Container>
      </React.Fragment >
   );
};
const MapStateToProps = (state) => {
   return {
      cart: state.Pizza.cart,
      token: state.Pizza.token,
      option: state.Pizza.option,
      signedIn: state.Pizza.signedIn
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      cartHandler: (value) => dispatch({ type: 'CART', value: value }),
      productHandler: (value) => dispatch({ type: 'PRODUCT', value: value }),
      dealHandler: (value) => dispatch({ type: 'DEAL', value: value }),
      discountHandler: (value) => dispatch({ type: 'DISCOUNT', value: value }),
      couponReduxHandler: (value) => dispatch({ type: 'COUPON', value: value }),
   };
};
export default connect(MapStateToProps, mapDispatchToProps)(Cart);
