import React, { useState } from 'react';
import { Container, Row, Spinner, Col } from 'reactstrap';
import { AlertCircle, X, CheckCircle } from 'react-feather';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Header from './Header';
const FriedChicken = (props) => {
    const [desc, setdesc] = useState(-1);
    const [pizza, setPizza] = useState([]);
    const [, setPizzaFilter] = useState([]);
    const [loader, setLoader] = useState(false);

    const getProduct = async () => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/categories/3/products`,
            headers: {
                Accept: 'application/json',
            },
        };

        const Response = await axios(config);
        const quantitySet = Response.data.response.data.products.map((i) => {
            return Object.assign({}, i, { quantity: 1, basePrice: i.smallPrice, price: i.smallPrice });
        });
        setPizza(quantitySet);
        setPizzaFilter(quantitySet);
        setLoader(true);
    };
    React.useEffect(() => {
        if (props.productId) {
            getProduct();
        }
    }, [props.productId]);

    const addtocartHandler = (i) => {
        const valid = pizza.map((i) => i.groupsIngredients.map((group) => group.addedIngredients ? group.addedIngredients : false));

        if (valid[0][0]) {
            const sameProduct = props.cart.map((item) => item.id === i.id);
            const sameProductCount = sameProduct.filter(Boolean);
            const object = Object.assign({ productName: `${i.name}${sameProductCount.length}` }, i);
            props.cartHandler(props.cart.concat(object));
            toast.success("Fried Chicken Added To Cart")
        } else {
            toast.error('Please Select Side');
        }
    };
    const sizeHandler = (e) => {
        const data = [...pizza];
        const quantitySet = data.map((i) => {
            if (i.name == e.target.name)
                return Object.assign({}, i, {
                    price: Number(e.target.value),
                    basePrice: Number(e.target.value),
                    size: e.target.value == 6.99 ? '3 PCS' : e.target.value == 9.99 ? '5 PCS' : e.target.value == 17.99 ? '10 PCS' : e.target.value == 33.99 ? '20 PCS' : null,
                    sides: i.sides,
                });
            return i;
        });

        const added = quantitySet.map((i) => ({
            ...i, groupsIngredients: i.groupsIngredients.map((group) =>
                ({ ...group, addedIngredients: undefined }))
        }));
        const select_box = document.getElementById("DropList");
        select_box.selectedIndex = 0;

        setPizza(added);
    };

    const sidesHandler = (l, item) => {
        const data = [...pizza];
        const valid = data.map((i) => i.groupsIngredients.map((group) => group.addedIngredients ? group.addedIngredients : false));

        if (item.size) {
            if (valid[0][0]) {
                if (item.price < 10 && valid[0][0]) {
                    const added = data.map((i) => ({
                        ...i, groupsIngredients: i.groupsIngredients.map((group) =>
                            ({ ...group, addedIngredients: group.ingredientsItems.filter((f) => f.name === l) }))
                    }))
                    setPizza(added);
                } else if (valid[0][0].length !== 2 && item.price > 10) {
                    const added = data.map((i) => ({
                        ...i, groupsIngredients: i.groupsIngredients.map((group) =>
                            ({ ...group, addedIngredients: group.ingredientsItems.filter((f) => f.name === l).concat(group.addedIngredients).filter(Boolean) }))
                    }))
                    setPizza(added);
                } else if (valid[0][0].length === 2) {
                    toast.error('max sides are 2');
                } else {
                    toast.error('you can only select 1 side item with 3 PCS and 5 PCS');
                }
            } else {
                const added = data.map((i) => ({
                    ...i, groupsIngredients: i.groupsIngredients.map((group) =>
                        ({ ...group, addedIngredients: group.ingredientsItems.filter((f) => f.name === l) }))
                }))
                setPizza(added);
            }
        } else {
            toast.error('Please Select Quantity');
        }
    };
    const removeHandler = (l) => {
        const data = [...pizza];
        const removed = data.map((i) => ({
            ...i, groupsIngredients: i.groupsIngredients.map((group) =>
                ({ ...group, addedIngredients: group.ingredientsItems.filter((f) => f.name === l.name) }))
        }))
        setPizza(removed);
    };

    return (
        <React.Fragment>
            <Header props={props} />
            {loader ? (
                <Container>
                    <Row className="mt-3">
                        <h1 style={{ fontWeight: '600' }}>Fried Chicken</h1>
                    </Row>
                    <Row>
                        <Row>
                            {pizza.map((i, k) => {
                                return (
                                    <Col sm="6" className="mt-3 position-relative" key={k}>
                                        <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                                            <Col sm="6">
                                                <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            </Col>
                                            <Col sm="6">
                                                <div style={{ paddingLeft: '10px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <label>{i.name}</label>
                                                        <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                                                    </div>

                                                    <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                                    <p>{i.detail}</p>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="MenuInner" style={{ height: 'auto' }}>
                                            <Col sm="8" className="p-0">
                                                <label>{i.name}</label>
                                                <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                                <div className="form-group mt-2">
                                                    <select className="form-control productSelect" name={i.name} onChange={(e) => sizeHandler(e)}>
                                                        <option className="d-none">Quantity</option>
                                                        <option value={i.smallPrice}>3 PCS ${i.smallPrice} (Select 1 side)</option>
                                                        <option value={i.mediumPrice}>5 PCS ${i.mediumPrice} (Select 1 side)</option>
                                                        <option value={i.largePrice}>10 PCS ${i.largePrice} (Select 2 Sides)</option>
                                                        <option value={i.extraLargePrice}>20 PCS ${i.extraLargePrice} (Select 2 Sides)</option>
                                                    </select>
                                                </div>
                                                <div className="mt-3">
                                                    <select className="form-control" onChange={(e) => sidesHandler(e.target.value, i)} id="DropList">
                                                        <option className="d-none">Side</option>
                                                        {pizza.map((i) => i.groupsIngredients.map((group) => group.ingredientsItems.map((ing, key) => {
                                                            return <option value={ing.name} key={key}>{ing.name}</option>
                                                        })))}
                                                    </select>
                                                </div>
                                                <div className="form-group mt-2 productConfirm friedChicken" onClick={() => addtocartHandler(i)}>
                                                    <span>Add To Cart</span>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="productImgDiv">
                                                <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                                                <img src={i.image} className="ProductImg" />
                                                {
                                                    pizza.map((i) => i.groupsIngredients.find((group) => group.addedIngredients))[0] !== undefined
                                                    && <>
                                                        <label>selected</label>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            {pizza.map((i) => i.groupsIngredients.map((group) => group.addedIngredients?.map((ing, key) => {
                                                                return <div className="sides" key={key} onClick={() => removeHandler(ing)}>
                                                                    <X />
                                                                    <b>{ing.name}</b>
                                                                </div>
                                                            })))}
                                                        </div>
                                                    </>
                                                }
                                            </Col>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Row>
                </Container >
            ) : (
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Spinner style={{ color: '#ffc000' }} />
                </div>
            )}
        </React.Fragment >
    );
};
const mapStateToProps = (state) => {
    return {
        Menu: state.Pizza.Menu,
        productId: state.Pizza.productId,
        cart: state.Pizza.cart,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        cartHandler: (value) => dispatch({ type: 'CART', value: value }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FriedChicken);
