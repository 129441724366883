import React, { useState } from 'react';
import { Container, Row, Modal, ModalBody, Col, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { AlertCircle, X } from 'react-feather';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Header from './Header';
const Pasta = (props) => {
    const [desc, setdesc] = useState(-1);
    const [pizza, setPizza] = useState([]);
    const [modal, setModal] = useState(false);
    const [pizzaFilter, setPizzaFilter] = useState([]);
    const [selected, setSelected] = useState();
    const [loader, setLoader] = useState(false);

    const getProduct = async () => {
        var axios = require('axios');
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/categories/13/products`,
            headers: {
                Accept: 'application/json',
            },
        };

        const Response = await axios(config);
        const types = Response.data.response.data.products.map((i) => ({
            ...i,
            groupsIngredients: i.groupsIngredients.map((ing) => ({ ...ing, type: ing.name === 'Choice of Ingredients' ? 'PreSelected' : 'AddExtra' })),
        }));
        const quantitySet = types.map((i) => {
            return Object.assign({}, i, { basePrice: i.price ? i.price : i.spaghettiPrice, price: i.price ? i.price : i.spaghettiPrice, quantity: 1, size: "Small Size" });
        });
        setPizza(quantitySet);
        setPizzaFilter(quantitySet);
        setLoader(true);
    };

    React.useEffect(() => {
        if (props.productId) {
            getProduct();
        }
    }, [props.productId]);

    const addtocartHandler = (i) => {
        // const data = [...pizza];
        // const filterIngedeints = data.filter((f) => {
        //     return f.name === i.name;
        // });

        if (i.quantity) {
            props.cartHandler(props.cart.concat(i));
            toast.success(`${i.name} added in the cart`);
            // const cart = props.cart;
            // let valid = cart.find((f) => {
            //     return f.name === filterIngedeints[0].name;
            // });
            // if (cart.length === 0) {
            //     props.cartHandler([filterIngedeints[0]]);
            //     toast.success(`${filterIngedeints[0].name} Added To Cart`);
            // } else {
            //     if (!valid) {
            //         const data = cart.concat(filterIngedeints[0]);
            //         props.cartHandler(data);
            //         toast.success(`${filterIngedeints[0].name} Added To Cart`);
            //     } else {
            //         const users = props.cart.map((u) => (u.id !== filterIngedeints[0].id ? u : filterIngedeints[0]));
            //         props.cartHandler(users);
            //         toast.success(`${filterIngedeints[0].name} Already Added To Cart`);
            //     }
            // }
        } else {
            toast.error('Please Select Quantity');
        }
    };

    const SpaghettiHandler = (e, mainItem) => {
        const data = [...pizza];
        const filterIngedeints = data.filter((f) => {
            return f.name === mainItem.name;
        });
        const extra = filterIngedeints[0].groupsIngredients.map((i) => (i.addedExtra?.length ? i.addedExtra?.reduce((a, b) => +a.smallPrice + +b.smallPrice) : null));
        const add = filterIngedeints.map((add) => ({ ...add, price: add.quantity ? add.quantity * e + extra[0] : e, basePrice: add.quantity ? add.quantity * e + extra[0] : e }));
        var editedUser = add[0];
        const users = pizza.map((u) => (u.id !== add[0].id ? u : editedUser));
        setPizza(users);
    };

    const qunatityHandler = (e) => {
        const data = [...pizza];
        const filterIngedeints = data.filter((f) => {
            return f.name === e.target.name;
        });
        const add = filterIngedeints.map((add) => ({ ...add, quantity: e.target.value, price: e.target.value * add.basePrice }));
        var editedUser = add[0];
        const users = pizza.map((u) => (u.id !== add[0].id ? u : editedUser));
        setPizza(users);
    };

    const customHandler = (i) => {
        props.productHandler([i]);
        props.history.push(`/item/${i.name.replace(/\s+/g, '_').toLowerCase()}`);
    };

    return (
        <React.Fragment>
            <Header props={props} />
            {loader ? (
                <Container>
                    <Row className="mt-3">
                        <h1 style={{ fontWeight: '600' }}>Pasta</h1>
                    </Row>
                    <Row>
                        <Row>
                            {pizza.map((i, k) => {
                                return (
                                    <Col sm="6" className="mt-3 position-relative" key={k}>
                                        <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                                            <Col sm="6">
                                                <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            </Col>
                                            <Col sm="6">
                                                <div style={{ paddingLeft: '10px' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <label>{i.name}</label>
                                                        <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                                                    </div>
                                                    <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                                    <p>{i.detail}</p>
                                                </div>
                                            </Col>
                                        </div>
                                        <div className="MenuInner">
                                            <Col sm="8" className="p-0">
                                                <label>{i.name}</label>
                                                <span className="d-block">${Number(i.price).toFixed(2)}</span>
                                                <select className="form-control productSelect" name={i.name} onChange={(e) => SpaghettiHandler(e.target.value, i)}>
                                                    <option value={i.spaghettiPrice}>Spaghetti ${i.spaghettiPrice}</option>
                                                    <option value={i.stuffedShellsPrice}>Stuffed Shells ${i.stuffedShellsPrice}</option>
                                                </select>
                                                <div className="d-flex customPizzaBtn respCustimBtn">
                                                    <select className="stromboliInput" name={i.name} onChange={(e) => qunatityHandler(e)}>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                    </select>
                                                    <span className="pizzaBtn" onClick={() => addtocartHandler(i)}>
                                                        Add To Cart
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col sm="4" className="productImgDiv">
                                                <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                                                <img src={i.image} className="ProductImg" />
                                                <span style={{ color: '#3434fb', marginTop: '5px', cursor: 'pointer' }} onClick={() => customHandler(i)}>
                                                    Customize
                                                </span>
                                            </Col>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Row>
                </Container>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Spinner style={{ color: '#ffc000' }} />
                </div>
            )}
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        Menu: state.Pizza.Menu,
        productId: state.Pizza.productId,
        cart: state.Pizza.cart,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        cartHandler: (value) => dispatch({ type: 'CART', value: value }),
        productHandler: (value) => dispatch({ type: 'PRODUCT', value: value }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Pasta);
