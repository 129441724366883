import React from 'react';
import { Col, Container, FormGroup, Row, Spinner } from 'reactstrap';
import * as yup from 'yup';
import { useFormik, Field, FormikProvider } from 'formik';
import axios from 'axios';
import Media from './Media';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import Header from './Header';
import toast from 'react-hot-toast';

const Register = (props) => {
    const [Loader, setLoader] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            password: '',
            email: '',
            confirmPassword: '',
            FirstName: '',
            LastName: '',
            Phone: '',
            address: "",
            apartment: '',
            city: "",
            state: "",
            zip: "",
            street: "",
            image: "",
            extension: ""
        },
        validationSchema: yup.object().shape({
            email: yup.string().required("Email Is A Required Field").email(),
            password: yup.string()
                .required("Password Is A Required Field!")
                .min(8, 'Password Is Too Short - Should Be 8 Chars Minimum.')
                .matches(/[a-zA-Z]/, 'Password Can Only Contain Latin Letters.'),
            confirmPassword: yup.string()
                .oneOf([yup.ref('password'), null], 'Passwords Must Match'),
            FirstName: yup.string().required("First Name Is A Required Field"),
            LastName: yup.string().required("Last Name Is A Required Field"),
            Phone: yup.string().required("Phone Is A Required Field"),
            address: yup.string().required("Address Is A Required Field"),
            apartment: yup.string(),
            city: yup.string().required("City Is A Required Field"),
            state: yup.string().required("State Is A Required Field"),
            zip: yup.string().required("Zip Code Is A Required Field"),
            street: yup.string().required("Street Is A Required Field"),
            lat: yup.string("Latitude Is A Required Field"),
            lng: yup.string("Longitude Is A Required Field"),
            time: yup.string("Time Is A Required Field"),
            extension: yup.string(),
            image: yup.mixed(),
        }),
        onSubmit: values => {
            registerHandler(values)
        },
    });

    let autocomplete = null;

    const registerHandler = async (values) => {
        setLoader(true);
        try {
            var data = new FormData();
            data.append('email', values.email);
            data.append('phoneNumber', values.Phone);
            data.append('firstName', values.FirstName);
            data.append('lastName', values.LastName);
            data.append('address', values.address);
            data.append('password', values.password);
            formik.values.image && data.append('image', values.image);
            data.append('city', values.city);
            data.append('state', values.state);
            data.append('zipCode', values.zip);
            data.append('apartment', values.apartment);
            data.append('lat', values.lat);
            data.append('lng', values.lng);
            data.append('deliveryTime', values.time);
            formik.values.extension && data.append('extension', values.extension);

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/v1/signup`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            const Response = await axios(config);
            setLoader(false);
            toast.success(`Welcome ${Response.data.response.data.auth.firstName}`);
            props.tokenHandler(Response.data.response.data.auth);
            props.signHandler(true);
            props.history.push({
                pathname: '/dashboard',
                state: { some: 'Profile' }
            })
        } catch (e) {
            toast.error(`${e.response.data.response.message}`);
            setLoader(false);
        }
    };

    const getLocation = React.useCallback(() => {
        const input = document.getElementById("pac-input");
        const options = {
            types: ['address'],
        };
        // eslint-disable-next-line no-undef
        let auto_complete = new google.maps.places.Autocomplete(
            input,
            options,
        );

        auto_complete.setFields(['address_components', 'name', "geometry"]);

        auto_complete.addListener('place_changed', () => {
            autocomplete = auto_complete.getPlace();
            if (autocomplete) {
                checkAddress(autocomplete);
            }
        });

        auto_complete.addListener('place_changed', () => {
            autocomplete = auto_complete.getPlace();
            if (autocomplete) {
                let address = autocomplete.address_components;
                const street = address.filter((i) => i.types[0] === "street_number");
                const route = address.filter((i) => i.types[0] === "route");
                const city = address.filter((i) => i.types[0] === "locality" || i.types[0] === "sublocality_level_1");
                const state = address.filter((i) => i.types[0] === "administrative_area_level_1");
                const zip = address.filter((i) => i.types[0] === "postal_code");
                formik.setFieldValue("address", autocomplete.name);
                formik.setFieldValue("city", city[0]?.long_name);
                formik.setFieldValue("state", state[0]?.long_name);
                formik.setFieldValue("street", `${route[0].long_name} ${street[0].long_name}`);
                formik.setFieldValue("lat", autocomplete.geometry.location.lat());
                formik.setFieldValue("lng", autocomplete.geometry.location.lng());
                formik.setFieldValue("zip", zip[0]?.short_name);
            }
        });

    }, [autocomplete]);

    const checkAddress = React.useCallback(async (autocomplete) => {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()

        var start = '39.27574376183423, -77.52916387534584';
        var end = `${autocomplete.geometry.location.lat()}, ${autocomplete.geometry.location.lng()}`;

        const results = await directionsService.route(
            {
                origin: start,
                destination: end,
                travelMode: "DRIVING",
                // eslint-disable-next-line no-undef
                unitSystem: google.maps.UnitSystem.METRIC
            }
        )
        const address = results.routes[0].legs[0].distance.value;
        const miles = address * 0.000621;

        if (miles < 10) {
            let address = autocomplete.address_components;
            const street = address.filter((i) => i.types[0] === "street_number");
            const route = address.filter((i) => i.types[0] === "route");
            const city = address.filter((i) => i.types[0] === "locality" || i.types[0] === "sublocality_level_1");
            const state = address.filter((i) => i.types[0] === "administrative_area_level_1");
            const zip = address.filter((i) => i.types[0] === "postal_code");
            formik.setFieldValue("address", autocomplete.name);
            formik.setFieldValue("city", city[0]?.long_name);
            formik.setFieldValue("state", state[0]?.long_name);
            formik.setFieldValue("street", `${route[0].long_name} ${street[0].long_name}`);
            formik.setFieldValue("lat", autocomplete.geometry.location.lat());
            formik.setFieldValue("lng", autocomplete.geometry.location.lng());
            formik.setFieldValue("zip", zip[0]?.short_name);
            formik.setFieldValue("time", results.routes[0].legs[0].duration.text);
        } else {
            toast.error("Sorry we don’t deliver in your area but you can place an order for pickup");
        }

    }, [props, autocomplete]);

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <React.Fragment>
            <Header props={props} />
            <Container>
                <h2 className="mt-3">Create Account</h2>
                <Row>
                    <FormikProvider value={formik}>
                        <Col sm="5">
                            <div>
                                <form className="registerForm" onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
                                    <div className="mb-2">
                                        <span>ACCOUNT INFORMATION</span>
                                    </div>
                                    <label>Image</label>
                                    <FormGroup className="mb-2">
                                        <Field name="image" type="file" setFieldValue={formik.setFieldValue} component={Media} className={`form-control ${formik.errors.image && formik.touched.image && 'is-invalid'}`} />
                                        {formik.errors.image && formik.touched.image ? <div className="text-danger">{formik.errors.image}</div> : null}
                                    </FormGroup>
                                    <label>Email</label>
                                    <FormGroup className="mb-2">
                                        <input onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email} name="email" type="email" className={`form-control ${formik.errors.email && formik.touched.email && 'is-invalid'}`} />
                                        {formik.errors.email && formik.touched.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                    </FormGroup>
                                    <label>Password</label>
                                    <FormGroup className="mb-2">
                                        <input onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password} name="password" type="password" className={`form-control ${formik.errors.password && formik.touched.password && 'is-invalid'}`} />
                                        {formik.errors.password && formik.touched.password ? <div className="text-danger">{formik.errors.password}</div> : null}
                                    </FormGroup>
                                    <label>Confirm Password</label>
                                    <FormGroup onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirmPassword} className="mb-2">
                                        <input name="confirmPassword" type="password" className={`form-control ${formik.errors.confirmPassword && formik.touched.confirmPassword && 'is-invalid'}`} />
                                        {formik.errors.confirmPassword && formik.touched.confirmPassword ? <div className="text-danger">{formik.errors.confirmPassword}</div> : null}
                                    </FormGroup>
                                    <div className="mb-2">
                                        <span>YOUR INFORMATION</span>
                                    </div>
                                    <label>First name</label>
                                    <FormGroup className="mb-2">
                                        <input onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.FirstName} name="FirstName" type="text" className={`form-control ${formik.errors.FirstName && formik.touched.FirstName && 'is-invalid'}`} />
                                        {formik.errors.FirstName && formik.touched.FirstName ? <div className="text-danger">{formik.errors.FirstName}</div> : null}
                                    </FormGroup>
                                    <label>Last name</label>
                                    <FormGroup onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.LastName} className="mb-2">
                                        <input name="LastName" type="text" className={`form-control ${formik.errors.LastName && formik.touched.LastName && 'is-invalid'}`} />
                                        {formik.errors.LastName && formik.touched.LastName ? <div className="text-danger">{formik.errors.LastName}</div> : null}
                                    </FormGroup>
                                    <label>Phone</label>
                                    <FormGroup className="mb-2">
                                        <InputMask mask="999-999-9999" className={`form-control ${formik.errors.Phone && formik.touched.Phone && 'is-invalid'}`}
                                            onChange={(e) => formik.setFieldValue('Phone', e.target.value)} value={props.value} />
                                        {formik.errors.Phone && formik.touched.Phone ? <div className="text-danger">{formik.errors.Phone}</div> : null}
                                    </FormGroup>
                                    <label>Extension</label>
                                    <FormGroup className="mb-2">
                                        <input autoComplete="off" name="extension"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={`form-control ${formik.errors.extension && formik.touched.extension && 'is-invalid'}`} />
                                        {formik.errors.extension && formik.touched.extension ? <div className="text-danger">{formik.errors.extension}</div> : null}
                                    </FormGroup>
                                    <label>Address</label>
                                    <FormGroup className="mb-2">
                                        <input autoComplete="off" name="address" id="pac-input"
                                            onChange={(e) => {
                                                getLocation();
                                                formik.setFieldValue("address", e.target.value)
                                            }} className={`form-control ${formik.errors.address && formik.touched.address && 'is-invalid'}`} />
                                        {formik.errors.address && formik.touched.address ? <div className="text-danger">{formik.errors.address}</div> : null}
                                    </FormGroup>
                                    <label>Apartment</label>
                                    <FormGroup
                                        onChange={(e) => formik.setFieldValue("apartment", e.target.value)}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.apartment} className="mb-2">
                                        <input name="Apartment" type="text" className={`form-control ${formik.errors.apartment && formik.touched.apartment && 'is-invalid'}`} />
                                        {formik.errors.apartment && formik.touched.apartment ? <div className="text-danger">{formik.errors.apartment}</div> : null}
                                    </FormGroup>
                                    <label>City</label>
                                    <FormGroup className="mb-2">
                                        <input
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.city} name="city" type="text" className={`form-control ${formik.errors.city && formik.touched.city && 'is-invalid'}`} />
                                        {formik.errors.city && formik.touched.city ? <div className="text-danger">{formik.errors.city}</div> : null}
                                    </FormGroup>
                                    <label>State</label>
                                    <FormGroup className="mb-2">
                                        <input onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.state} name="state" type="text" className={`form-control ${formik.errors.state && formik.touched.state && 'is-invalid'}`} />
                                        {formik.errors.state && formik.touched.state ? <div className="text-danger">{formik.errors.state}</div> : null}
                                    </FormGroup>
                                    <label>Street</label>
                                    <FormGroup className="mb-2">
                                        <input onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.street} name="street" type="text" className={`form-control ${formik.errors.street && formik.touched.street && 'is-invalid'}`} />
                                        {formik.errors.street && formik.touched.street ? <div className="text-danger">{formik.errors.street}</div> : null}
                                    </FormGroup>
                                    <label>Zip</label>
                                    <FormGroup className="mb-2">
                                        <input onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.zip} name="zip" type="text" className={`form-control ${formik.errors.zip && formik.touched.zip && 'is-invalid'}`} />
                                        {formik.errors.zip && formik.touched.zip ? <div className="text-danger">{formik.errors.zip}</div> : null}
                                    </FormGroup>
                                    <button type="submit" className="Btn" disabled={Loader}>
                                        {!Loader ? 'Submit' : <Spinner style={{ color: '#fff' }} />}
                                    </button>
                                </form>
                            </div>
                        </Col>
                    </FormikProvider>

                </Row>
            </Container>
        </React.Fragment>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        tokenHandler: (value) => dispatch({ type: 'TOKEN', value: value }),
        signHandler: (value) => dispatch({ type: 'SIGNED', value: value }),
    };
};
export default connect(null, mapDispatchToProps)(Register);
