import React, { useState } from 'react';
import { Container, Row, Spinner, Col } from 'reactstrap';
import { AlertCircle, X } from 'react-feather';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Header from './Header';
const MenuWings = (props) => {
  const [desc, setdesc] = useState(-1);
  const [pizza, setPizza] = useState([]);
  const [, setPizzaFilter] = useState([]);
  const [loader, setLoader] = useState(false);
  const [flavor, setFlavor] = useState(false);

  const getProduct = async () => {
    var axios = require('axios');
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_URL}/v1/categories/4/products`,
      headers: {
        Accept: 'application/json',
      },
    };

    const Response = await axios(config);
    const types = Response.data.response.data.products.map((i) => ({
      ...i,
      groupsIngredients: i.groupsIngredients.map((ing) => ({
        ...ing,
        addedIngredients: ing.ingredientsItems.filter((filter) => filter.isSelected),
        type: ing.name === 'Choice of Ingredients' ? 'PreSelected' : 'AddExtra',
      })),
    }));
    const quantitySet = types.map((i) => {
      return Object.assign({}, i, { basePrice: i.price ? i.price : i.smallPrice, quantity: 1, price: i.smallPrice });
    });
    setPizza(quantitySet);
    setPizzaFilter(quantitySet);
    setLoader(true);
  };
  React.useEffect(() => {
    if (props.productId) {
      getProduct();
    }
    return () => {
      setPizza([]);
      setdesc(-1);
      setPizzaFilter([]);
      setLoader(false);
    };
  }, [props.productId]);

  const addtocartHandler = (i) => {
    const alteredData = [...pizza];
    if (i.size) {
      if (flavor) {
        const cart = props.cart;
        const sameProduct = props.cart.map((item) => item.id === i.id);
        const sameProductCount = sameProduct.filter(Boolean);

        const quantitySet = alteredData.map((i) => {
          return Object.assign({}, i, {
            price: i.extra ? +i.price + +i.extra : +i.price,
            basePrice: i.extra ? +i.price + +i.extra : +i.price,
            productName: `${i.name}${sameProductCount.length}`,
          });
        });
        const data = cart.concat(quantitySet[0]);
        props.cartHandler(data);
        toast.success(`${quantitySet[0].name} Added To Cart`);
      } else {
        toast.error("Please Select Flavor");
      }
    } else {
      toast.error("Please Select Quantity")
    }
  };

  const sizeHandler = (e) => {
    const data = [...pizza];
    const quantitySet = data.map((i) => {
      if (i.name == e.target.name)
        return Object.assign({}, i, {
          size: e.target.value,
          price: e.target.value === '7 Wings' ? i.smallPrice * i.quantity : e.target.value === '14 Wings' ? i.mediumPrice * i.quantity : e.target.value === '21 Wings' ? i.largePrice * i.quantity : null,
          basePrice:
            e.target.value === '7 Wings' ? i.smallPrice * i.quantity : e.target.value === '14 Wings' ? i.mediumPrice * i.quantity : e.target.value === '21 Wings' ? i.largePrice * i.quantity : null,
        });
      return i;
    });
    setPizza(quantitySet);
  };

  const qunatityHandler = (e) => {
    const data = [...pizza];
    const filterIngedeints = data.filter((f) => {
      return f.name === e.target.name;
    });
    const add = filterIngedeints.map((add) => ({ ...add, quantity: e.target.value, price: e.target.value * add.basePrice }));
    var editedUser = add[0];
    const users = pizza.map((u) => (u.id !== add[0].id ? u : editedUser));
    setPizza(users);
  };

  const flovorHandler = (e) => {
    const data = [...pizza];
    const filter = data.filter((f) => f.name === e.target.name);

    const group = filter.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === 'Choice of Sauce';
      });
    });
    const removedIngredient = group[0].map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === e.target.value) }));
    var editedUser = removedIngredient[0];
    const users = filter.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
    setPizza(users);
    setFlavor(true);
  };

  const extraHandler = (e) => {
    const data = [...pizza];
    const filter = data.filter((f) => f.name === e.target.name);

    const group = filter.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === 'Add Extra';
      });
    });
    const removedIngredient = group[0].map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === e.target.value) }));
    var editedUser = removedIngredient[0];
    const users = filter.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
    const quantitySet = users.map((i) => {
      return Object.assign({}, i, {
        extra: +removedIngredient[0].addedIngredients[0].smallPrice
      });
    });
    var editedUser2 = quantitySet[0];
    const users2 = data.map((u) => (u.id !== editedUser2.id ? u : editedUser2));
    setPizza(users2)
  };

  return (
    <React.Fragment>
      <Header props={props} />
      {loader ? (
        <Container>
          <Row className="mt-3">
            <h1 style={{ fontWeight: '600' }}>Wings</h1>
          </Row>
          <Row>
            <Row className="mb-1">
              {pizza.map((i, k) => {
                return (
                  <Col sm="6" className="mt-3 position-relative" key={k}>
                    <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                      <Col sm="12" md="12" lg="6">
                        <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </Col>
                      <Col sm="12" md="12" lg="6">
                        <div style={{ paddingLeft: '10px' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label>{i.name}</label>
                            <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                          </div>
                          <span className="d-block">${Number(i.extra ? +i.price + +i.extra : i.price).toFixed(2)}</span>
                          <p>{i.detail}</p>
                        </div>
                      </Col>
                    </div>
                    <div className="MenuInner wings">
                      <Col sm="12" md="12" lg="8" className="p-0">
                        <label>{i.name}</label>
                        <span className="d-block">${Number(i.extra ? +i.price + +i.extra : i.price).toFixed(2)}</span>
                        <div className="form-group mt-2">
                          <select className="form-control productSelect" name={i.name} onChange={(e) => sizeHandler(e)}>
                            <option className="d-none">Quantity</option>
                            <option value="7 Wings">7 Wings</option>
                            <option value="14 Wings">14 Wings</option>
                            <option value="21 Wings">21 Wings</option>
                          </select>
                        </div>
                        <div className="form-group mt-2">
                          <select className="form-control productSelect" name={i.name} onChange={(e) => flovorHandler(e)}>
                            <option className="d-none">Choose Flavour</option>
                            {i.groupsIngredients.filter((f) => f.name === "Choice of Sauce").map((group) =>
                              group.ingredientsItems.map((ing, ingKey) => {
                                return <option key={ingKey} value={ing.name}>{ing.name}</option>;
                              })
                            )}
                          </select>
                          <select className="form-control productSelect mt-2" name={i.name} onChange={(e) => extraHandler(e)} >
                            <option className="d-none">Choose Dressing</option>
                            {i.groupsIngredients.filter((f) => f.name === "Add Extra").map((group) =>
                              group.ingredientsItems.map((ing, ingKey) => {
                                return <option key={ingKey} value={ing.name}>{ing.name} (${ing.smallPrice})</option>;
                              })
                            )}
                          </select>
                        </div>
                        <div className="d-flex customPizzaBtn respCustimBtn">
                          <select className="stromboliInput" name={i.name} onChange={(e) => qunatityHandler(e)}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                          </select>
                          <span className="pizzaBtn" onClick={() => addtocartHandler(i)}>
                            Add To Cart
                          </span>
                        </div>
                      </Col>
                      <Col sm="12" md="12" lg="4" className="productImgDiv">
                        <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                        <img src={i.image} className="ProductImg" />
                      </Col>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Row>
        </Container>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '30px' }}>
          <Spinner style={{ color: '#ffc000' }} />
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    Menu: state.Pizza.Menu,
    productId: state.Pizza.productId,
    cart: state.Pizza.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cartHandler: (value) => dispatch({ type: 'CART', value: value }),
    productHandler: (value) => dispatch({ type: 'PRODUCT', value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuWings);
