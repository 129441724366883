import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody, ModalHeader, Card, CardBody, CardFooter } from 'reactstrap';
import Images from './Constants';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { CheckCircle, ChevronDown, ChevronUp, X, ChevronRight } from 'react-feather';
import LeftGray from '../Assests/button-LRW-left-active.png';
import Rightgray from '../Assests/button-LRW-right-active.png';
import FullGray from '../Assests/button-LRW-whole-active.png';
import FullRed from '../Assests/button-LRW-whole-selected.png';
import RightRed from '../Assests/button-LRW-right-selected.png';
import LeftRed from '../Assests/button-LRW-left-selected.png';
import toast from 'react-hot-toast';
import Header from './Header';
const PizzaMaker = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [, setCrust] = useState([]);
  const [, setSauce] = useState([]);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalImg,] = useState();
  const [modalName,] = useState();
  const [seeMore, setSeeMore] = useState([3, '']);
  const [fixed, setFixed] = useState(false);
  const [done, setDone] = useState(false);
  const scroll = React.useRef();

  React.useEffect(() => {
    if (props.product) {
      const quantitySet = props.product.map((i) => {
        return Object.assign({}, i, {
          toppingPrice: 0,
          crustPrice: 0,
          saucePrice: 0,
          cheesePrice: 0,
        });
      });
      setData(quantitySet);
    }
  }, [props.product]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    const Crust = Images.Crust.filter((f) => {
      return f.name === props.Crust;
    });
    setCrust(Crust);
    const Sauce = Images.Sauce.filter((f) => {
      return f.name === props.Sauce;
    });
    setSauce(Sauce);
  }, [props]);

  const crustHandler = (l, group) => {
    const product = [...data];
    const filter = product.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });

    const removedIngredient = filter[0].map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === l.name) }));
    var editedUser = removedIngredient[0];
    const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
    const crustPrice = removedIngredient.flat().map((k) =>
      k.addedIngredients.map((price) => {
        if (product[0].size === 'Small Size') {
          return price.smallPrice;
        } else if (product[0].size === 'Medium Size') {
          return price.mediumPrice;
        } else if (product[0].size === 'Large Size') {
          return price.largePrice;
        } else if (product[0].size === 'Extra Large Size') {
          return price.extraLargePrice;
        }
      })
    );
    const priceFlat = crustPrice.flat();
    const price = priceFlat.flat()[0];

    const quantitySet = users.map((i) => {
      return Object.assign({}, i, {
        crustPrice: price,
        price: data[0].size === "Small Size" ? data[0].smallPrice :
          data[0].size === "Medium Size" ? data[0].mediumPrice :
            data[0].size === "Large Size" ? data[0].largePrice :
              data[0].size === "Extra Large Size" ? data[0].extraLargePrice :
                null
      });
    });
    setData(quantitySet);
  };

  const sauceHandler = (l, group) => {
    const product = [...data];
    const filter = product.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });

    const removedIngredient = filter.flat().map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === l.name).map((k) => ({ ...k, size: 'Medium Size' })) }));
    var editedUser = removedIngredient[0];
    const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
    const crustPrice = removedIngredient.flat().map((k) =>
      k.addedIngredients.map((price) => {
        if (product[0].size === 'Small Size') {
          return price.smallPrice;
        } else if (product[0].size === 'Medium Size') {
          return price.smallPrice;
        } else if (product[0].size === 'Large Size') {
          return price.smallPrice;
        } else if (product[0].size === 'Extra Large Size') {
          return price.extraLargePrice;
        }
      })
    );
    const priceFlat = crustPrice.flat();
    const price = priceFlat.flat()[0];
    const quantitySet = users.map((i) => {
      return Object.assign({}, i, {
        saucePrice: price,
      });
    });
    setData(quantitySet);
  };

  const cheeseHandler = (l, group) => {
    const product = [...data];
    const valid = product.map((i) => {
      return i.groupsIngredients
        .filter((filter) => {
          return filter.name === group.name;
        })
        .map((t) => {
          return t.addedIngredients?.find((f) => f.name === l.name);
        });
    });
    if (!valid[0][0]) {
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });

      const testAdded = filter.flat().map((i) => i.addedIngredients?.map((ing) => ing.size));

      const removedIngredient = filter.flat().map((i) => ({
        ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === l.name)
          .map((k) => ({ ...k, size: testAdded.flat()[0] ? testAdded.flat()[0] : 'Small Size', mainSize: product[0].size })).concat(i.addedIngredients),
      }));

      var editedUser = removedIngredient[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      const crustPrice = removedIngredient.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (price.mainSize === 'Small Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.smallPrice * 2 : price.smallPrice;
            }
          } else if (price.mainSize === 'Medium Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.mediumPrice * 2 : price.mediumPrice;
            }
          }
          else if (price.mainSize === 'Large Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.largePrice * 2 : price.largePrice;
            }
          }
          else if (price.mainSize === 'Extra Large Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.extraLargePrice * 2 : price.extraLargePrice;
            }
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.reduce((a, b) => +a + +b, 0);
      const quantitySet = users.map((i) => {
        return Object.assign({}, i, {
          cheesePrice: price,
        });
      });
      setData(quantitySet);
    } else {
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });

      const ingredient = filter.flat().map((i) => ({
        ...i, addedIngredients: i.addedIngredients.filter((f) => f.name === l.name)
      }));

      const crustPrice = ingredient.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (price.mainSize === 'Small Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.smallPrice * 2 : price.smallPrice;
            }
          } else if (price.mainSize === 'Medium Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.mediumPrice * 2 : price.mediumPrice;
            }
          }
          else if (price.mainSize === 'Large Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.largePrice * 2 : price.largePrice;
            }
          }
          else if (price.mainSize === 'Extra Large Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.extraLargePrice * 2 : price.extraLargePrice;
            }
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.reduce((a, b) => +a + +b, 0)

      const quantitySet = product.map((i) => {
        return Object.assign({}, i, {
          cheesePrice: i.cheesePrice - price,
        });
      });

      const removedIngredient = filter.flat().map((i) => ({
        ...i, addedIngredients: i.addedIngredients.filter((f) => f.name !== l.name)
      }));
      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));

      setData(users);
    }
  };

  const toppingHandler = (l, group) => {
    const product = [...data];
    const valid = product.map((i) => {
      return i.groupsIngredients
        .filter((filter) => {
          return filter.name === group.name;
        })
        .map((t) => {
          return t.addedIngredients?.find((f) => f.name === l.name);
        });
    });

    if (!valid[0][0]) {
      const quantitySet = data.map((i) => {
        if (i.size === "Small Size") {
          return Object.assign({}, i, {
            toppingPrice: +i.toppingPrice + +l.smallPrice,
          });
        } else if (i.size === "Medium Size") {
          return Object.assign({}, i, {
            toppingPrice: +i.toppingPrice + +l.mediumPrice,
          });
        }
        else if (i.size === "Large Size") {
          return Object.assign({}, i, {
            toppingPrice: +i.toppingPrice + +l.largePrice,
          });
        }
        else if (i.size === "Extra Large Size") {
          return Object.assign({}, i, {
            toppingPrice: +i.toppingPrice + +l.extraLargePrice,
          });
        }
      });

      const filter = quantitySet.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });

      const removedIngredient = filter[0].map((ing) => ({
        ...ing,
        addedIngredients:
          ing.addedIngredients === undefined
            ? ing.ingredientsItems.filter((f) => f.name === l.name).map((k) => ({ ...k, size: 'Small Size', topping: 'whole', mainSize: product[0].size }))
            : ing.ingredientsItems
              .filter((f) => f.name === l.name)
              .map((k) => ({ ...k, size: 'Small Size', topping: 'whole', mainSize: product[0].size }))
              .concat(ing.addedIngredients),
      }));
      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    }
    // Remove
    else {
      const valid = product.map((i) => {
        return i.groupsIngredients
          .filter((filter) => {
            return filter.name === group.name;
          })
          .map((t) => {
            return t.addedIngredients?.find((f) => f.name === l.name);
          });
      });
      const topping = valid.flat()[0].topping;
      const mainSize = valid.flat()[0].mainSize ? valid.flat()[0].mainSize : product[0].size;
      const size = valid.flat()[0].size;
      const quantitySet = data.map((i) => {
        switch (mainSize) {
          case "Small Size":
            if (size === "Extra Large Size") {
              if (topping === "whole") {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.smallPrice * 2
                })
              } else {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.smallPrice,
                })
              }
            }
            else {
              if (topping === "whole") {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.smallPrice
                })
              } else {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.smallPrice / 2,
                })
              }
            }
          case "Medium Size":
            if (size === "Extra Large Size") {
              if (topping === "whole") {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.mediumPrice * 2
                })
              } else {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.mediumPrice,
                })
              }
            } else {
              if (topping === "whole") {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.mediumPrice
                })
              } else {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.mediumPrice / 2,
                })
              }
            }
          case "Large Size":
            if (size === "Large Size") {
              if (topping === "whole") {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.largePrice * 2
                })
              } else {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.largePrice,
                })
              }
            } else {
              if (topping === "whole") {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.largePrice
                })
              } else {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.largePrice / 2,
                })
              }
            }
          case "Extra Large Size":
            if (size === "Extra Large Size") {
              if (topping === "whole") {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.extraLargePrice * 2
                })
              } else {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.extraLargePrice,
                })
              }
            } else {
              if (topping === "whole") {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.extraLargePrice
                })
              } else {
                return Object.assign({}, i, {
                  toppingPrice: +i.toppingPrice - +l.extraLargePrice / 2,
                })
              }
            }
        }
      });
      const filter = quantitySet.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const removedIngredient = filter[0].map((ing) => ({ ...ing, addedIngredients: ing.addedIngredients.filter((f) => f.name !== l.name) }));
      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    }
  };

  const ingredientHandler = (i, group) => {
    switch (group.name) {
      case 'Choice of Crust':
        crustHandler(i, group);
        break;
      case 'Choice of Sauce':
        sauceHandler(i, group);
        break;
      case 'Cheese':
        cheeseHandler(i, group);
        break;
      case 'Meats':
        toppingHandler(i, group);
        break;
      case 'Veggies':
        toppingHandler(i, group);
        break;
      case 'Choice of Ingredients':
        ingredientsHandler(i, group);
        break;
    }
  };

  const ingredientsHandler = (l, group) => {
    const product = [...data];
    const valid = product.map((i) => {
      return i.groupsIngredients
        .filter((filter) => {
          return filter.name === group.name;
        })
        .map((t) => {
          return t.addedIngredients?.find((f) => f.name === l.name);
        });
    });

    if (!valid[0][0]) {
      const quantitySet = data.map((i) => {
        return Object.assign({}, i, {
          price: +i.price + +l.smallPrice,
        });
      });
      const filter = quantitySet.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const removedIngredient = filter[0].map((ing) => ({
        ...ing,
        addedIngredients: ing.addedIngredients === undefined ? ing.ingredientsItems.filter((f) => f.name === l.name) : ing.ingredientsItems.filter((f) => f.name === l.name).concat(ing.addedIngredients),
      }));
      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    } else {
      const quantitySet = data.map((i) => {
        return Object.assign({}, i, {
          price: i.price - l.smallPrice,
        });
      });
      const filter = quantitySet.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const removedIngredient = filter[0].map((ing) => ({ ...ing, addedIngredients: ing.addedIngredients.filter((f) => f.name !== l.name) }));
      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    }
  };

  const addtocartHandler = (i) => {
    const cart = props.cart;
    const quantitySet = data.map((i) => {
      return Object.assign({}, i, {
        price: +data[0].price + +data[0].crustPrice + +data[0].saucePrice + +data[0].toppingPrice + +data[0].cheesePrice,
        basePrice: +data[0].price + +data[0].crustPrice + +data[0].saucePrice + +data[0].toppingPrice + +data[0].cheesePrice,
      });
    });

    let valid = cart.find((f) => {
      return f.productName === i[0].productName;
    });

    if (!valid) {
      const data1 = cart.concat(quantitySet[0]);
      props.cartHandler(data1);
      toast.success(`${quantitySet[0].name} added to cart`);
      props.history.push('/cart');
    } else {
      const users = props.cart.map((u) => (u.id !== quantitySet[0].id ? u : quantitySet[0]));
      props.cartHandler(users);
      toast.success(`${quantitySet[0].name} added to cart`);
      props.history.push('/cart');
    }
  };

  const ingredientSizeHandler = (group, i, item, ing) => {
    if (group.addedIngredients.length) {
      switch (group.name) {
        case 'Choice of Crust':
          crustSizeHandler(group, i, item);
          break;
        case 'Choice of Sauce':
          sauceSizeHandler(group, i, item);
          break;
        case 'Cheese':
          cheeseSizeHandler(group, i, item);
          break;
        case 'Meats':
          toppingSizeHandler(group, i, item, ing);
          break;
        case 'Veggies':
          toppingSizeHandler(group, i, item, ing);
          break;
      }
    }
  };

  const crustSizeHandler = (group, l) => {
    const product = [...data];
    const filter = product.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });
    const crustPrice = filter.flat().map((k) =>
      k.addedIngredients.map((price) => {
        if (l === 'Small Size') {
          return price.smallPrice;
        } else if (l === 'Medium Size') {
          return price.mediumPrice;
        } else if (l === 'Large Size') {
          return price.largePrice;
        } else if (l === 'Extra Large Size') {
          return price.extraLargePrice;
        }
      })
    );
    const priceFlat = crustPrice.flat();
    const price = priceFlat.flat()[0];
    const quantitySet = product.map((i) => {
      return Object.assign({}, i, {
        size: l,
        crustPrice: price,
        price: l === "Small Size" ? data[0].smallPrice :
          l === "Medium Size" ? data[0].mediumPrice :
            l === "Large Size" ? data[0].largePrice :
              l === "Extra Large Size" ? data[0].extraLargePrice :
                null
      });
    });

    let array = [];
    quantitySet.forEach((i) => {
      i.groupsIngredients.forEach((k) => {
        if (k.name === "Meats" || k.name === "Veggies") {
          k.addedIngredients.forEach((ing) => {
            switch (l) {
              case "Small Size":
                if (ing.size === "Extra Large Size") {
                  if (ing.topping === "whole") {
                    return array.push(ing.smallPrice * 2);
                  } else {
                    return array.push(ing.smallPrice);
                  }
                } else {
                  if (ing.topping === "whole") {
                    return array.push(ing.smallPrice);
                  } else {
                    return array.push(ing.smallPrice / 2);
                  }
                }
              case "Medium Size":
                if (ing.size === "Extra Large Size") {
                  if (ing.topping === "whole") {
                    return array.push(ing.mediumPrice * 2);
                  } else {
                    return array.push(ing.mediumPrice);
                  }
                } else {
                  if (ing.topping === "whole") {
                    return array.push(ing.mediumPrice);
                  } else {
                    return array.push(ing.mediumPrice / 2);
                  }
                }

              case "Large Size":
                if (ing.size === "Extra Large Size") {
                  if (ing.topping === "whole") {
                    return array.push(ing.largePrice * 2);
                  } else {
                    return array.push(ing.largePrice);
                  }
                } else {
                  if (ing.topping === "whole") {
                    return array.push(ing.largePrice);
                  } else {
                    return array.push(ing.largePrice / 2);
                  }
                }

              case "Extra Large Size":
                if (ing.size === "Extra Large Size") {
                  if (ing.topping === "whole") {
                    return array.push(ing.extraLargePrice * 2);
                  } else {
                    return array.push(ing.extraLargePrice);
                  }
                } else {
                  if (ing.topping === "whole") {
                    return array.push(ing.extraLargePrice);
                  } else {
                    return array.push(ing.extraLargePrice / 2);
                  }
                }
            }
          })
        }
      })
    });

    const top = quantitySet.map((i) => {
      return Object.assign({}, i, {
        toppingPrice: array.reduce((partialSum, a) => +partialSum + +a, 0)
      })
    });

    const toppingPrice = top.map((i) => ({
      ...i, groupsIngredients: i.groupsIngredients.map((grp) => ({
        ...grp, addedIngredients: grp.addedIngredients
          .map((ing) => ({ ...ing, mainSize: grp.name === "Meats" || grp.name === "Veggies" || grp.name === "Cheese" ? l : ing.mainSize }))
      }))
    }));

    const cheeseFilter = toppingPrice.map((cheeseFilter) => cheeseFilter.groupsIngredients.filter((filter) => filter.name === "Cheese")).flat();

    const cheesePriceFilter = cheeseFilter.map((k) =>
      k.addedIngredients.map((price) => {
        if (price.mainSize === 'Small Size') {
          if (price.size === "Small Size") {
            return !price.isSelected ? price.smallPrice : 0;
          } else if (price.size === "Medium Size") {
            return !price.isSelected ? price.smallPrice : 0;
          } else if (price.size === "Extra Large Size") {
            return !price.isSelected ? price.smallPrice * 2 : price.smallPrice;
          }
        } else if (price.mainSize === 'Medium Size') {
          if (price.size === "Small Size") {
            return !price.isSelected ? price.mediumPrice : 0;
          } else if (price.size === "Medium Size") {
            return !price.isSelected ? price.mediumPrice : 0;
          } else if (price.size === "Extra Large Size") {
            return !price.isSelected ? price.mediumPrice * 2 : price.mediumPrice;
          }
        }
        else if (price.mainSize === 'Large Size') {
          if (price.size === "Small Size") {
            return !price.isSelected ? price.largePrice : 0;
          } else if (price.size === "Medium Size") {
            return !price.isSelected ? price.largePrice : 0;
          } else if (price.size === "Extra Large Size") {
            return !price.isSelected ? price.largePrice * 2 : price.largePrice;
          }
        }
        else if (price.mainSize === 'Extra Large Size') {
          if (price.size === "Small Size") {
            return !price.isSelected ? price.extraLargePrice : 0;
          } else if (price.size === "Medium Size") {
            return !price.isSelected ? price.extraLargePrice : 0;
          } else if (price.size === "Extra Large Size") {
            return !price.isSelected ? price.extraLargePrice * 2 : price.extraLargePrice;
          }
        }
      })
    );

    const cheesePriceFlat = cheesePriceFilter.flat();
    const cheesePrice = cheesePriceFlat.reduce((a, b) => +a + +b, 0);
    const cheesePriceSet = toppingPrice.map((i) => {
      return Object.assign({}, i, {
        cheesePrice: cheesePrice,
      });
    });

    setData(cheesePriceSet);
  };

  const toppingSizeHandler = (group, l, i, ing) => {
    if (l !== 'None') {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });

      const small = filter.flat().map((f) => ({ ...f, addedIngredients: f.addedIngredients.map((k) => ({ ...k, size: k.name === ing.name ? l : k.size })) }));
      var editedUser = small[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== small[0].name ? u : editedUser)) }));

      const valid = product.map((i) => {
        return i.groupsIngredients
          .filter((filter) => {
            return filter.name === group.name;
          })
          .map((t) => {
            return t.addedIngredients?.find((f) => f.name === ing.name);
          });
      });
      const mainSize = valid.flat()[0].mainSize ? valid.flat()[0].mainSize : product[0].size;
      const toppping = valid.flat()[0].topping;

      const selected = filter.flat().map((f) => f.addedIngredients.filter((filter) => filter.name === ing.name)).flat();

      var price;
      users.map((i) => {
        switch (selected[0].size) {
          case "Small Size":
            if (selected[0].mainSize === "Small Size") {
              if (toppping === "whole") {
                price = +ing.smallPrice * 2;
              }
              else {
                price = +ing.smallPrice / 2;
              }
            }
            if (selected[0].mainSize === "Medium Size") {
              if (toppping === "whole") {
                price = +ing.mediumPrice * 2;
              }
              else {
                price = +ing.mediumPrice / 2;
              }
            }
            if (selected[0].mainSize === "Large Size") {
              if (toppping === "whole") {
                price = +ing.largePrice * 2;
              }
              else {
                price = +ing.largePrice / 2;
              }
            }
            if (selected[0].mainSize === "Extra Large Size") {
              if (toppping === "whole") {
                price = +ing.extraLargePrice * 2;
              }
              else {
                price = +ing.extraLargePrice / 2;
              }
            }
            break;
          case "Extra Large Size":
            if (selected[0].mainSize === "Small Size") {
              if (toppping === "whole") {
                price = +ing.smallPrice;
              }
              else {
                price = +ing.smallPrice / 2;
              }
            }
            if (selected[0].mainSize === "Medium Size") {
              if (toppping === "whole") {
                price = +ing.mediumPrice;
              }
              else {
                price = +ing.mediumPrice / 2;
              }
            }
            if (selected[0].mainSize === "Large Size") {
              if (toppping === "whole") {
                price = +ing.largePrice;
              }
              else {
                price = +ing.largePrice / 2;
              }
            }
            if (selected[0].mainSize === "Extra Large Size") {
              if (toppping === "whole") {
                price = +ing.extraLargePrice;
              }
              else {
                price = +ing.extraLargePrice / 2;
              }
            }
        }
      });

      const quantitySet = users.map((i) => {
        if (l === "Extra Large Size") {
          if (mainSize === "Small Size") {
            if (toppping === "whole") {
              return Object.assign({}, i, {
                toppingPrice: +i.toppingPrice + price - ing.smallPrice,
              });
            }
            else {
              return Object.assign({}, i, {
                toppingPrice: (+i.toppingPrice + price)
              });
            }

          } else if (mainSize === "Medium Size") {
            if (toppping === "whole") {
              return Object.assign({}, i, {
                toppingPrice: +i.toppingPrice + price - ing.mediumPrice,
              });
            }
            else {
              return Object.assign({}, i, {
                toppingPrice: (+i.toppingPrice + price)
              });
            }
          } else if (mainSize === "Large Size") {
            if (toppping === "whole") {
              return Object.assign({}, i, {
                toppingPrice: +i.toppingPrice + price - ing.largePrice,
              });
            }
            else {
              return Object.assign({}, i, {
                toppingPrice: (+i.toppingPrice + price)
              });
            }
          } else if (mainSize === "Extra Large Size") {
            if (toppping === "whole") {
              return Object.assign({}, i, {
                toppingPrice: +i.toppingPrice + price - ing.extraLargePrice,
              });
            }
            else {
              return Object.assign({}, i, {
                toppingPrice: (+i.toppingPrice + price)
              });
            }
          }
        }
        else {
          if (mainSize === "Small Size") {
            if (toppping === "whole") {
              return Object.assign({}, i, {
                toppingPrice: +i.toppingPrice + price - ing.smallPrice * 2,
              });
            }
            else {
              return Object.assign({}, i, {
                toppingPrice: (+i.toppingPrice + price) - ing.smallPrice,
              });
            }
          } else if (mainSize === "Medium Size") {
            if (toppping === "whole") {
              return Object.assign({}, i, {
                toppingPrice: +i.toppingPrice + price - ing.mediumPrice * 2,
              });
            }
            else {
              return Object.assign({}, i, {
                toppingPrice: (+i.toppingPrice + price) - ing.mediumPrice,
              });
            }
          } else if (mainSize === "Large Size") {
            if (toppping === "whole") {
              return Object.assign({}, i, {
                toppingPrice: +i.toppingPrice + price - ing.largePrice * 2,
              });
            }
            else {
              return Object.assign({}, i, {
                toppingPrice: (+i.toppingPrice + price) - ing.largePrice,
              });
            }
          } else if (mainSize === "Extra Large Size") {
            if (toppping === "whole") {
              return Object.assign({}, i, {
                toppingPrice: +i.toppingPrice + price - ing.extraLargePrice * 2,
              });
            } else {
              return Object.assign({}, i, {
                toppingPrice: (+i.toppingPrice + price) - ing.extraLargePrice,
              });
            }
          }
        }
      });

      setData(quantitySet);
    } else {
      toppingHandler(ing, group);
    }
  };

  const cheeseSizeHandler = (group, l) => {
    if (l !== 'None') {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const small = filter.flat().map((f) => ({ ...f, addedIngredients: f.addedIngredients.map((k) => ({ ...k, size: l })) }));

      var editedUser = small[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== small[0].name ? u : editedUser)) }));
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (price.mainSize === 'Small Size') {
            if (l === "Small Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (l === "Medium Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (l === "Large Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (l === "Extra Large Size") {
              return !price.isSelected ? price.smallPrice * 2 : price.smallPrice;
            }
          } else if (price.mainSize === 'Medium Size') {
            if (l === "Small Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (l === "Medium Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (l === "Large Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (l === "Extra Large Size") {
              return !price.isSelected ? price.mediumPrice * 2 : price.mediumPrice;
            }
          }
          else if (price.mainSize === 'Large Size') {
            if (l === "Small Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (l === "Medium Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (l === "Large Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (l === "Extra Large Size") {
              return !price.isSelected ? price.largePrice * 2 : price.largePrice;
            }
          }
          else if (price.mainSize === 'Extra Large Size') {
            if (l === "Small Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (l === "Medium Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (l === "Large Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (l === "Extra Large Size") {
              return !price.isSelected ? price.extraLargePrice * 2 : price.extraLargePrice;
            }
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.reduce((a, b) => +a + +b, 0)
      const quantitySet = users.map((i) => {
        return Object.assign({}, i, {
          cheesePrice: price,
        });
      });
      setData(quantitySet);
    } else {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (price.mainSize === "Small Size") {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (price.size === "Large Size") {
              return !price.isSelected ? price.smallPrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.smallPrice * 2 : price.smallPrice;
            }
          } else if (price.mainSize === 'Medium Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (price.size === "Large Size") {
              return !price.isSelected ? price.mediumPrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.mediumPrice * 2 : price.mediumPrice;
            }
          }
          else if (price.mainSize === 'Large Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (price.size === "Large Size") {
              return !price.isSelected ? price.largePrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.largePrice * 2 : price.largePrice;
            }
          }
          else if (price.mainSize === 'Extra Large Size') {
            if (price.size === "Small Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (price.size === "Medium Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (price.size === "Large Size") {
              return !price.isSelected ? price.extraLargePrice : 0;
            } else if (price.size === "Extra Large Size") {
              return !price.isSelected ? price.extraLargePrice * 2 : price.extraLargePrice;
            }
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.reduce((a, b) => +a + +b, 0);
      const quantitySet = product.map((i) => {
        return Object.assign({}, i, {
          cheesePrice: Number(i.cheesePrice) - Number(price),
        });
      });

      const None = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((g) => ({ ...g, addedIngredients: g.name === group.name ? [] : g.addedIngredients })) }));
      setData(None);
    }
  };

  const sauceSizeHandler = (group, l) => {
    if (l !== 'None') {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const small = filter.flat().map((f) => ({ ...f, addedIngredients: f.addedIngredients.map((k) => ({ ...k, size: l })) }));

      var editedUser = small[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== small[0].name ? u : editedUser)) }));
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (l === 'Small Size') {
            return price.smallPrice;
          } else if (l === 'Medium Size') {
            return price.mediumPrice;
          } else if (l === 'Large Size') {
            return price.largePrice;
          } else if (l === 'Extra Large Size') {
            return price.extraLargePrice;
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.flat()[0];
      const quantitySet = users.map((i) => {
        return Object.assign({}, i, {
          saucePrice: price,
        });
      });
      setData(quantitySet);
    } else {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (price.size === 'Small Size') {
            return price.smallPrice;
          } else if (price.size === 'Medium Size') {
            return price.mediumPrice;
          } else if (price.size === 'Extra Large Size') {
            return price.extraLargePrice;
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.flat()[0];
      const quantitySet = product.map((i) => {
        return Object.assign({}, i, {
          saucePrice: Number(i.saucePrice) - Number(price),
        });
      });

      const None = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((g) => ({ ...g, addedIngredients: g.name === group.name ? [] : g.addedIngredients })) }));
      setData(None);
    }
  };

  const seeMoreHandler = (group) => {
    if (seeMore[1] === group.name) {
      setSeeMore([100, '']);
    } else {
      setSeeMore([3, group.name]);
    }
  };

  const topSize = (group, l, ing) => {
    const product = [...data];
    const filter = product.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });
    const small = filter.flat().map((f) => f.addedIngredients.filter((k) => k.name === ing.name));
    const small2 = small.flat().map((i) => ({ ...i, topping: l }));
    var editedUser = small2[0];
    const users1 = data.map((d) => ({
      ...d,
      groupsIngredients: d.groupsIngredients.map((g) => ({ ...g, addedIngredients: g.addedIngredients.map((add) => (add.name !== editedUser.name ? add : editedUser)) })),
    }));
    const crustPrice = small.flat().map((k) => {
      if (k.mainSize === 'Small Size') {
        if (k.size === "Small Size") {
          if (l === 'whole') {
            return +product[0].toppingPrice + +k.smallPrice / 2;
          } else {
            if (k.topping === 'left' || k.topping === 'right') {
              return product[0].toppingPrice;
            } else if (l !== 'whole') {
              return +product[0].toppingPrice - +k.smallPrice / 2;
            }
          }
        } else {
          if (l === 'whole') {
            return +product[0].toppingPrice + +k.smallPrice / 2 * 2;
          } else {
            if (k.topping === 'left' || k.topping === 'right') {
              return product[0].toppingPrice;
            } else if (l !== 'whole') {
              return +product[0].toppingPrice - +k.smallPrice / 2 * 2;
            }
          }
        }
      } else if (k.mainSize === 'Medium Size') {
        if (k.size === "Small Size") {
          if (l === 'whole') {
            return +product[0].toppingPrice + +k.mediumPrice / 2;
          } else {
            if (k.topping === 'left' || k.topping === 'right') {
              return product[0].toppingPrice;
            } else if (l !== 'whole') {
              return +product[0].toppingPrice - +k.mediumPrice / 2;
            }
          }
        } else {
          if (l === 'whole') {
            return +product[0].toppingPrice + +k.mediumPrice / 2 * 2;
          } else {
            if (k.topping === 'left' || k.topping === 'right') {
              return product[0].toppingPrice;
            } else if (l !== 'whole') {
              return +product[0].toppingPrice - +k.mediumPrice / 2 * 2;
            }
          }
        }
      } else if (k.mainSize === 'Large Size') {
        if (k.size === "Small Size") {
          if (l === 'whole') {
            return +product[0].toppingPrice + +k.largePrice / 2;
          } else {
            if (k.topping === 'left' || k.topping === 'right') {
              return product[0].toppingPrice;
            } else if (l !== 'whole') {
              return +product[0].toppingPrice - +k.largePrice / 2;
            }
          }
        } else {
          if (l === 'whole') {
            return +product[0].toppingPrice + +k.largePrice / 2 * 2;
          } else {
            if (k.topping === 'left' || k.topping === 'right') {
              return product[0].toppingPrice;
            } else if (l !== 'whole') {
              return +product[0].toppingPrice - +k.largePrice / 2 * 2;
            }
          }
        }
      }
      else {
        if (k.size === "Small Size") {
          if (l === 'whole') {
            return +product[0].toppingPrice + +k.extraLargePrice / 2;
          } else {
            if (k.topping === 'left' || k.topping === 'right') {
              return product[0].toppingPrice;
            } else if (l !== 'whole') {
              return +product[0].toppingPrice - +k.extraLargePrice / 2;
            }
          }
        } else {
          if (l === 'whole') {
            return +product[0].toppingPrice + +k.extraLargePrice / 2 * 2;
          } else {
            if (k.topping === 'left' || k.topping === 'right') {
              return product[0].toppingPrice;
            } else if (l !== 'whole') {
              return +product[0].toppingPrice - +k.extraLargePrice / 2 * 2;
            }
          }
        }
      }
    });
    const quantitySet = users1.map((i) => {
      return Object.assign({}, i, {
        toppingPrice: crustPrice[0],
      });
    });
    setData(quantitySet);
  };

  useEffect(() => {
    const width = window.innerWidth;
    if (width) {
      window.addEventListener('scroll', headerHandler);
    }
    return () => {
      window.removeEventListener('scroll', headerHandler);
    };
  }, []);

  const headerHandler = () => {
    const divElement = scroll.current.offsetTop;
    if (window.pageYOffset > divElement) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  const texthandler = (e) => {
    const product = [...data];
    const quantitySet = product.map((i) => {
      return Object.assign({}, i, {
        remarks: e
      });
    });

    setData(quantitySet);
  }

  const checkSize = () => {
    if (data[0].size) {
      setDone(true);
    } else {
      toast.error("Please Select Size");
    }
  }

  var Price = undefined;
  if (data.length) {
    Price = +data[0].price + +data[0].crustPrice + +data[0].saucePrice + +data[0].toppingPrice + +data[0].cheesePrice;
  }
  return (
    <div>
      <Header props={props} />
      {Object.keys(data).length ? (
        <Container className="mt-3">
          <Row>
            <h1 className="text-center">Pizza Builder</h1>
          </Row>
          <Row>
            <Col sm="12" lg="6" cl="6" className="mobileHide">
              <div style={{ position: 'relative' }}>
                <img src={data.map((i) => i.groupsIngredients.filter((filter) => filter.name === 'Choice of Crust')).map((img) => img[0].addedIngredients[0]?.image)} style={{ position: 'absolute' }} />
                <img src={data.map((i) => i.groupsIngredients.filter((filter) => filter.name === 'Choice of Sauce')).map((img) => img[0].addedIngredients[0]?.image)} style={{ position: 'absolute' }} />
                <img src={data.map((i) => i.groupsIngredients.filter((filter) => filter.name === 'Cheese')).map((img) => img[0].addedIngredients[0]?.image)} style={{ position: 'absolute' }} />
                {data.map((i) => {
                  return i.groupsIngredients
                    .filter((filter) => {
                      return filter.name === 'Meats' || filter.name === 'Veggies';
                    })
                    .map((im) => {
                      return im.addedIngredients.map((img, imgKey) => {
                        return (
                          <React.Fragment key={imgKey}>
                            <img
                              key={imgKey}
                              src={img.image}
                              className={`${img.topping === 'left' ? 'left' : img.topping === 'right' ? 'right' : ''}`}
                              style={{ position: 'absolute', zIndex: `${imgKey === 0 ? imgKey + 1 : imgKey - 1}` }}
                            />
                            {img.size === 'Large' ? (
                              <img
                                key={imgKey + Math.random()}
                                src={img.image}
                                className={`${img.topping === 'left' ? 'left' : img.topping === 'right' ? 'right' : ''} extraImg`}
                                style={{ position: 'absolute', zIndex: `${imgKey === 0 ? imgKey + 1 : imgKey - 1}` }}
                              />
                            ) : null}
                          </React.Fragment>
                        );
                      });
                    });
                })}
              </div>
            </Col>
            {!done ? <Col sm="12" lg="6" cl="6">
              <Row className="mt-3">
                <div className={`productInnerHeader ${fixed ? 'mobileQuantity' : ''}`} ref={scroll}>
                  <h1 style={{ fontSize: '20px' }}>${Number(Price).toFixed(2)}</h1>
                  {fixed && window.innerWidth > 1000 && <Button size="lg" color="danger"
                    onClick={() => checkSize()}
                  >
                    Done
                  </Button>}
                  <Button size="lg" color="danger"
                    onClick={() => checkSize()}
                  >
                    Done
                  </Button>
                </div>
              </Row>
              <Row className="mt-3 tabsBorder">
                <Nav tabs className="nav-justified p-0">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      CRUST, SAUCE AND CHEESE
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      TOPPINGS
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {data.map((i) => {
                      return i.groupsIngredients
                        ?.filter((filter) => {
                          return filter.name !== 'Meats' && filter.name !== 'Veggies';
                        })
                        .map((group, groupKey) => {
                          return (
                            <React.Fragment key={groupKey}>
                              <Row>
                                <Col sm="12" md="6" lg="12" className="p-0">
                                  <h3 className="crust">
                                    <span>
                                      {group.name} <span style={{ fontSize: '14px' }}>{group.name !== 'Ingredients' ? group.addedIngredients.map((find) => find.notes) : null}</span>
                                    </span>
                                  </h3>
                                </Col>
                              </Row>
                              <Row>
                                {group.ingredientsItems?.filter((f) => i.size === "Extra Large Size" ? f.name !== "Gluten Free Crust" : f).map((ing, ingKey) => {
                                  return (
                                    <Col key={ingKey} sm="3" className="respIng">
                                      <React.Fragment>
                                        <div className={`${group.addedIngredients?.find((item) => item.name === ing.name) ? 'activeCrust' : ''} crustPreview`} onClick={() => ingredientHandler(ing, group)}>
                                          {group.addedIngredients?.find((item) => item.name === ing.name) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000' }} /> : null}
                                          <img src={ing.image} />
                                        </div>
                                        <div className="crustDesc">
                                          <span>{`${ing.name}  ${group.name === 'Choice of Crust'
                                            ? i.size === 'Small Size' && ing.smallPrice !== '0.00'
                                              ? `(Extra $${ing.smallPrice})`
                                              : i.size === 'Medium Size' && ing.mediumPrice !== '0.00'
                                                ? `(Extra ($${ing.mediumPrice})`
                                                : i.size === 'Large Size' && ing.largePrice !== '0.00'
                                                  ? `(Extra $${ing.largePrice})`
                                                  : i.size === 'Extra Large Size' && ing.extraLargePrice !== '0.00'
                                                    ? `(Extra $${ing.extraLargePrice})`
                                                    : ''
                                            : ''
                                            }`}
                                          </span>
                                        </div>
                                      </React.Fragment>
                                    </Col>
                                  );
                                })}
                              </Row>
                              {group.name === 'Choice of Crust' ? (
                                <Row className="mt-2">
                                  <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Size</span>
                                  <Col sm="4">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Small Size', i)}>
                                      {i.size === 'Small Size' ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Small (6 slices)</p>
                                    </h4>
                                  </Col>
                                  <Col sm="4">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Medium Size', i)}>
                                      {i.size === 'Medium Size' ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Medium (8 slices)</p>
                                    </h4>
                                  </Col>
                                  <Col sm="4">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Large Size', i)}>
                                      {i.size === 'Large Size' ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Large (10 slices)</p>
                                    </h4>
                                  </Col>
                                  {!group.addedIngredients?.find((item) => item.name === 'Gluten Free Crust') ? (
                                    <Col sm="4">
                                      <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Extra Large Size', i)}>
                                        {i.size === 'Extra Large Size' ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                        <p className="m-0">Extra Large (12 slices)</p>
                                      </h4>
                                    </Col>
                                  ) : null}
                                </Row>
                              ) : group.name === 'Choice of Sauce' ? (
                                <Row className="mt-2">
                                  <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Amount</span>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Extra Large Size', i)}>
                                      {group.addedIngredients.find((find) => find.size === 'Extra Large Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Extra</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Medium Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Medium Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Regular</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Small Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Small Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Light</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'None', i)}>
                                      {!group.addedIngredients?.find((find) => find.size) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">None</p>
                                    </h4>
                                  </Col>
                                </Row>
                              ) : group.name === 'Cheese' ? (
                                <Row className="mt-2">
                                  <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Amount</span>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Extra Large Size', i)}>
                                      {group.addedIngredients.find((find) => find.size === 'Extra Large Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Extra</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Medium Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Medium Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Regular</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Small Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Small Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Light</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'None', i)}>
                                      {!group.addedIngredients?.find((find) => find.size) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">None</p>
                                    </h4>
                                  </Col>
                                </Row>
                              ) : null}
                            </React.Fragment>
                          );
                        });
                    })}
                    <Button className="mt-4 btn btn-danger float-right mb-4" style={{ color: "#fff" }}>
                      <NavLink
                        className={`${classnames({ active: activeTab === '2' })} white`}
                        onClick={() => {
                          toggle('2');
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                          });
                        }}
                      >
                        Continue to Toppings
                      </NavLink>
                    </Button>
                  </TabPane>
                  <TabPane tabId="2">
                    {data.map((i) => {
                      return i.groupsIngredients
                        ?.filter((filter) => {
                          return filter.name === 'Meats';
                        })
                        .map((group, groupKey) => {
                          return (
                            <React.Fragment key={groupKey + Math.random()}>
                              <Row>
                                <Col sm="12" md="6" lg="12" className="p-0">
                                  <h3 className="crust">
                                    <span>{group.name}</span>
                                  </h3>
                                </Col>
                              </Row>
                              <Row>
                                {group.ingredientsItems?.slice(0, seeMore[1] === group.name ? 100 : 3).map((ing, ingKey) => {
                                  return (
                                    <div key={ingKey + Math.random()} className={`peproni p-2 mb-2 mt-2 ${group.addedIngredients?.find((find) => find.name === ing.name) ? 'activeItem' : ''}`}>
                                      <div onClick={() => ingredientHandler(ing, group)}>
                                        {group.addedIngredients?.find((find) => find.name === ing.name) ? <CheckCircle className="circleTop" /> : null}
                                        <img src={ing.thumbnail} className="toppings" />
                                        <label style={{ marginLeft: '20px' }}>{ing.name}</label>
                                      </div>
                                      <Row className={`mt-2 ${group.addedIngredients.find((find) => find.name === ing.name) ? 'opened' : 'closed'}`}>
                                        <Col sm="12" md="6" lg="6" className="d-flex justify-content-around offset-md-2">
                                          <span
                                            className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Extra Large Size' && find.name === ing.name) ? 'selected' : ''}`}
                                            onClick={() =>
                                              !group.addedIngredients?.find((find) => find.size === 'Extra Large Size' && find.name === ing.name) ? ingredientSizeHandler(group, 'Extra Large Size', i, ing) : null
                                            }
                                          >
                                            Extra
                                          </span>
                                          <span
                                            className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Small Size' && find.name === ing.name) ? 'selected' : ''}`}
                                            onClick={() => (!group.addedIngredients?.find((find) => find.size === 'Small Size' && find.name === ing.name) ? ingredientSizeHandler(group, 'Small Size', i, ing) : null)}
                                          >
                                            Regular
                                          </span>
                                          <span className={`toppingsSize`} onClick={() => ingredientSizeHandler(group, 'None', i, ing)}>
                                            None
                                          </span>
                                        </Col>
                                        <Col sm="12" md="4" lg="4" className={`pt-2 mobileTopping`}>
                                          {!group.addedIngredients?.find((item) => item.topping === 'left' && item.name === ing.name) ? (
                                            <img src={LeftGray} style={{ marginRight: '10px' }} onClick={() => topSize(group, 'left', ing)} />
                                          ) : (
                                            <img src={LeftRed} style={{ marginRight: '10px' }} />
                                          )}
                                          {!group.addedIngredients?.find((item) => item.topping === 'right' && item.name === ing.name) ? (
                                            <img src={Rightgray} style={{ marginRight: '10px' }} onClick={() => topSize(group, 'right', ing)} />
                                          ) : (
                                            <img src={RightRed} style={{ marginRight: '10px' }} />
                                          )}
                                          {group.addedIngredients?.find((item) => item.topping === 'whole' && item.name === ing.name) ? (
                                            <img src={FullRed} style={{ marginRight: '10px' }} />
                                          ) : (
                                            <img src={FullGray} style={{ marginRight: '10px' }} onClick={() => topSize(group, 'whole', ing)} />
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })}
                                <span style={{ textAlign: 'center', color: '#265799', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => seeMoreHandler(group)}>
                                  {`${seeMore[1] === group.name ? 'See Less' : 'See More'}`}
                                  {seeMore[1] === group.name ? <ChevronUp style={{ color: '#ffc000' }} /> : <ChevronDown style={{ color: '#ffc000' }} />}
                                </span>
                              </Row>
                            </React.Fragment>
                          );
                        });
                    })}
                    {data.map((i) => {
                      return i.groupsIngredients
                        ?.filter((filter) => {
                          return filter.name === 'Veggies';
                        })
                        .map((group, groupKey) => {
                          return (
                            <React.Fragment key={groupKey}>
                              <Row>
                                <Col sm="12" md="6" lg="12" className="p-0">
                                  <h3 className="crust">
                                    <span>{group.name}</span>
                                  </h3>
                                </Col>
                              </Row>
                              <Row>
                                {group.ingredientsItems?.slice(0, seeMore[1] === group.name ? 100 : 3).map((ing, ingKey) => {
                                  return (
                                    <div key={ingKey} className={`peproni p-2 mb-2 mt-2 ${group.addedIngredients?.find((find) => find.name === ing.name) ? 'activeItem' : ''}`}>
                                      {group.addedIngredients?.find((find) => find.name === ing.name) ? <CheckCircle className="circleTop" /> : null}
                                      <div onClick={() => ingredientHandler(ing, group)}>
                                        <img src={ing.thumbnail} className="toppings" />
                                        <label style={{ marginLeft: '20px' }}>{ing.name}</label>
                                      </div>
                                      <Row className={`mt-2 ${group.addedIngredients.find((find) => find.name === ing.name) ? 'opened' : 'closed'}`}>
                                        <Col sm="6" className="d-flex justify-content-around offset-md-2">
                                          <span
                                            className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Extra Large Size' && find.name === ing.name) ? 'selected' : ''}`}
                                            onClick={() =>
                                              !group.addedIngredients?.find((find) => find.size === 'Extra Large Size' && find.name === ing.name) ? ingredientSizeHandler(group, 'Extra Large Size', i, ing) : null
                                            }
                                          >
                                            Extra
                                          </span>
                                          <span
                                            className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Small Size' && find.name === ing.name) ? 'selected' : ''}`}
                                            onClick={() => (!group.addedIngredients?.find((find) => find.size === 'Small Size' && find.name === ing.name) ? ingredientSizeHandler(group, 'Small Size', i, ing) : null)}
                                          >
                                            Regular
                                          </span>
                                          <span className={`toppingsSize`} onClick={() => ingredientSizeHandler(group, 'None', i, ing)}>
                                            None
                                          </span>
                                        </Col>
                                        <Col sm="4" className={`pt-2 mobileTopping`}>
                                          {!group.addedIngredients?.find((item) => item.topping === 'left' && item.name === ing.name) ? (
                                            <img src={LeftGray} style={{ marginRight: '10px' }} onClick={() => topSize(group, 'left', ing)} />
                                          ) : (
                                            <img src={LeftRed} style={{ marginRight: '10px' }} />
                                          )}
                                          {!group.addedIngredients?.find((item) => item.topping === 'right' && item.name === ing.name) ? (
                                            <img src={Rightgray} style={{ marginRight: '10px' }} onClick={() => topSize(group, 'right', ing)} />
                                          ) : (
                                            <img src={RightRed} style={{ marginRight: '10px' }} />
                                          )}
                                          {group.addedIngredients?.find((item) => item.topping === 'whole' && item.name === ing.name) ? (
                                            <img src={FullRed} style={{ marginRight: '10px' }} />
                                          ) : (
                                            <img src={FullGray} style={{ marginRight: '10px' }} onClick={() => topSize(group, 'whole', ing)} />
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })}
                                <span style={{ textAlign: 'center', color: '#265799', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => seeMoreHandler(group)}>
                                  {`${seeMore[1] === group.name ? 'See Less' : 'See More'}`}
                                  {seeMore[1] === group.name ? <ChevronUp style={{ color: '#ffc000' }} /> : <ChevronDown style={{ color: '#ffc000' }} />}
                                </span>
                              </Row>
                            </React.Fragment>
                          );
                        });
                    })}
                  </TabPane>
                </TabContent>
              </Row>
            </Col> :
              <Col>
                <Card>
                  <div className={`productInnerHeader ${fixed ? 'mobileQuantity' : ''} p-2`} ref={scroll}>
                    <h1 style={{ fontSize: '20px' }}>${Number(Price).toFixed(2)}</h1>
                    <Button size="lg" color="danger"
                      onClick={() => addtocartHandler(data)}
                    >
                      Done
                    </Button>
                  </div>
                  {data.map((i, k) => {
                    const pizzaSize = i.size === "Small Size" ? "Small" : i.size === "Medium Size" ? "Medium" : i.size === "Large Size" ? "Large" : i.size === "Extra Large Size" ? "Extra Large" : null
                    return <CardBody key={k}>
                      <div className="d-flex justify-content-between align-items-center">
                        <h3>Create your own</h3>
                        <label style={{ color: "#ffc000", cursor: 'pointer' }} onClick={() => setDone(false)}>Modify <ChevronRight /></label>
                      </div>
                      <div className="cart-item">
                        {i.groupsIngredients?.filter((filter) => filter.name === "Choice of Crust" || filter.name === "Choice of Sauce" || filter.name === "Cheese")
                          .map((group) => {
                            return group.addedIngredients?.map((ing, ingKey) => {
                              const ingSize = ing.size === "Small Size" ? "Small" : ing.size === "Medium Size" ? "Medium" : ing.size === "Large Size" ? "Large" : ing.size === "Extra Large Size" ? "Extra" : null
                              return <div key={ingKey}><b style={{ fontWeight: "600" }}>{ing.size === undefined ? pizzaSize : ingSize}</b> {ing.name}</div>
                            })
                          })}
                      </div>
                      <div className="mt-4 mb-4">
                        <h4>Meat & Veggies</h4>
                        <div className="cart-item">
                          {i.groupsIngredients?.filter((filter) => filter.name === "Meats" || filter.name === "Veggies")
                            .map((group) => {
                              return group.addedIngredients?.filter((filter) => filter.topping === "whole").map((ing, ingKey) => {
                                const topping = ing.topping;
                                return <div key={ingKey} className="d-flex justify-content-between align-items-center whole">
                                  <span>
                                    {ingKey === 0 && <b className="d-block">{topping}</b>}
                                    {ing.name}
                                  </span>
                                  <X role="button" onClick={() => ingredientHandler(ing, group)} />
                                </div>
                              })
                            })}
                        </div>
                        <div className="cart-item">
                          {i.groupsIngredients?.filter((filter) => filter.name === "Meats" || filter.name === "Veggies")
                            .map((group) => {
                              return group.addedIngredients?.filter((filter) => filter.topping === "left").map((ing, ingKey) => {
                                const topping = ing.topping;
                                return <div key={ingKey} className="d-flex justify-content-between align-items-center left">
                                  <span>
                                    {ingKey === 0 && <b className="d-block">{topping}</b>}
                                    {ing.name}
                                  </span>
                                  <X role="button" onClick={() => ingredientHandler(ing, group)} />
                                </div>
                              })
                            })}
                        </div>
                        <div className="cart-item">
                          {i.groupsIngredients?.filter((filter) => filter.name === "Meats" || filter.name === "Veggies")
                            .map((group) => {
                              return group.addedIngredients?.filter((filter) => filter.topping === "right").map((ing, ingKey) => {
                                const topping = ing.topping;
                                return <div key={ingKey} className="d-flex justify-content-between align-items-center right">
                                  <span>
                                    {ingKey === 0 && <b className="d-block">{topping}</b>}
                                    {ing.name}
                                  </span>
                                  <X role="button" onClick={() => ingredientHandler(ing, group)} />
                                </div>
                              })
                            })}
                        </div>
                      </div>
                    </CardBody>
                  })}
                  <CardFooter>
                    <label className="d-block">Any Special Requests</label>
                    <textarea className="w-100 mt-2" maxLength="150" onChange={(e) => texthandler(e.target.value)}></textarea>
                  </CardFooter>
                </Card>
              </Col>}
          </Row>
        </Container>
      ) : null}

      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader className="pb-0" toggle={() => setModal(false)}>
          {modalName}
        </ModalHeader>
        <ModalBody className="d-flex pt-0">
          <Col sm="3" className="p-2">
            <img src={modalImg} width="100%" />
          </Col>
          <Col sm="9" className="p-2">
            <p style={{ fontSize: '14px' }}>The legendary Original Pan® Pizza has a thick crust that’s baked to golden perfection, crispy on the outside, soft and buttery on the inside.</p>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    Crust: state.Pizza.crust,
    Sauce: state.Pizza.sauce,
    Cheese: state.Pizza.cheese,
    product: state.Pizza.product,
    cart: state.Pizza.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    crustHandler: (value) => dispatch({ type: 'CRUST', value: value }),
    sauceHandler: (value) => dispatch({ type: 'SAUCE', value: value }),
    cheeseHandler: (value) => dispatch({ type: 'CHEESE', value: value }),
    cartHandler: (value) => dispatch({ type: 'CART', value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PizzaMaker);