import React, { useState } from 'react';
import { Button, FormGroup } from 'reactstrap';

const VideoInput = (props) => {
    const [fileName, setFileName] = useState('');

    const handleVideoChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file) {
            reader.onload = function (upload) {
                props.image && props.image(true);
            };
            reader.onloadend = () => setFileName(file.name);
            reader.readAsDataURL(file);
            props.setFieldValue(props.field.name, file);
        }
    };

    return (
        <FormGroup margin="normal">
            <input disabled={props.disabled} className="form-control" id="video-upload" name={props.field.name} type="file" accept={props.accept} onChange={(e) => handleVideoChange(e)} />
        </FormGroup>
    );
};

export default VideoInput;
