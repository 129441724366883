import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from './Header';
import { Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { CheckCircle, ChevronDown, ChevronUp } from 'react-feather';
import toast from 'react-hot-toast';
const DealInner = (props) => {
  const [key, setKey] = React.useState('');
  const [data, setData] = React.useState([]);
  const [ing, setIng] = React.useState('CRUST, SAUCE AND CHEESE');
  const [seeMore, setSeeMore] = React.useState([3, '']);

  const seeMoreHandler = (group) => {
    if (seeMore[0] === 3) {
      setSeeMore([100, group.name]);
    } else {
      setSeeMore([3, group.name]);
    }
  };

  React.useEffect(() => {
    if (props.deal) {
      setKey(props.deal[0]?.dealItems[0]?.productDetail[0]?.name);
      setData(props.deal);
    }
    return () => {
      setKey('');
    };
  }, [props.deal]);

  const toppingHandler = (l, group, main, i) => {
    const product = [...data];
    const valid = main.productDetail[0]?.groupsIngredients
      .filter((filter) => {
        return filter.name === l.name;
      })
      .map((t) => {
        return t.addedIngredients?.find((f) => f.name === group.name);
      });
    const count = main.productDetail[0]?.groupsIngredients.filter((filter) => {
      return filter.name === l.name;
    });
    if (valid[0] == undefined) {
      if (main.toppingCount !== null && count[0].addedIngredients?.length + 1 <= main.toppingCount) {
        const filter = i.groupsIngredients.filter((filter) => {
          return filter.name === l.name;
        });

        const removedIngredient = filter.map((ing) => ({
          ...ing,
          addedIngredients:
            ing.addedIngredients === undefined ? ing.ingredientsItems.filter((f) => f.name === group.name) : ing.ingredientsItems.filter((f) => f.name === group.name).concat(ing.addedIngredients),
        }));
        const mainMap = product.map((k) => ({ ...k, dealItems: k.dealItems.filter((f) => f.productId === main.productId) }));
        const groupMap = mainMap.map((k) => ({
          ...k,
          dealItems: k.dealItems.map((deal) => ({
            ...deal,
            productDetail: deal.productDetail.map((prod) => ({ ...prod, groupsIngredients: prod.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : removedIngredient[0])) })),
          })),
        }));
        var editedUser = groupMap[0].dealItems[0];
        const users = data.map((i) => ({ ...i, dealItems: i.dealItems.map((deal) => (deal.productId !== editedUser.productId ? deal : editedUser)) }));
        setData(users);
      } else {
        const filter = i.groupsIngredients.filter((filter) => {
          return filter.name === l.name;
        });

        const removedIngredient = filter.map((ing) => ({
          ...ing,
          addedIngredients:
            ing.addedIngredients === undefined ? ing.ingredientsItems.filter((f) => f.name === group.name) : ing.ingredientsItems.filter((f) => f.name === group.name).concat(ing.addedIngredients),
        }));
        const mainMap = product.map((k) => ({ ...k, dealItems: k.dealItems.filter((f) => f.productId === main.productId) }));
        const price = mainMap.map((k) => ({ ...k, price: +k.price + +group.mediumPrice }));
        const groupMap = price.map((k) => ({
          ...k,
          dealItems: k.dealItems.map((deal) => ({
            ...deal,
            productDetail: deal.productDetail.map((prod) => ({ ...prod, groupsIngredients: prod.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : removedIngredient[0])) })),
          })),
        }));

        var editedUser = groupMap[0].dealItems[0];
        const users = data.map((i) => ({ ...i, dealItems: i.dealItems.map((deal) => (deal.productId !== editedUser.productId ? deal : editedUser)) }));
        const added = users.map((k) => ({
          ...k,
          price:
            main.size === 'Large'
              ? +k.price + +group.largePrice
              : main.size === 'Medium'
                ? +k.price + +group.mediumPrice
                : main.size === 'Small'
                  ? +k.price + +group.smallPrice
                  : main.size === 'Extra Large'
                    ? +k.price + +group.largePrice
                    : null,
        }));
        setData(added);
      }
    } else {
      const filter = i.groupsIngredients.filter((filter) => {
        return filter.name === l.name;
      });

      const removedIngredient = filter.map((ing) => ({
        ...ing,
        addedIngredients: ing.addedIngredients.filter((f) => f.name !== group.name),
      }));
      const mainMap = product.map((k) => ({ ...k, dealItems: k.dealItems.filter((f) => f.productId === main.productId) }));
      const price = mainMap.map((k) => ({ ...k, price: k.price - group.mediumPrice }));
      const groupMap = price.map((k) => ({
        ...k,
        dealItems: k.dealItems.map((deal) => ({
          ...deal,
          productDetail: deal.productDetail.map((prod) => ({ ...prod, groupsIngredients: prod.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : removedIngredient[0])) })),
        })),
      }));
      var editedUser = groupMap[0].dealItems[0];
      const users = data.map((i) => ({ ...i, dealItems: i.dealItems.map((deal) => (deal.productId !== editedUser.productId ? deal : editedUser)) }));
      const added = users.map((k) => ({
        ...k,
        price:
          main.size === 'Large'
            ? k.price - group.largePrice
            : main.size === 'Medium'
              ? k.price - group.mediumPrice
              : main.size === 'Small'
                ? k.price - group.smallPrice
                : main.size === 'Extra Large'
                  ? k.price - group.largePrice
                  : null,
      }));
      setData(added);
    }
  };
  const ingredientHandler = (l, group, i, main) => {
    crustHandler(l, group, i, main);
  };
  const crustHandler = (l, group, i, main) => {
    const product = [...data];
    const filter = i.groupsIngredients.filter((filter) => {
      return filter.name === group.name;
    });

    const removedIngredient = filter.map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === l.name) }));

    const mainMap = product.map((k) => ({ ...k, dealItems: k.dealItems.filter((f) => f.productId === main.productId) }));
    const price = mainMap.map((k) => ({ ...k, price: +k.price + +group.smallPrice }));
    const groupMap = price.map((k) => ({
      ...k,
      dealItems: k.dealItems.map((deal) => ({
        ...deal,
        productDetail: deal.productDetail.map((prod) => ({ ...prod, groupsIngredients: prod.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : removedIngredient[0])) })),
      })),
    }));
    var editedUser = groupMap[0].dealItems[0];
    const users = data.map((i) => ({ ...i, dealItems: i.dealItems.map((deal) => (deal.productId !== editedUser.productId ? deal : editedUser)) }));
    setData(users);
  };
  const tabsHandler = (k) => {
    setKey(k);
  };
  const addtocartHandler = () => {
    const cart = props.cart;
    let valid = cart.find((f) => {
      return f.name === data[0].name;
    });
    if (cart.length === 0) {
      props.cartHandler(data);
      toast.success(`Order Added To Cart`);
      props.dealHandler(false);
      props.history.push('/cart');
    } else {
      if (!valid) {
        const data1 = cart.concat(data);
        props.cartHandler(data1);
        toast.success(`Order Added To Cart`);
        props.dealHandler(false);
        props.history.push('/cart');
      } else {
        props.cartHandler(data);
        toast.success(`Order Already Added To Cart`);
        props.dealHandler(false);
        props.history.push('/cart');
      }
    }
  };

  return (
    <>
      <Header props={props} />
      <Container>
        <div className="mb-2 mt-4" style={{ textAlign: 'center', textTransform: 'capitalize' }}>
          <h3>{`${data[0]?.name} ($${Number(data[0]?.price).toFixed(2)})`}</h3>
        </div>
        <Tabs id="controlled-tab-example" onSelect={(k) => tabsHandler(k)} className="mb-3 mt-3 deals">
          {data.map((main) => {
            return main.dealItems.map((dealItem, dealKey) => {
              return dealItem.productDetail.map((i, k) => {
                return (
                  <Tab eventKey={`${i.name}${dealKey}`} title={i.name} key={k}>
                    <Row>
                      <Col sm="12" md="12" lg="6" className="mobileHide">
                        <React.Fragment>
                          {i.category === 'Pizza' ? (
                            <div style={{ position: 'relative' }}>
                              <img src={i.groupsIngredients.filter((filter) => filter.name === 'Choice of Crust').map((img) => img.addedIngredients[0]?.image)} style={{ position: 'absolute', maxWidth: '500px' }} />
                              <img src={i.groupsIngredients.filter((filter) => filter.name === 'Choice of Sauce').map((img) => img.addedIngredients[0]?.image)} style={{ position: 'absolute', maxWidth: '500px' }} />
                              <img src={i.groupsIngredients.filter((filter) => filter.name === 'Cheese').map((img) => img.addedIngredients[0]?.image)} style={{ position: 'absolute', maxWidth: '500px' }} />
                              {i.groupsIngredients
                                .filter((filter) => {
                                  return filter.name === 'Meats' || filter.name === 'Veggies';
                                })
                                .map((im) => {
                                  return im.addedIngredients.reverse().map((img, imgKey) => {
                                    return (
                                      <React.Fragment>
                                        <img
                                          key={imgKey}
                                          src={img.image}
                                          className={`${img.topping === 'left' ? 'left' : img.topping === 'right' ? 'right' : ''}`}
                                          style={{ position: 'absolute', zIndex: `${imgKey + 1}`, maxWidth: '450px', left: '30px', top: '30px' }}
                                        />
                                        {img.size === 'Large' ? (
                                          <img
                                            key={imgKey + Math.random()}
                                            src={img.image}
                                            className={`${img.topping === 'left' ? 'left' : img.topping === 'right' ? 'right' : ''} extraImg`}
                                            style={{ position: 'absolute', zIndex: `${imgKey + 1}`, maxWidth: '450px', left: '30px', top: '30px' }}
                                          />
                                        ) : null}
                                      </React.Fragment>
                                    );
                                  });
                                })}
                            </div>
                          ) : (
                            <img src={i.image} style={{ margin: '0 auto', display: 'block' }} />
                          )}
                        </React.Fragment>
                      </Col>
                      <Col sm="12" md="12" lg="6">
                        {i.category === 'Pizza' ? (
                          <Tabs id="controlled-tab-example" activeKey={ing} onSelect={(h) => setIng(h)} className="dealsIng">
                            <Tab eventKey="CRUST, SAUCE AND CHEESE" title="CRUST, SAUCE AND CHEESE">
                              {i.groupsIngredients
                                ?.filter((filter) => {
                                  return filter.name !== 'Meats' && filter.name !== 'Veggies';
                                })
                                .map((group, groupKey) => {
                                  return (
                                    <React.Fragment key={groupKey}>
                                      <Row>
                                        <Col sm="12" md="6" lg="12" className="p-0">
                                          <h3 className="crust">
                                            <span>
                                              {group.name} <span style={{ fontSize: '14px' }}>{group.name !== 'Ingredients' ? group.addedIngredients.map((find) => find.notes) : null}</span>
                                            </span>
                                          </h3>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {group.ingredientsItems?.map((ing, ingKey) => {
                                          return (
                                            <Col key={ingKey} sm="3" className="respIng">
                                              <div
                                                className={`${group.addedIngredients?.find((item) => item.name === ing.name) ? 'activeCrust' : ''} crustPreview`}
                                                onClick={() => ingredientHandler(ing, group, i, dealItem)}
                                              >
                                                {group.addedIngredients?.find((item) => item.name === ing.name) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000' }} /> : null}
                                                <img src={ing.image} />
                                              </div>
                                              <div className="crustDesc">
                                                <span>{`${ing.name}`}</span>
                                              </div>
                                            </Col>
                                          );
                                        })}
                                      </Row>
                                      {group.name === 'Choice of Crust' ? (
                                        <Row className="mt-2">
                                          <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Size</span>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              {group.addedIngredients.find((find) => find.size === 'Small') || group.addedIngredients.find((find) => find.size === undefined) ? (
                                                <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} />
                                              ) : null}
                                              <p className="m-0">Small</p>
                                            </h4>
                                          </Col>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              {group.addedIngredients.find((find) => find.size === 'Medium') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                              <p className="m-0">Medium</p>
                                            </h4>
                                          </Col>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              {group.addedIngredients.find((find) => find.size === 'Large') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                              <p className="m-0">Large</p>
                                            </h4>
                                          </Col>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize deals">
                                              {group.addedIngredients.find((find) => find.size === 'Extra') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                              <p className="m-0">Extra Large</p>
                                            </h4>
                                          </Col>
                                        </Row>
                                      ) : group.name === 'Choice of Sauce' ? (
                                        <Row className="mt-2">
                                          <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Amount</span>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              {group.addedIngredients.find((find) => find.size === 'Large') || group.addedIngredients.find((find) => find.size === undefined) ? (
                                                <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} />
                                              ) : null}
                                              <p className="m-0">Extra</p>
                                            </h4>
                                          </Col>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              {group.addedIngredients.find((find) => find.size === 'Medium') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                              <p className="m-0">Regular</p>
                                            </h4>
                                          </Col>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              <p className="m-0">None</p>
                                            </h4>
                                          </Col>
                                        </Row>
                                      ) : group.name === 'Cheese' ? (
                                        <Row className="mt-2">
                                          <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Amount</span>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              {group.addedIngredients.find((find) => find.size === 'Large') || group.addedIngredients.find((find) => find.size === undefined) ? (
                                                <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} />
                                              ) : null}
                                              <p className="m-0">Extra</p>
                                            </h4>
                                          </Col>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              {group.addedIngredients.find((find) => find.size === 'Medium') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                              <p className="m-0">Regular</p>
                                            </h4>
                                          </Col>
                                          <Col sm="4">
                                            <h4 className="sizes dealSize">
                                              <p className="m-0">None</p>
                                            </h4>
                                          </Col>
                                        </Row>
                                      ) : null}
                                    </React.Fragment>
                                  );
                                })}
                            </Tab>
                            <Tab eventKey="TOPPINGS" title="TOPPINGS">
                              {i.groupsIngredients
                                ?.filter((filter) => filter.name === 'Meats')
                                .map((group, groupKey) => {
                                  return (
                                    <React.Fragment key={groupKey}>
                                      <Row>
                                        <Col sm="12" md="6" lg="12" className="p-0">
                                          <h3 className="crust">
                                            <span>{group.name}</span>
                                          </h3>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {group.ingredientsItems?.slice(0, seeMore[1] === group.name ? seeMore[0] : 3).map((ing, ingKey) => {
                                          return (
                                            <div key={ingKey} className={`peproni p-2 mb-2 mt-2`}>
                                              <div onClick={() => toppingHandler(group, ing, dealItem, i)}>
                                                <img src={ing.image} className="toppings" />
                                                <label style={{ marginLeft: '20px' }}>{ing.name}</label>
                                              </div>
                                              <Row className={`mt-2 ${group.addedIngredients.find((find) => find.name === ing.name) ? 'opened' : 'closed'}`}>
                                                <Col sm="6" className="d-flex justify-content-around offset-md-2">
                                                  <span className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Medium' && find.name === ing.name) ? 'selected' : ''}`}>Extra</span>
                                                  <span
                                                    className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Small' || (group.addedIngredients?.find((find) => find.size === undefined) && find.name === ing.name))
                                                      ? 'selected'
                                                      : ''
                                                      }`}
                                                  >
                                                    Regular
                                                  </span>
                                                  <span className={`toppingsSize`} onClick={() => toppingHandler(group, ing, dealItem, i)}>
                                                    None
                                                  </span>
                                                </Col>
                                              </Row>
                                            </div>
                                          );
                                        })}
                                        <span style={{ textAlign: 'center', color: '#265799', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => seeMoreHandler(group)}>
                                          {`${seeMore[1] === group.name ? 'See Less' : 'See More'}`}
                                          {seeMore[1] === group.name ? <ChevronUp style={{ color: '#ffc000' }} /> : <ChevronDown style={{ color: '#ffc000' }} />}
                                        </span>
                                      </Row>
                                    </React.Fragment>
                                  );
                                })}
                              {i.groupsIngredients
                                ?.filter((filter) => filter.name === 'Veggies')
                                .map((group, groupKey) => {
                                  return (
                                    <React.Fragment key={groupKey}>
                                      <Row>
                                        <Col sm="12" md="6" lg="12" className="p-0">
                                          <h3 className="crust">
                                            <span>{group.name}</span>
                                          </h3>
                                        </Col>
                                      </Row>
                                      <Row>
                                        {group.ingredientsItems?.slice(0, seeMore[1] === group.name ? seeMore[0] : 3).map((ing, ingKey) => {
                                          return (
                                            <div key={ingKey} className={`peproni p-2 mb-2 mt-2`}>
                                              <div onClick={() => toppingHandler(group, ing, dealItem, i)}>
                                                <img src={ing.image} className="toppings" />
                                                <label style={{ marginLeft: '20px' }}>{ing.name}</label>
                                              </div>
                                              <Row className={`mt-2 ${group.addedIngredients.find((find) => find.name === ing.name) ? 'opened' : 'closed'}`}>
                                                <Col sm="6" className="d-flex justify-content-around offset-md-2">
                                                  <span className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Medium' && find.name === ing.name) ? 'selected' : ''}`}>Extra</span>
                                                  <span
                                                    className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Small' || (group.addedIngredients?.find((find) => find.size === undefined) && find.name === ing.name))
                                                      ? 'selected'
                                                      : ''
                                                      }`}
                                                  >
                                                    Regular
                                                  </span>
                                                  <span className={`toppingsSize`} onClick={() => toppingHandler(group, ing, dealItem, i)}>
                                                    None
                                                  </span>
                                                </Col>
                                              </Row>
                                            </div>
                                          );
                                        })}
                                        <span style={{ textAlign: 'center', color: '#265799', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => seeMoreHandler(group)}>
                                          {`${seeMore[1] === group.name ? 'See Less' : 'See More'}`}
                                          {seeMore[1] === group.name ? <ChevronUp style={{ color: '#ffc000' }} /> : <ChevronDown style={{ color: '#ffc000' }} />}
                                        </span>
                                      </Row>
                                    </React.Fragment>
                                  );
                                })}
                            </Tab>
                          </Tabs>
                        ) : (
                          <>
                            <Row>
                              <Col sm="12" md="6" lg="12" className="p-0">
                                <h3 className="crust">
                                  <span>TOPPINGS {`(Free ${dealItem.toppingCount === null ? '0' : dealItem.toppingCount} Toppings)`}</span>
                                </h3>
                              </Col>
                            </Row>
                            <Row>
                              {i.groupsIngredients
                                ?.filter((f) => f.name === 'Topping')
                                .map((group) => {
                                  return group.ingredientsItems.map((ing, ingKey) => {
                                    return (
                                      <Col key={ingKey} sm="3" className="mt-2">
                                        <div className={`${group.addedIngredients?.find((item) => item.name === ing.name) ? 'activeCrust' : ''} crustPreview`} onClick={() => toppingHandler(group, ing, dealItem, i)}>
                                          {group.addedIngredients?.find((item) => item.name === ing.name) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000' }} /> : null}
                                          <img src={ing.image} />
                                        </div>
                                        <div className="crustDesc">
                                          <span>{`${ing.name}`}</span>
                                        </div>
                                      </Col>
                                    );
                                  });
                                })}
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Tab>
                );
              });
            });
          })}
          <Tab eventKey="Done" title="Done">
            {data.map((i) => {
              return i.dealItems.map((dealItem) => {
                return dealItem.productDetail.map((prod, k) => {
                  const added = prod.groupsIngredients.map((m) => {
                    return m.addedIngredients?.map((m1) => {
                      return m1.name ? m1.name : m1.ingredientName;
                    });
                  });
                  const extra = prod.groupsIngredients.map((m) => {
                    return m.addedExtra?.map((m1) => {
                      return m1.name ? m1.name : m1.ingredientName;
                    });
                  });
                  const array = added.concat(extra);

                  const alterArray = array.flat();
                  return (
                    <Row key={k} className="cartProducts">
                      <Col sm="3">
                        <img src={prod.image} style={{ width: '100%', height: '100px', objectFit: 'cover' }} />
                      </Col>
                      <Col sm="6">
                        <div>
                          <h4>{prod.name}</h4>
                          {alterArray.filter(Boolean).map((ing, ingKey) => {
                            return (
                              <span style={{ fontSize: '14px', color: '#677078' }} key={`demo_snap_${ingKey}`}>
                                {(ingKey ? ', ' : '') + ing}
                              </span>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                });
              });
            })}
            <Row>
              <div style={{ textAlign: 'right' }}>
                <span className="dealCart" onClick={() => addtocartHandler()}>
                  Add to Cart
                </span>
              </div>
            </Row>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    deal: state.Pizza.deals,
    cart: state.Pizza.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cartHandler: (value) => dispatch({ type: 'CART', value: value }),
    dealHandler: (value) => dispatch({ type: 'DEAL', value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DealInner);
