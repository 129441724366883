const Crust = [
 {
  name: 'Original Pan Pizza',
  img: require('../Assests/Crust/crust_ht_NoFinish.png'),
 },
 {
  name: 'Hand Tossed Pizza',
  img: require('../Assests/Crust/crust_pan_NoFinish.png'),
 },
 {
  name: 'Thin ‘N Crispy',
  img: require('../Assests/Crust/crust_sc_NoFinish.png'),
 },
 {
  name: 'Original Stuffed Crust',
  img: require('../Assests/Crust/crust_thin_NoFinish.png'),
 },
];
const Sauce = [
 {
  name: 'Classic Marinara',
  img: require('../Assests/Sauce/sauce_BBQ_ML.png'),
 },
 {
  name: 'Creamy Garlic Parmesan',
  img: require('../Assests/Sauce/sauce_Buffalo_ML.png'),
 },
 {
  name: 'Barbeque',
  img: require('../Assests/Sauce/sauce_CreamyGarlicParm_ML.png'),
 },
 {
  name: 'Buffalo',
  img: require('../Assests/Sauce/sauce_Marinara_ML.png'),
 },
];
const Images = { Crust, Sauce };
export default Images;
