import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Images from './Constants';
import { connect } from 'react-redux';
import { CheckCircle } from 'react-feather';
import Cheese from '../Assests/Cheese/cheese_mozz_ML.png';
import Pepperoni from '../Assests/topping_Pepperoni_ML_reg.png';
import toast from 'react-hot-toast';
import Header from './Header';
const ProductInner = (props) => {
  const [crust, setCrust] = useState([]);
  const [sauce, setSauce] = useState([]);
  const [leftGray] = useState(false);
  const [rightGray] = useState(false);
  const [fullGray] = useState(false);
  const [data, setData] = useState([]);

  React.useEffect(() => {
    if (props.product) {
      setData(props.product);
    }
  }, [props.product]);
  useEffect(() => {
    const Crust = Images.Crust.filter((f) => {
      return f.name === props.Crust;
    });
    setCrust(Crust);
    const Sauce = Images.Sauce.filter((f) => {
      return f.name === props.Sauce;
    });
    setSauce(Sauce);
  }, [props]);

  const selectedHandler = (l, group) => {
    const product = [...data];
    const valid = product.map((i) => {
      return i.groupsIngredients.map((f) => {
        return f.addedIngredients?.find((find) => find.name === l.name);
      });
    });

    const valididFilter = valid[0].filter(Boolean);
    if (valididFilter[0]) {
      const removedIngredient = product.map((i) => ({
        ...i,
        groupsIngredients: i.groupsIngredients.map((ing) => ({
          ...ing,
          addedIngredients: ing.addedIngredients.filter((f) => f.name !== l.name),
        })),
      }));
      const quantitySet = removedIngredient.map((i) => {
        const small = l.smallPrice * i.quantity;
        return Object.assign({}, i, {
          ingPrice: i.ingPrice ? i.ingPrice - l.smallPrice : +l.smallPrice,
          price: i.price - small,
        });
      });
      setData(quantitySet);
    } else {
      const removedIngredient = product.map((i) => ({
        ...i,
        groupsIngredients: i.groupsIngredients.map((ing) => ({
          ...ing,
          addedIngredients: ing.addedIngredients === undefined ? ing.ingredientsItems.filter((f) => f.name === l.name) : ing.ingredientsItems.filter((f) => f.name === l.name).concat(ing.addedIngredients),
        })),
      }));
      const quantitySet = removedIngredient.map((i) => {
        const small = l.smallPrice * i.quantity;
        return Object.assign({}, i, {
          ingPrice: i.ingPrice ? +i.ingPrice + +l.smallPrice : +l.smallPrice,
          price: +i.price + +small,
        });
      });
      setData(quantitySet);
    }
  };
  const preselectedHandler = (l, group) => {
    const product = [...data];
    const valid = product.map((i) => {
      return i.groupsIngredients
        .filter((filter) => {
          return filter.name === group.name;
        })
        .map((t) => {
          return t.addedIngredients?.find((f) => f.name === l.name);
        });
    });
    const filter = product.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });
    if (!valid[0][0]) {
      const removedIngredient = filter.flat().map((i) => ({
        ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === l.name)
          .map((k) => ({ ...k, size: 'Medium Size' })).concat(i.addedIngredients).filter(Boolean),
      }));
      var editedUser = removedIngredient[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    } else {
      const removedIngredient = filter.flat().map((i) => ({
        ...i, addedIngredients: i.addedIngredients.filter((f) => f.name !== l.name)
          .map((k) => ({ ...k, size: 'Medium Size' }))
      }));
      var editedUser = removedIngredient[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    }
  };

  const addtocartHandler = (i) => {
    const wrapCheck = i.map((f) => f.groupsIngredients.filter((f) => f.name === "Wraps"));
    const cutCheck = i[0].isHotCold && i.map((f) => f.groupsIngredients.filter((f) => f.name === "Choose One"));
    const wrap = wrapCheck[0][0] && wrapCheck[0][0]?.addedIngredients.length;
    const cut = i[0].isHotCold && cutCheck[0][0]?.addedIngredients.length;

    if (i[0].category === "Wraps") {
      if (wrap) {
        if (i[0].size) {
          const cart = props.cart;
          let valid = cart.find((f) => {
            return f.productName === i[0].productName;
          });

          if (!valid) {
            const data1 = cart.concat(i[0]);
            props.cartHandler(data1);
            toast.success(`${i[0].name} Added To Cart`);
            props.history.push('/cart');
          } else {
            const users = props.cart.map((u) => (u.productName !== i[0].productName ? u : i[0]));
            props.cartHandler(users);
            toast.success(`${i[0].name} Added To Cart`);
            props.history.push('/cart');
          }
        } else {
          toast.error('Please Select Size');
        }
      } else {
        toast.error('Please Select Wrap');
      }
    } else if (i[0].category === "Subs") {
      if (cutCheck) {
        if (cut) {
          if (i[0].size) {
            const cart = props.cart;
            let valid = cart.find((f) => {
              return f.productName === i[0].productName;
            });

            if (!valid) {
              const data1 = cart.concat(i[0]);
              props.cartHandler(data1);
              toast.success(`${i[0].name} Added To Cart`);
              props.history.push('/cart');
            } else {
              const users = props.cart.map((u) => (u.productName !== i[0].productName ? u : i[0]));
              props.cartHandler(users);
              toast.success(`${i[0].name} Added To Cart`);
              props.history.push('/cart');
            }
          } else {
            toast.error('Please Select Size');
          }
        } else {
          toast.error('Please Select Cut');
        }
      } else {
        if (i[0].size) {
          const cart = props.cart;
          let valid = cart.find((f) => {
            return f.productName === i[0].productName;
          });

          if (!valid) {
            const data1 = cart.concat(i[0]);
            props.cartHandler(data1);
            toast.success(`${i[0].name} Added To Cart`);
            props.history.push('/cart');
          } else {
            const users = props.cart.map((u) => (u.productName !== i[0].productName ? u : i[0]));
            props.cartHandler(users);
            toast.success(`${i[0].name} Added To Cart`);
            props.history.push('/cart');
          }
        } else {
          toast.error('Please Select Size');
        }
      }
    } else {
      if (i[0].size) {
        const cart = props.cart;
        let valid = cart.find((f) => {
          return f.productName === i[0].productName;
        });
        if (!valid) {
          const data1 = cart.concat(i[0]);
          props.cartHandler(data1);
          toast.success(`${i[0].name} Added To Cart`);
          props.history.push('/cart');
        } else {
          const users = props.cart.map((u) => (u.productName !== i[0].productName ? u : i[0]));
          props.cartHandler(users);
          toast.success(`${i[0].name} Added To Cart`);
          props.history.push('/cart');
        }
      } else {
        toast.error('Please Select Size');
      }
    }
  };

  const sizeHandler = (e) => {
    const product = [...data];
    switch (data[0].category) {
      case 'Wings':
        const quantitySet = product.map((i) => {
          return Object.assign({}, i, {
            size: e,
            price: e === '7 Wings' ? i.smallPrice * i.quantity : e === '14 Wings' ? i.mediumPrice * i.quantity : e === '21 Wings' ? i.largePrice * i.quantity : null,
            basePrice: e === '7 Wings' ? i.smallPrice : e === '14 Wings' ? i.mediumPrice : e === '21 Wings' ? i.largePrice : null,
          });
        });
        setData(quantitySet);
        break;
      case 'Subs':
        const Subs = product.map((i) => {
          return Object.assign({}, i, {
            size: e,
            price: e === '8 inch' ? i.smallPrice * i.quantity : e === '12 inch' ? i.mediumPrice * i.quantity : null,
            basePrice: e === '8 inch' ? i.smallPrice : e === '12 inch' ? i.mediumPrice : null,
          });
        });
        setData(Subs);
        break;
      case 'Salad':
        const Salad = product.map((i) => {
          return Object.assign({}, i, {
            size: e,
            price: e === 'Small Size' ? i.smallPrice * i.quantity : e === 'Large Size' ? i.largePrice * i.quantity : null,
            basePrice: e === 'Small Size' ? i.smallPrice : e === 'Large Size' ? i.largePrice : null,
          });
        });
        setData(Salad);
        break;
      case 'Pizza':
        const Pizza = product.map((i) => {
          return Object.assign({}, i, {
            size: e,
            price:
              e === 'Small Size'
                ? i.smallPrice * i.quantity
                : e === 'Medium Size'
                  ? i.mediumPrice * i.quantity
                  : e === 'Large Size'
                    ? i.largePrice * i.quantity
                    : e === 'Extra Large Size'
                      ? i.largePrice * i.quantity
                      : null,
            basePrice: e === 'Small Size' ? i.smallPrice : e === 'Medium Size' ? i.mediumPrice : e === 'Large Size' ? i.largePrice : e === 'Extra Large Size' ? i.largePrice : null,
          });
        });
        setData(Pizza);
        break;
    }
  };

  const crustHandler = (item, group) => {
    const product = [...data];
    const valid = product.map((i) => {
      return i.groupsIngredients
        .filter((filter) => {
          return filter.name === group.name;
        })
        .map((t) => {
          return t.addedIngredients?.find((f) => f.name === item.name);
        });
    });
    const filter = product.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });
    if (!valid[0][0]) {
      const removedIngredient = filter.flat().map((i) => ({
        ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === item.name)
          .map((k) => ({ ...k, size: 'Small Size' })),
      }));
      var editedUser = removedIngredient[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    } else {
      const removedIngredient = filter.flat().map((i) => ({
        ...i, addedIngredients: i.addedIngredients.filter((f) => f.name !== item.name)
          .map((k) => ({ ...k, size: 'Small Size' }))
      }));
      var editedUser = removedIngredient[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    }
  };

  return (
    <React.Fragment>
      <Header props={props} />
      {Object.keys(data).length ? (
        <div className="mt-3">
          <Container>
            <Row>
              <h1 className="text-center">{data[0].name}</h1>
            </Row>
            <Row>
              <Col sm="12" lg="6">
                {data[0].category === 'Pizza' ? (
                  <div style={{ position: 'relative', height: '100%' }}>
                    {crust.length ? <img src={crust[0].img.default} style={{ position: 'absolute', height: '520px' }} /> : <img src={Images.Crust[0].img.default} style={{ position: 'absolute' }} />}
                    {sauce.length ? <img src={sauce[0].img.default} style={{ position: 'absolute', height: '520px' }} /> : <img src={Images.Sauce[0].img.default} style={{ position: 'absolute' }} />}
                    {props.Cheese !== '' ? <img src={Cheese} style={{ position: 'absolute', height: '520px' }} /> : null}
                    {rightGray || leftGray || fullGray ? <img src={Pepperoni} style={{ position: 'absolute', height: '520px' }} className={`${leftGray ? 'left' : ''} ${rightGray ? 'right' : ''}`} /> : null}
                  </div>
                ) : (
                  <div>
                    <img src={data[0].image} style={{ width: "100%" }} />
                  </div>
                )}
              </Col>
              <Col sm="12" lg="6">
                <Row className="mt-3">
                  <div className="productInnerHeader">
                    <h1 style={{ fontSize: '20px' }}>${Number(data[0].price).toFixed(2)}</h1>
                    <Button size="lg" color="danger" onClick={() => addtocartHandler(data)}>
                      Done
                    </Button>
                  </div>
                </Row>
                <Row className="mt-3 tabsBorder">
                  <Row>
                    {data[0].groupsIngredients
                      .filter((filter) => filter.name === 'Choose One')
                      .map((m, mkey) => {
                        return (
                          <React.Fragment key={mkey}>
                            <Row>
                              <Col sm="12" className="p-0">
                                <h3 className="crust">
                                  <span>{m.name}</span>
                                </h3>
                              </Col>
                            </Row>
                            <Row>
                              {m.ingredientsItems.map((i, k) => {
                                return (
                                  <Col key={k} sm="3" className="mt-2">
                                    <div className={`${m.addedIngredients?.find((item) => item?.name === i.name) ? 'activeCrust' : ''} crustPreview`} onClick={() => crustHandler(i, m)}>
                                      {m.addedIngredients?.find((item) => item?.name === i.name) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000' }} /> : null}
                                      <img src={i.image} />
                                    </div>
                                    <div className="crustDesc">
                                      <span>{i.name}</span>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </React.Fragment>
                        );
                      })}
                  </Row>
                  <Row>
                    {data[0].groupsIngredients
                      .filter((filter) => filter.name === 'Wraps')
                      .map((m, mkey) => {
                        return (
                          <React.Fragment key={mkey}>
                            <Row>
                              <Col sm="12" className="p-0">
                                <h3 className="crust">
                                  <span>{m.name}</span>
                                </h3>
                              </Col>
                            </Row>
                            <Row>
                              {m.ingredientsItems.map((i, k) => {
                                return (
                                  <Col key={k} sm="3" className="mt-2">
                                    <div className={`${m.addedIngredients?.find((item) => item?.name === i.name) ? 'activeCrust' : ''} crustPreview`} onClick={() => crustHandler(i, m)}>
                                      {m.addedIngredients?.find((item) => item?.name === i.name) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000' }} /> : null}
                                      <img src={i.image} />
                                    </div>
                                    <div className="crustDesc">
                                      <span>{`${i.name}`}</span>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </React.Fragment>
                        );
                      })}
                  </Row>
                  <Row>
                    {data[0].groupsIngredients
                      .filter((filter) => filter.name === 'Choice of Ingredients' || filter.name === "Choice of Dressing")
                      .map((m, mkey) => {
                        return (
                          <React.Fragment key={mkey}>
                            <Row>
                              <Col sm="12" className="p-0">
                                <h3 className="crust">
                                  <span>{m.name}</span>
                                </h3>
                              </Col>
                            </Row>
                            <Row>
                              {m.ingredientsItems.map((i, k) => {
                                return (
                                  <Col key={k} sm="3" className="mt-2">
                                    <div className={`${m.addedIngredients?.find((item) => item?.name === i.name) ? 'activeCrust' : ''} crustPreview`} onClick={() => preselectedHandler(i, m)}>
                                      {m.addedIngredients?.find((item) => item?.name === i.name) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000' }} /> : null}
                                      <img src={i.image} />
                                    </div>
                                    <div className="crustDesc">
                                      <span>{`${i.name}`}</span>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </React.Fragment>
                        );
                      })}
                  </Row>
                  <Row>
                    {data[0].groupsIngredients
                      .filter((filter) => filter.name === 'Choice of Topping' || filter.name === 'Choice of Toppings' || filter.type === 'AddExtra' ||
                        filter.name === 'Toppings')
                      .filter((f) => f.name !== "Wraps" && f.name !== "Choice of Ingredients" && f.name !== "Choose One")
                      .map((m, mkey) => {
                        return (
                          <React.Fragment key={mkey}>
                            <Row>
                              <Col sm="12" className="p-0">
                                <h3 className="crust">
                                  <span>{m.name}</span>
                                </h3>
                              </Col>
                            </Row>
                            <Row>
                              {m.ingredientsItems.map((i, k) => {
                                return (
                                  <Col key={k} sm="3" className="mt-2">
                                    <div className={`${m.addedIngredients?.find((item) => item.name === i.name) ? 'activeCrust' : ''} crustPreview`} onClick={() => selectedHandler(i)}>
                                      {m.addedIngredients?.find((item) => item.name === i.name) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000' }} /> : null}
                                      <img src={i.image} />
                                    </div>
                                    <div className="crustDesc">
                                      <span>{`${i.name}`}</span>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </React.Fragment>
                        );
                      })}
                  </Row>
                  {data[0].sizes.length ? (
                    <Row>
                      <Col sm="12" className="p-0">
                        <h3 className="crust">
                          <span>Size</span>
                        </h3>
                      </Col>
                      {data[0].sizes.map((size, sizeKey) => {
                        return (
                          <React.Fragment key={sizeKey}>
                            <Col sm="4">
                              <h4 className="sizes" onClick={() => sizeHandler(size)}>
                                {size === data[0].size ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                <p className="m-0">{size}</p>
                              </h4>
                            </Col>
                          </React.Fragment>
                        );
                      })}
                    </Row>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    Crust: state.Pizza.crust,
    Sauce: state.Pizza.sauce,
    Cheese: state.Pizza.cheese,
    product: state.Pizza.product,
    cart: state.Pizza.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cartHandler: (value) => dispatch({ type: 'CART', value: value }),
    crustHandler: (value) => dispatch({ type: 'CRUST', value: value }),
    sauceHandler: (value) => dispatch({ type: 'SAUCE', value: value }),
    cheeseHandler: (value) => dispatch({ type: 'CHEESE', value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductInner);
