import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './Components/redux/storeConfig/store';
import { Toaster } from 'react-hot-toast';

import Footer from './Components/Footer';
import Home from './Components/Home';
import PizzaMaker from './Components/PizzaMaker';
import Pizza from './Components/Pizza';
import Deals from './Components/Deals';
import MenuWings from './Components/menuWings';
import MenuSides from './Components/MenuSides';
import SignIn from './Components/SignIn';
import Register from './Components/Register';
import Dashboard from './Components/Dashboard';
import Cart from './Components/Cart';
import Forgot from './Components/Forgot';
import MenuDesserts from './Components/MenuDesserts';
import Wraps from './Components/Wraps';
import Stromboli from './Components/Stromboli';
import FiredChicken from './Components/FiredChicken';
import Drinks from './Components/Drinks';
import KidsMenu from './Components/KidsMenu';
import PitaSandwiches from './Components/PitaSandwiches';
import Salad from './Components/Salad';
import Burger from './Components/burger';
import Subs from './Components/Subs';
import Pasta from './Components/Pasta';
import ProductInner from './Components/ProductInner';
import StromboliMaker from './Components/StromboliMaker';
import DealInner from './Components/DealsInner';
import CheckOut from './Components/checkOut';
import Thankyou from './Components/thankyou';

import './App.css';

function App() {
    console.log(process.env.REACT_APP_BASE_URL);

    return (
        <Provider store={store}>
            <div>
                <Router>
                    <Switch>
                        <Route exact path="/" render={(props) => <Home {...props} />} />
                        <Route exact path="/sign_in" render={(props) => <SignIn {...props} />} />
                        <Route exact path="/register" render={(props) => <Register {...props} />} />
                        <Route exact path="/menu/pizza" render={(props) => <Pizza {...props} />} />
                        <Route exact path="/pizza_maker" render={(props) => <PizzaMaker {...props} />} />
                        <Route exact path="/menu/Sides" render={(props) => <MenuSides {...props} />} />
                        <Route exact path="/Deals" render={(props) => <Deals {...props} />} />
                        <Route exact path="/cart" render={(props) => <Cart {...props} />} />
                        <Route exact path="/dashboard" render={(props) => <Dashboard {...props} />} />
                        <Route exact path="/forgot_password" render={(props) => <Forgot {...props} />} />
                        <Route exact path="/menu/desserts" render={(props) => <MenuDesserts {...props} />} />
                        <Route exact path="/menu/wraps" render={(props) => <Wraps {...props} />} />
                        <Route exact path="/menu/stromboli" render={(props) => <Stromboli {...props} />} />
                        <Route exact path="/menu/fried_chicken" render={(props) => <FiredChicken {...props} />} />
                        <Route exact path="/menu/wings" render={(props) => <MenuWings {...props} />} />
                        <Route exact path="/menu/drinks" render={(props) => <Drinks {...props} />} />
                        <Route exact path="/menu/kids_menu" render={(props) => <KidsMenu {...props} />} />
                        <Route exact path="/menu/pita_sandwiches" render={(props) => <PitaSandwiches {...props} />} />
                        <Route exact path="/menu/salad" render={(props) => <Salad {...props} />} />
                        <Route exact path="/menu/burger" render={(props) => <Burger {...props} />} />
                        <Route exact path="/menu/subs" render={(props) => <Subs {...props} />} />
                        <Route exact path="/menu/pasta" render={(props) => <Pasta {...props} />} />
                        <Route exact path="/item/:id" render={(props) => <ProductInner {...props} />} />
                        <Route exact path="/stromboli_maker" render={(props) => <StromboliMaker {...props} />} />
                        <Route exact path="/deal/:id" render={(props) => <DealInner {...props} />} />
                        <Route exact path="/checkOut" render={(props) => <CheckOut {...props} />} />
                        <Route exact path="/order/:id" render={(props) => <Thankyou {...props} />} />
                    </Switch>
                    <Footer />
                </Router>
                <div>
                    <Toaster duration={'10000'} toastOptions={{ style: { textTransform: "capitalize", fontWeight: "bold" } }} />
                </div>
            </div>
        </Provider>
    );
}

export default App;
