import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Account from './Account';
import Orders from './Orders';
import Header from './Header';
import Cards from './cards';


const Dashboard = (props) => {
    const [Active, setActive] = useState(props.location.state?.some === "Profile" ? "1" : "2");
    const toggle = (tab) => {
        if (Active !== tab) {
            setActive(tab);
        }
    };

    return (
        <React.Fragment>
            <Header props={props} />
            <Container>
                <div className="nav-vertical mt-4">
                    <Nav tabs className="nav-fill">
                        <NavItem>
                            <NavLink
                                className={Active === '1' ? 'active' : ''}
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                Profile
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={Active === '2' ? 'active' : ''}
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                Orders
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={Active === '3' ? 'active' : ''}
                                onClick={() => {
                                    toggle('3');
                                }}
                            >
                                Card
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={Active}>
                        <TabPane tabId="1">
                            {Active === "1" && <Account />}
                        </TabPane>
                        <TabPane tabId="2">
                            {Active === "2" && <Orders />}
                        </TabPane>
                        <TabPane tabId="3">
                            {Active === "3" && <Cards />}
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </React.Fragment>
    );
};
export default Dashboard;
