export const CRUST = 'CRUST';
export const SAUCE = 'SAUCE';
export const CHEESE = 'CHEESE';
export const MENU = 'MENU';
export const CART = 'CART';
export const TOKEN = 'TOKEN';
export const SIGNED = 'SIGNED';
export const PRODUCTID = 'PRODUCTID';
export const PRODUCT = 'PRODUCT';
export const DEALS = 'DEALS';
export const DEAL = 'DEAL';
export const DISCOUNT = 'DISCOUNT';
export const OPTION = 'OPTION';
export const DELIVERY = 'DELIVERY';
export const COUPON = 'COUPON';
