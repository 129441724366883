import React from "react";
import { Container, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import axios from 'axios';

import Stripe from "./stripe/stripe";
import Header from "./Header";
import toast from "react-hot-toast";

const hours = [
    {
        Time: "11", Am: true
    },
    {
        Time: "12", Am: false
    },
    {
        Time: "1", Am: false
    },
    {
        Time: "2", Am: false
    },
    {
        Time: "3", Am: false
    },
    {
        Time: "4", Am: false
    },
    {
        Time: "5", Am: false
    },
    {
        Time: "6", Am: false
    },
    {
        Time: "7", Am: false
    },
    {
        Time: "8", Am: false
    }
];
const minute = ["00", "15", "30", "45"];

let schema = yup.object().shape({
    contactless: yup.boolean(),
    guest: yup.boolean(),
    register: yup.boolean(),
    password: yup
        .string()
        .when("register", {
            is: true,
            then: yup.string().required("Password Is Required Field!")
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
        }),
    confirmPassword: yup
        .string()
        .when("register", {
            is: true,
            then: yup.string().required("Confirm Password Is Required Field!").oneOf([yup.ref('password'), null], 'Passwords must match'),
        }),
    time: yup
        .string()
        .when("Scheduled", {
            is: true,
            then: yup.string().required("Time Is Required Field!"),
        }),
    vehicle: yup
        .string()
        .when("contactless", {
            is: true,
            then: yup.string().required("Vehicle Is Required Field!"),
        }),
    color: yup
        .string()
        .when("contactless", {
            is: true,
            then: yup.string().required("Color Is Required Field!"),
        }),
    placeFood: yup
        .string()
        .when("contactless", {
            is: true,
            then: yup.string().required("Place food Is Required Field!"),
        }),
    firstName: yup
        .string()
        .when("guest", {
            is: true,
            then: yup.string().required("First Name Is Required Field!"),
        }),
    lastName: yup
        .string()
        .when("guest", {
            is: true,
            then: yup.string().required("Last Name Is Required Field!"),
        }),
    phone: yup
        .string()
        .when("guest", {
            is: true,
            then: yup.string().required("Phone Is Required Field!"),
        }),
    extension: yup
        .number()
        .when("guest", {
            is: true,
            then: yup.number(),
        }),
    email: yup
        .string()
        .when("guest", {
            is: true,
            then: yup.string().required("Email Is Required Field!").email(),
        }),
});

const CheckOut = (props) => {
    const formik = useFormik({
        initialValues: {
            selected: false,
            contactless: false,
            register: false,
            guest: !props.signedIn,
            time: "",
            vehicle: "",
            color: "",
            placeFood: "",
            firstName: "",
            lastName: "",
            extension: "",
            phone: "",
            email: "",
            delivery: "",
            password: "",
            confirmPassword: ""
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: values => {
            checkTime(values);
        }
    });

    const [hourBoolean, SetHourBoolean] = React.useState(false);
    const [dateBoolean, setDateBoolean] = React.useState(false);
    const [scheduled, setScheduled] = React.useState(false);
    const [contactless, setContactless] = React.useState(false);
    const [am, setAm] = React.useState(false);
    const [time, setTime] = React.useState("");
    const [date, setDate] = React.useState("");
    const [mint, setMint] = React.useState("");
    const [deliveryTime, setDeliveryTime] = React.useState("");
    const [options, setOptions] = React.useState(false);
    const [values, setValues] = React.useState();
    const [storeTime, setStoreTime] = React.useState();
    const [delivery, setDelivery] = React.useState(0);
    const [dateDisplay, setDateDisplay] = React.useState("");
    const [mintDisplay, setMintDisplay] = React.useState("");

    React.useMemo(() => {
        if (props.cart.length === 0) {
            props.history.push("/")
        }
    }, [])

    const getDates = () => {
        let days = [];
        let daysRequired = 7
        let date = moment().subtract(1, 'days')

        for (let i = 1; i <= daysRequired; i++) {
            days.push(moment(date).add(i, 'days').format('dddd, Do MMMM YYYY'))
        }
        return days;
    }

    const timeHandler = React.useCallback((mint, hour) => {
        let Format = mint;
        let FormatHour = hour ? hour : date;
        const time2 = `${time}, ${FormatHour}:${Format} ${am ? "AM" : "PM"}`
        const converted = moment(time2, 'DD/MM/YYYY,HH:mm a').format('YYYY-MM-DD[T]HH:mm:ss')
        setDeliveryTime(moment(converted).unix());
        setMint(Format);
        formik.setFieldValue("time", moment(converted).unix());
    }, [time, am, setDeliveryTime, date]);

    const hourHandler = React.useCallback((hour) => {
        if (date === "") {
            let Foramat = JSON.parse(hour);
            const selectHour = `${Foramat.Time}`
            setAm(Foramat.Am);
            setDate(selectHour);
            SetHourBoolean(true);
        }
        else {
            let Foramat = JSON.parse(hour);
            const selectHour = `${Foramat.Time}`
            setAm(Foramat.Am);
            setDate(selectHour);
            timeHandler(mint, Foramat.Time);
        }

    }, [setAm, time, setDate, mint, timeHandler]);

    const storeTiming = React.useCallback(() => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/store-timing`,
            headers: {
                'Accept': 'application/json'
            }
        };

        axios(config)
            .then(function (response) {
                setStoreTime(response.data.response.data.storeTime);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, [setStoreTime]);

    React.useEffect(() => {
        storeTiming();
    }, []);

    const guestLogin = React.useCallback(async (values) => {
        try {
            var axios = require('axios');
            var data = new FormData();
            data.append('email', values.email);
            data.append('phoneNumber', values.phone);
            data.append('firstName', values.firstName);
            data.append('lastName', values.lastName);
            data.append('extension', values.extension);
            props.option === "DELIVERY" && data.append('address', JSON.parse(window.sessionStorage.getItem("user")).street);
            props.option === "DELIVERY" && data.append('city', JSON.parse(window.sessionStorage.getItem("user")).city);
            props.option === "DELIVERY" && data.append('state', JSON.parse(window.sessionStorage.getItem("user")).state);
            props.option === "DELIVERY" && data.append('zipCode', JSON.parse(window.sessionStorage.getItem("user")).zipCode);
            props.option === "DELIVERY" && data.append('apartment', JSON.parse(window.sessionStorage.getItem("user")).apartment);
            props.option === "DELIVERY" && data.append('lat', JSON.parse(window.sessionStorage.getItem("user")).lat);
            props.option === "DELIVERY" && data.append('lng', JSON.parse(window.sessionStorage.getItem("user")).lang);

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/v1/login-as-guest`,
                data: data
            };

            const response = await axios(config);
            props.tokenHandler(response.data.response.data.auth);
            setOptions(true);
        }
        catch (e) {
            toast.error(e?.response?.data?.response?.message);
        }
    }, [props]);

    const nextHandler = React.useCallback((values) => {
        const date12 = moment().format("hh:mm A");
        var format = 'hh:mm A'
        var time = moment(date12, format),
            beforeTime = moment(storeTime.openTime, format),
            afterTime = moment(storeTime.closeTime, format);

        if (!props.signedIn) {
            if (!scheduled) {
                if (storeTime.offOnDay === "On") {
                    if (time.isBetween(beforeTime, afterTime)) {
                        if (props.option === "DELIVERY") {
                            checkAddress(values);
                        } else if (props.option === "CARRYOUT") {
                            if (values.register) {
                                registerHandler(values);
                            }
                            else {
                                guestLogin(values);
                            }
                        }
                    } else {
                        toast.error(`Store Is Close Please Schedule A Order`);
                    }
                } else {
                    toast.error(`Store Is Close Please Schedule A Order`);
                }
            } else if (props.option === "DELIVERY") {
                if (window.sessionStorage.getItem("user")) {
                    checkAddress(values);
                } else {
                    toast.error(`Please provide your address details`);
                }

            } else if (props.option === "CARRYOUT") {
                if (values.register) {
                    registerHandler(values);
                }
                else {
                    guestLogin(values);
                }
            }
        } else {
            if (!scheduled) {
                if (storeTime.offOnDay === "On") {
                    if (time.isBetween(beforeTime, afterTime)) {
                        if (props.option === "DELIVERY") {
                            checkAddress(values);
                        } else {
                            setOptions(true);
                        }
                    } else {
                        toast.error(`Store Is Close Please Schedule A Order`);
                    }
                } else {
                    toast.error(`Store Is Close Please Schedule A Order`);
                }
            } else if (props.option === "DELIVERY") {
                if (props.user?.address) {
                    checkAddress(values);
                } else {
                    toast.error(`Please provide your address details`);
                }
            } else if (props.option === "CARRYOUT") {
                setOptions(true);
            }
        }


    }, [storeTime, guestLogin, setOptions, props.user, scheduled, props.token, props.option, props.signedIn, formik]);

    const registerHandler = async (values) => {
        try {
            var data = new FormData();
            data.append('email', values.email);
            data.append('phoneNumber', values.phone);
            data.append('firstName', values.firstName);
            data.append('lastName', values.lastName);
            props.option === "DELIVERY" && data.append('address', JSON.parse(window.sessionStorage.getItem("user")).address);
            props.option === "DELIVERY" && data.append('city', JSON.parse(window.sessionStorage.getItem("user")).city);
            props.option === "DELIVERY" && data.append('state', JSON.parse(window.sessionStorage.getItem("user")).state);
            props.option === "DELIVERY" && data.append('zipCode', JSON.parse(window.sessionStorage.getItem("user")).zipCode);
            props.option === "DELIVERY" && data.append('apartment', JSON.parse(window.sessionStorage.getItem("user")).apartment);
            props.option === "DELIVERY" && data.append('lat', JSON.parse(window.sessionStorage.getItem("user")).lat);
            props.option === "DELIVERY" && data.append('lng', JSON.parse(window.sessionStorage.getItem("user")).lang);
            data.append('password', values.password);

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/v1/signup`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            const Response = await axios(config);
            toast.success(`Welcome ${Response.data.response.data.auth.firstName}`);
            props.tokenHandler(Response.data.response.data.auth);
            props.signHandler(true);
            setOptions(true);
        } catch (e) {
            console.log(e);
            // toast.error(`${e.response.data.response.message}`);
        }
    };

    const checkAddress = React.useCallback(async (values) => {
        try {
            // eslint-disable-next-line no-undef
            const directionsService = new google.maps.DirectionsService()
            if (props.signedIn) {
                var end = `${props.user.lat}, ${props.user.lng}`
            }
            else {
                var end = `${JSON.parse(window.sessionStorage.getItem("user")).lat}, ${JSON.parse(window.sessionStorage.getItem("user")).lang}`;
            }

            var start = '39.27574376183423, -77.52916387534584';

            const results = await directionsService.route(
                {
                    origin: start,
                    destination: end,
                    travelMode: "DRIVING",
                    // eslint-disable-next-line no-undef
                    unitSystem: google.maps.UnitSystem.METRIC
                }
            )

            const address = results.routes[0].legs[0].distance.value;

            const miles = address * 0.000621;
            if (!props.signedIn) {
                if (miles < 5) {
                    setDelivery(Number(props.delivery.lessThenRadius));
                    if (values.register) {
                        registerHandler();
                    }
                    else {
                        guestLogin();
                    }
                } else if (miles > 5 && miles <= 10) {
                    setDelivery(Number(props.delivery.greaterThenRadius));
                    if (values.register) {
                        registerHandler();
                    }
                    else {
                        guestLogin();
                    }
                } else {
                    toast.error("Sorry we don’t deliver in your area but you can place an order for pickup");
                }
            } else {
                if (miles < 5) {
                    setDelivery(Number(props.delivery.lessThenRadius));
                    setOptions(true);
                } else if (miles <= 10) {
                    setDelivery(Number(props.delivery.greaterThenRadius));
                    setOptions(true);
                } else {
                    toast.error("Sorry we don’t deliver in your area but you can place an order for pickup");
                }
            }
        }
        catch {
            toast.error("No Address found add address on clicking the address on header");
        }

    }, [setOptions, props.signedIn, props.user, props.delivery]);

    const checkTime = React.useCallback((values) => {
        setValues(values);
        if (scheduled) {
            var dateString = moment.unix(deliveryTime)
            const date12 = moment();

            var beginningTime = moment(dateString);
            var currentTime = date12;
            if (beginningTime.isAfter(currentTime)) {
                nextHandler(values);
            }
            else {
                toast.error("Please select time in Future and 30 minutes after the current time!");
            }
        }
        else {
            nextHandler(values);
        }

    }, [storeTime, deliveryTime, scheduled]);

    return <div>
        <Header props={props} />
        <Container className="mt-3">
            {!options &&
                <form onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
                    <Row className="mb-5 p-4" style={{ border: "1px solid #ffc000", borderRadius: "12px" }}>
                        {!props.signedIn &&
                            <div className="mb-4">
                                <h1>My Information</h1>
                                <div className="d-flex flex-wrap mt-3">
                                    <div className="col-sm-12 col-lg-6  p-1">
                                        <label className="d-block"><b>First Name</b></label>
                                        <input
                                            onChange={formik.handleChange}
                                            name="firstName"
                                            placeholder="First Name"
                                            style={{ width: "100%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}
                                        />
                                        {
                                            formik.errors.firstName && formik.touched.firstName
                                            && <div className="text-danger">{formik.errors.firstName}</div>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-lg-6 p-1">
                                        <label className="d-block"><b>Last Name</b></label>
                                        <input
                                            onChange={formik.handleChange}
                                            name="lastName"
                                            placeholder="Last Name"
                                            style={{ width: "100%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}
                                        />
                                        {
                                            formik.errors.lastName && formik.touched.lastName &&
                                            <div className="text-danger">{formik.errors.lastName}</div>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-lg-6  p-1">
                                        <label className="d-block"><b>Email</b></label>
                                        <input
                                            onChange={formik.handleChange}
                                            name="email"
                                            placeholder="Email"
                                            style={{ width: "100%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}
                                        />
                                        {
                                            formik.errors.email && formik.touched.email
                                            && <div className="text-danger">{formik.errors.email}</div>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-lg-6 p-1">
                                        <label className="d-block"><b>Phone</b></label>
                                        <InputMask mask="999-999-9999"
                                            className={`form-control ${formik.errors.phone && formik.touched.phone && 'is-invalid'}`}
                                            onChange={(e) => formik.setFieldValue('phone', e.target.value)}
                                            value={props.value}
                                        />
                                        {
                                            formik.errors.phone && formik.touched.phone &&
                                            <div className="text-danger">{formik.errors.phone}</div>
                                        }
                                    </div>
                                    <div className="col-sm-12 col-lg-6 p-1">
                                        <label className="d-block"><b>Extension</b></label>
                                        <input
                                            onChange={formik.handleChange}
                                            name="extension"
                                            placeholder="Extension"
                                            style={{ width: "100%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}
                                        />
                                        {
                                            formik.errors.extension && formik.touched.extension &&
                                            <div className="text-danger">{formik.errors.extension}</div>
                                        }
                                    </div>
                                    <div className="col-sm-12 p-1 mt-2">
                                        <label className="checkout-check">
                                            <input type="checkbox"
                                                onChange={(e) => {
                                                    formik.setTouched({}, false);
                                                    formik.setFieldValue("register", e.target.checked)
                                                }} />
                                            <b>Create Account</b>
                                        </label>
                                    </div>
                                    {formik.values.register && <>
                                        <div className="col-sm-12 col-lg-6 p-1">
                                            <label className="d-block"><b>Password</b></label>
                                            <input
                                                onChange={formik.handleChange}
                                                name="password"
                                                placeholder="Password"
                                                type="password"
                                                style={{ width: "100%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}
                                            />
                                            {
                                                formik.errors.password && formik.touched.password &&
                                                <div className="text-danger">{formik.errors.password}</div>
                                            }
                                        </div>
                                        <div className="col-sm-12 col-lg-6 p-1">
                                            <label className="d-block"><b>Confirm Password</b></label>
                                            <input
                                                onChange={formik.handleChange}
                                                name="confirmPassword"
                                                placeholder="Confirm Password"
                                                type="password"
                                                style={{ width: "100%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}
                                            />
                                            {
                                                formik.errors.confirmPassword && formik.touched.confirmPassword &&
                                                <div className="text-danger">{formik.errors.confirmPassword}</div>
                                            }
                                        </div>
                                    </>}
                                </div>
                            </div>}
                        <div className="mb-4">
                            <h2>{props.option === "DELIVERY" ? "Delivery" : "CarryOut"}</h2>
                            <b>Schedule for later</b>
                            <p>Carryout is available till {storeTime?.closeTime}.</p>
                            <label className="checkout-check mt-5 mb-5">
                                <input type="checkbox"
                                    onChange={(e) => {
                                        setScheduled(e.target.checked);
                                        formik.setTouched({}, false);
                                        formik.setFieldValue("Scheduled", e.target.checked)
                                    }} />
                                Schedule for later.
                            </label>
                            {scheduled &&
                                <div>
                                    <div className="d-lg-flex">
                                        <div className="col-sm-12 col-lg-6 mb-4">
                                            <select
                                                className="date-check"
                                                onChange={(e) => {
                                                    setTime(moment(e.target.value, "dddd, Do MMMM YYYY").format("DD/MM/YYYY"));
                                                    setDateBoolean(true);
                                                    setDate("");
                                                    setDateDisplay("");
                                                    setMintDisplay("");
                                                }}
                                            >
                                                <option className="d-none">Select date</option>
                                                {getDates().map((date, index) => <option key={index} value={date}>{date}</option>)}
                                            </select>
                                        </div>
                                        <div className="date-col-2 col-sm-12 col-lg-6">
                                            <select
                                                onChange={(e) => {
                                                    hourHandler(e.target.value);
                                                    setDateDisplay(e.target.value)
                                                }}
                                                value={dateDisplay}
                                                disabled={!dateBoolean}>
                                                <option className="d-none">HH</option>
                                                {hours.map((hour, index) => <option key={index} value={JSON.stringify(hour)}>{hour.Time}</option>)}
                                            </select>
                                            <select
                                                disabled={!hourBoolean}
                                                value={mintDisplay}
                                                onChange={(e) => {
                                                    timeHandler(e.target.value, date);
                                                    setMintDisplay(e.target.value);
                                                }}>
                                                <option className="d-none">MM</option>
                                                {minute.map((mint, index) => <option key={index} value={mint}>{mint}</option>)}
                                            </select>
                                            <select disabled>
                                                <option className="d-none">AM/PM</option>
                                                <option selected={am}>AM</option>
                                                <option selected={!am}>PM</option>
                                            </select>
                                        </div>

                                    </div>
                                    {
                                        scheduled && <div>
                                            {
                                                formik.errors.time && formik.touched.time &&
                                                <div className="text-danger">{formik.errors.time} {formik.values.selected}</div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {props.option === "CARRYOUT" &&
                                <div className="mt-5">
                                    <b>Contactless curbside</b>
                                    <label className="checkout-check mb-5">
                                        <input type="checkbox"
                                            onChange={(e) => {
                                                setContactless(e.target.checked);
                                                formik.setFieldValue("contactless", e.target.checked)
                                            }} />
                                        I want contactless curbside*
                                    </label>
                                    {
                                        contactless && <div className="d-flex flex-wrap">
                                            <div className="col-sm-12 col-lg-6 mb-2">
                                                <label className="d-block"><b>Tell us what your'e driving</b></label>
                                                <input
                                                    onChange={formik.handleChange}
                                                    name="vehicle"
                                                    placeholder="Make/Model/Features"
                                                    style={{ width: "90%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}
                                                />
                                                {formik.errors.vehicle && formik.touched.vehicle ? <div className="text-danger">{formik.errors.vehicle}</div> : null}
                                            </div>
                                            <div className="col-sm-12 col-lg-6 mb-2">
                                                <label className="d-block"><b>Vehicle color</b></label>
                                                <select onChange={formik.handleChange} name="color" style={{ width: "90%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}>
                                                    <option className="d-none">Color</option>
                                                    <option value="Black">Black</option>
                                                    <option value="Blue">Blue</option>
                                                    <option value="Brown">Brown</option>
                                                    <option value="Gold">Gold</option>
                                                    <option value="Grey">Grey</option>
                                                    <option value="Green">Green</option>
                                                    <option value="Orange">Orange</option>
                                                    <option value="Purple">Purple</option>
                                                    <option value="Red">Red</option>
                                                    <option value="Silver">Silver</option>
                                                    <option value="Tan">Tan</option>
                                                    <option value="White">White</option>
                                                    <option value="Yellow">Yellow</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {
                                                    formik.errors.color && formik.touched.color &&
                                                    <div className="text-danger">{formik.errors.color}</div>
                                                }
                                            </div>
                                            <div className="mt-2 col-sm-12 col-lg-6 mb-2">
                                                <label className="d-block"><b>Tell us where to place food</b></label>
                                                <select
                                                    onChange={formik.handleChange}
                                                    name="placeFood"
                                                    style={{ width: "90%", padding: "8px 10px", borderRadius: "12px", border: "1px solid silver" }}>
                                                    <option className="d-none">Ex. trunk</option>
                                                    <option value="Trunk">Trunk</option>
                                                    <option value="Backseat">Backseat</option>
                                                </select>
                                                {
                                                    formik.errors.placeFood && formik.touched.placeFood &&
                                                    <div className="text-danger">{formik.errors.placeFood}</div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <b>Kindly call the store once you arrived at the curbside location*</b>
                                </div>
                            }
                        </div>
                        <Button className="w-25 m-auto"
                            style={{ backgroundColor: "#ffc000", borderColor: "#ffc000" }}
                            type="submit"
                        >
                            Next
                        </Button>
                    </Row>
                </form>
            }

            {options && <Stripe history={props.history} values={values} delivery={delivery} />}
        </Container>
    </div>;
}

const mapStateToProps = (state) => {
    return {
        option: state.Pizza.option,
        signedIn: state.Pizza.signedIn,
        user: state.Pizza.token,
        cart: state.Pizza.cart,
        delivery: state.Pizza.delivery,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        tokenHandler: (value) => dispatch({ type: 'TOKEN', value: value }),
        signHandler: (value) => dispatch({ type: 'SIGNED', value: value }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckOut);