import React from 'react';
import { Col, Container, FormGroup, Row, Spinner } from 'reactstrap';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import axios from 'axios';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import Header from './Header';
import ReCAPTCHA from "react-google-recaptcha";

const remember = JSON.parse(localStorage.getItem("remember"));

let schema = yup.object().shape({
    email: yup.string().required("Email Is A Required Field!").email(),
    password: yup.string()
        .required("Password Is A Required Field!")
        .min(8, 'Password Is Too Short - Should Be 8 Chars Minimum.')
        .matches(/[a-zA-Z]/, 'Password Can Only Contain Latin letters.'),
    remember: yup.boolean(),
    captcha: yup.string().required("Captcha Is A Required Field!").nullable()
});
const SignIn = (props) => {
    const [Loader, setLoader] = React.useState(false);

    const registerHandler = async (token) => {
        try {
            var data = new FormData();
            data.append('apartment', JSON.parse(window.sessionStorage.getItem("user")).apartment);
            data.append('city', JSON.parse(window.sessionStorage.getItem("user")).city);
            data.append('state', JSON.parse(window.sessionStorage.getItem("user")).state);
            data.append('zipCode', JSON.parse(window.sessionStorage.getItem("user")).zipCode);
            data.append('address', JSON.parse(window.sessionStorage.getItem("user")).street);

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}v1/user/profile/update`,
                headers: {
                    Authorization: `Bearer ${token.jwt}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            await axios(config);
            getdata(token);
        } catch (e) {
            console.log(e);
        }
    };

    const getdata = async (tokenJwt) => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/user/profile`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${tokenJwt.jwt}`,
            },
        };

        const response = await axios(config);
        const token = { jwt: tokenJwt.jwt }
        const returnedTarget = Object.assign(response.data.response.data.auth, token);
        props.tokenHandler(returnedTarget);
        props.signHandler(true);
        window.sessionStorage.removeItem("guest");
        props.history.push('/');
        toast.success(`Welcome ${response.data.response.data.auth.firstName}`);
    };

    const signInHandler = async (values) => {
        setLoader(true);
        try {
            var data = JSON.stringify({
                email: values.email,
                password: values.password,
            });

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/v1/signin`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: data,
            };

            const Response = await axios(config);
            setLoader(false);
            if (values.remember) {
                const data = { email: values.email, password: values.password }
                localStorage.setItem("remember", JSON.stringify(data))
            }
            if (!window.sessionStorage.getItem("user")) {
                props.tokenHandler(Response.data.response.data.auth);
                props.signHandler(true);
                window.sessionStorage.removeItem("guest");
                props.history.push('/');
                toast.success(`Welcome ${Response.data.response.data.auth.firstName}`);
            } else {
                registerHandler(Response.data.response.data.auth);
            }

        } catch (e) {
            setLoader(false);
            toast.error(`${e.response.data.response.message}`);
        }
    };

    const guestHandler = React.useCallback(() => {
        window.sessionStorage.setItem("guest", true);
        props.history.push('/checkOut');
    }, []);

    return (
        <React.Fragment>
            <Header props={props} />
            <Container>
                <Row className="mt-3">
                    <h2>Sign In To Your Account</h2>
                </Row>
                <Row className="mt-4">
                    <Col sm="8">
                        <Col sm="6">
                            <div>
                                <Formik
                                    initialValues={{
                                        password: remember ? remember.password : "",
                                        email: remember ? remember.email : "",
                                        remember: false,
                                        captcha: ""
                                    }}
                                    validationSchema={schema}
                                    onSubmit={(values) => {
                                        signInHandler(values);
                                    }}
                                >
                                    {({ errors, touched, values, setFieldValue }) => (
                                        <Form>
                                            <label>Email</label>
                                            <FormGroup className="mb-2">
                                                <Field name="email" type="email" value={values.email} className={`form-control ${errors.email && touched.email && 'is-invalid'}`} />
                                                {errors.email && touched.email ? <div className="text-danger">{errors.email}</div> : null}
                                            </FormGroup>
                                            <label>Password</label>
                                            <FormGroup className="mb-2">
                                                <Field name="password" type="password" value={values.password} className={`form-control ${errors.password && touched.password && 'is-invalid'}`} />
                                                {errors.password && touched.password ? <div className="text-danger">{errors.password}</div> : null}
                                            </FormGroup>
                                            <div className='text-center mb-2 w-100'>
                                                <ReCAPTCHA
                                                    sitekey="6Le83zAqAAAAAK356qOoKjDhcp5mntSlnL3Ausqy"
                                                    onChange={(e) => setFieldValue("captcha", e)}
                                                />
                                                {errors.captcha && touched.captcha ? <div className="text-danger">{errors.captcha}</div> : null}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mb-3">
                                                <span style={{ fontSize: '14px', cursor: 'pointer', color: '#ffc000' }} onClick={() => props.history.push('/Register')}>
                                                    Create Acccount
                                                </span>
                                                <span style={{ fontSize: '14px', cursor: 'pointer', color: '#ffc000' }} onClick={() => props.history.push('/forgot_password')}>
                                                    Forgot Password
                                                </span>
                                            </div>

                                            <div className="mb-3 text-center">
                                                <label style={{ color: "#ffc000" }}>
                                                    <Field type="checkbox" name="remember" />
                                                    &nbsp; Remember me
                                                </label>
                                            </div>

                                            <button type="submit" className="Btn" disabled={Loader}>
                                                {!Loader ? 'Submit' : <Spinner style={{ color: '#fff' }} />}
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                                <button type="submit" className="Btn mt-2" onClick={guestHandler}>
                                    Continue As Guest
                                </button>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        tokenHandler: (value) => dispatch({ type: 'TOKEN', value: value }),
        signHandler: (value) => dispatch({ type: 'SIGNED', value: value }),
    };
};
export default connect(null, mapDispatchToProps)(SignIn);
