import React from 'react';
import { Container, FormGroup, Row, Col, Spinner } from 'reactstrap';
import toast from 'react-hot-toast';
import Header from './Header';
const Forgot = (props) => {
 const [email, setEmail] = React.useState();
 const [otpCode, setOtpCode] = React.useState();
 const [Loader, setLoader] = React.useState(false);
 const [OTP, SetOTP] = React.useState('1');
 const [password, SetPassword] = React.useState('');
 const ForgotHandler = async () => {
  setLoader(true);
  try {
   var axios = require('axios');
   var FormData = require('form-data');
   var data = new FormData();
   data.append('email', email);

   var config = {
    method: 'post',
    url: '${process.env.REACT_APP_BASE_URL}v1/forgot-password',
    headers: {
     Accept: 'application/json',
     'Content-Type': 'application/json',
    },
    data: data,
   };

   const Response = await axios(config);
   if (Response.data.response.status) {
    SetOTP('2');
    setLoader(false);
    toast.success(`OTP Sent to Email ${email}`);
   } else {
    setLoader(false);
    toast.error(`Please provide a valid email address`);
   }
  } catch {
   setLoader(false);
   toast.error(`Please provide a valid email address`);
  }
 };
 const OtpHandler = async () => {
  setLoader(true);
  try {
   var axios = require('axios');
   var FormData = require('form-data');
   var data = new FormData();
   data.append('email', email);
   data.append('otp', otpCode);

   var config = {
    method: 'post',
    url: '${process.env.REACT_APP_BASE_URL}v1/verify/otp',
    headers: {
     Accept: 'application/json',
     'Content-Type': 'application/json',
    },
    data: data,
   };

   const Response = await axios(config);
   if (Response.data.response.status) {
    SetOTP('3');
    setLoader(false);
    toast.success(`OTP Verified`);
   } else {
    setLoader(false);
    toast.error(`Enter valid OTP`);
   }
  } catch {
   setLoader(false);
   toast.error(`Enter valid OTP`);
  }
 };
 const ResetPassword = async () => {
  setLoader(true);
  try {
   var axios = require('axios');
   var data = JSON.stringify({
    email: email,
    otp: otpCode,
    password: password,
   });

   var config = {
    method: 'put',
    url: '${process.env.REACT_APP_BASE_URL}v1/password/reset',
    headers: {
     Accept: 'application/json',
     'Content-Type': 'application/json',
    },
    data: data,
   };

   const Response = await axios(config);
   if (Response.data.response.status) {
    setLoader(false);
    toast.success(`Password Resetted`);
    props.history.push('/');
   } else {
    setLoader(false);
    toast.error(`Failed`);
   }
  } catch {
   setLoader(false);
   toast.error(`Failed`);
  }
 };
 return (
  <React.Fragment>
   <Header props={props} />
   <Container>
    <h1 style={{ fontWeight: '600', marginTop: '50px' }}>Forgot Password</h1>
    <Row>
     {OTP === '1' ? (
      <Col sm="6" className="offset-md-3">
       <label className="mb-2">Email</label>
       <FormGroup>
        <input className="form-control" placeholder="Enter your Email" onChange={(e) => setEmail(e.target.value)} />
       </FormGroup>
       <button type="submit" className="Btn mt-3" disabled={Loader} onClick={() => ForgotHandler()}>
        {!Loader ? 'Submit' : <Spinner style={{ color: '#fff' }} />}
       </button>
      </Col>
     ) : OTP === '2' ? (
      <Col sm="6" className="offset-md-3">
       <label className="mb-2">OTP</label>
       <FormGroup>
        <input className="form-control" type="number" placeholder="Enter OTP sent to your Email" onChange={(e) => setOtpCode(e.target.value)} />
       </FormGroup>
       <button type="submit" className="Btn mt-3" disabled={Loader} onClick={() => OtpHandler()}>
        {!Loader ? 'Submit' : <Spinner style={{ color: '#fff' }} />}
       </button>
      </Col>
     ) : OTP === '3' ? (
      <Col sm="6" className="offset-md-3">
       <label className="mb-2">Password</label>
       <FormGroup>
        <input className="form-control" type="password" placeholder="Password" onChange={(e) => SetPassword(e.target.value)} />
       </FormGroup>
       <button type="submit" className="Btn mt-3" disabled={Loader} onClick={() => ResetPassword()}>
        {!Loader ? 'Submit' : <Spinner style={{ color: '#fff' }} />}
       </button>
      </Col>
     ) : null}
    </Row>
   </Container>
  </React.Fragment>
 );
};
export default Forgot;
