import React from 'react';
import { Col, FormGroup, Spinner } from 'reactstrap';
import * as yup from 'yup';
import { input, useFormik } from 'formik';
import axios from 'axios';
import Media from './Media';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import toast from 'react-hot-toast';
let schema = yup.object().shape({
    email: yup.string().email(),
    FirstName: yup.string().required('First Name is Required'),
    LastName: yup.string().required('Last Name is Required'),
    Phone: yup.string(),
    address: yup.string(),
    city: yup.mixed(),
    state: yup.mixed(),
    zip: yup.mixed(),
    apartment: yup.mixed(),
});
const Account = (props) => {
    const [Loader, setLoader] = React.useState(false);
    const [image, setImage] = React.useState(false);
    let autocomplete = null;

    const formik = useFormik({
        initialValues: {
            email: props.user.email,
            FirstName: props.user.firstName,
            LastName: props.user.lastName,
            Phone: props.user.phoneNumber,
            address: props.user.address,
            image: props.user.image,
            city: props.user.city,
            state: props.user.state,
            zip: props.user.zipCode,
            apartment: props.user.apartment,
            time: props.user.deliveryTime,
            lat: props.user.lat,
            lng: props.user.lng,
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: values => {
            registerHandler(values);
        },
    });

    const registerHandler = async (values) => {
        setLoader(true);
        try {
            var data = new FormData();
            values.email && data.append('email', values.email);
            values.Phone && data.append('phoneNumber', values.Phone);
            values.FirstName && data.append('firstName', values.FirstName);
            values.LastName && data.append('lastName', values.LastName);
            values.apartment && data.append('apartment', values.apartment);
            values.city && data.append('city', values.city);
            values.state && data.append('state', values.state);
            values.zip && data.append('zipCode', values.zip);
            values.address && data.append('address', values.address);
            values.lat && data.append('lat', values.lat);
            values.lng && data.append('lng', values.lng);
            image && data.append('image', values.image);

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/v1/user/profile/update`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${props.user.jwt}`,
                },
                data: data
            };

            await axios(config);
            toast.success("Profile is updated successfully");
            setLoader(false);
            getdata();
            setImage(false);
        } catch (e) {
            toast.error(e.response.data.response.message);
            setLoader(false);
        }
    };

    const getdata = async () => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/user/profile`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${props.user.jwt}`,
            },
        };

        const response = await axios(config);
        const token = { jwt: props.user.jwt }
        const returnedTarget = Object.assign(response.data.response.data.auth, token);
        props.tokenHandler(returnedTarget);
    };

    const handleVideoChange = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        if (file) {
            formik.setFieldValue("image", file);
            setImage(true);
        }
    };

    const getLocation = React.useCallback((e) => {
        e.preventDefault();
        const input = document.getElementById("pac-input");
        const options = {
            types: ['address'],
        };
        // eslint-disable-next-line no-undef
        let auto_complete = new google.maps.places.Autocomplete(
            input,
            options,
        );

        auto_complete.setFields(['address_components', 'name', "geometry"]);

        auto_complete.addListener('place_changed', () => {
            autocomplete = auto_complete.getPlace();
            if (autocomplete) {
                checkAddress(autocomplete);
            }
        });

    }, [autocomplete]);

    const checkAddress = React.useCallback(async (autocomplete) => {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()

        var start = '39.27574376183423, -77.52916387534584';
        var end = `${autocomplete.geometry.location.lat()}, ${autocomplete.geometry.location.lng()}`;

        const results = await directionsService.route(
            {
                origin: start,
                destination: end,
                travelMode: "DRIVING",
                // eslint-disable-next-line no-undef
                unitSystem: google.maps.UnitSystem.METRIC
            }
        )
        const address = results.routes[0].legs[0].distance.value;
        const miles = address * 0.000621;

        if (miles < 10) {
            let address = autocomplete.address_components;
            const city = address.filter((i) => i.types[0] === "locality" || i.types[0] === "sublocality_level_1");
            const state = address.filter((i) => i.types[0] === "administrative_area_level_1");
            const zip = address.filter((i) => i.types[0] === "postal_code");
            formik.setFieldValue("address", autocomplete.name);
            formik.setFieldValue("city", city[0]?.long_name);
            formik.setFieldValue("state", state[0]?.long_name);
            formik.setFieldValue("zip", zip[0]?.short_name);
            formik.setFieldValue("lat", autocomplete.geometry.location.lng());
            formik.setFieldValue("lng", autocomplete.geometry.location.lat());
            formik.setFieldValue("time", results.routes[0].legs[0].duration.text);
        } else {
            toast.error("Sorry we don’t deliver in your area but you can place an order for pickup");
        }

    }, [props, autocomplete]);

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    return (
        <Col sm="6" className="offset-md-3 mt-4">
            <form className="registerForm" onReset={formik.resetForm} onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
                <div className="mb-2">
                    <span>ACCOUNT INFORMATION</span>
                </div>
                <img src={image ? URL.createObjectURL(formik.values.image) : props.user.image} style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover', margin: '0 auto', display: 'block' }} />
                <label className="d-block">Image</label>
                <FormGroup className="mb-2">
                    <input name="image" type="file" accept="image/png, image/jpeg" onChange={(e) => {
                        handleVideoChange(e)
                    }}
                        className={`form-control ${formik.errors.image && formik.touched.image && 'is-invalid'}`} />
                    {formik.errors.image && formik.touched.image ? <div className="text-danger">{formik.errors.image}</div> : null}
                </FormGroup>
                <label>Email</label>
                <FormGroup className="mb-2">
                    <input name="email" type="email" onChange={formik.handleChange} disabled value={formik.values.email} className={`form-control ${formik.errors.email && formik.touched.email && 'is-invalid'}`} />
                    {formik.errors.email && formik.touched.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                </FormGroup>
                <div className="mb-2">
                    <span>YOUR INFORMATION</span>
                </div>
                <label>First name</label>
                <FormGroup className="mb-2">
                    <input name="FirstName" value={formik.values.FirstName} onChange={formik.handleChange} type="text" className={`form-control ${formik.errors.FirstName && formik.touched.FirstName && 'is-invalid'}`} />
                    {formik.errors.FirstName ? <div className="text-danger">{formik.errors.FirstName}</div> : null}
                </FormGroup>
                <label>Last name</label>
                <FormGroup className="mb-2">
                    <input name="LastName" type="text" value={formik.values.LastName} onChange={formik.handleChange} className={`form-control ${formik.errors.LastName && formik.touched.LastName && 'is-invalid'}`} />
                    {formik.errors.LastName ? <div className="text-danger">{formik.errors.LastName}</div> : null}
                </FormGroup>
                <label>Phone</label>
                <FormGroup className="mb-2">
                    <InputMask
                        mask="999-999-9999"
                        className={`form-control ${formik.errors.Phone && formik.touched.Phone && 'is-invalid'}`}
                        onChange={(e) => formik.setFieldValue('Phone', e.target.value)}
                        value={formik.values.Phone}
                    />
                    {formik.errors.Phone && formik.touched.Phone ? <div className="text-danger">{formik.errors.Phone}</div> : null}
                </FormGroup>
                <label>Address</label>
                <FormGroup className="mb-2">
                    <input
                        id="pac-input"
                        autoComplete="off"
                        onChange={(e) => {
                            getLocation(e);
                            formik.setFieldValue("address", e.target.value);
                        }}
                        name="address"
                        type="text"
                        value={formik.values.address}
                        className={`form-control ${formik.errors.address && formik.touched.address && 'is-invalid'}`}
                    />
                    {formik.errors.address && formik.touched.address ? <div className="text-danger">{formik.errors.address}</div> : null}
                </FormGroup>
                <label>Apartment</label>
                <FormGroup className="mb-2">
                    <input name="apartment" type="text" onChange={formik.handleChange} value={formik.values.apartment} className={`form-control ${formik.errors.apartment && formik.touched.apartment && 'is-invalid'}`} />
                    {formik.errors.apartment && formik.touched.apartment ? <div className="text-danger">{formik.errors.apartment}</div> : null}
                </FormGroup>
                <label>City</label>
                <FormGroup className="mb-2">
                    <input name="city" type="text" onChange={formik.handleChange} value={formik.values.city} className={`form-control ${formik.errors.city && formik.touched.city && 'is-invalid'}`} />
                    {formik.errors.city && formik.touched.city ? <div className="text-danger">{formik.errors.city}</div> : null}
                </FormGroup>
                <label>State</label>
                <FormGroup className="mb-2">
                    <input name="state" type="text" onChange={formik.handleChange} value={formik.values.state} className={`form-control ${formik.errors.state && formik.touched.state && 'is-invalid'}`} />
                    {formik.errors.state && formik.touched.state ? <div className="text-danger">{formik.errors.state}</div> : null}
                </FormGroup>
                <label>Zip Code</label>
                <FormGroup className="mb-2">
                    <input name="zip" type="text" onChange={formik.handleChange} value={formik.values.zip} className={`form-control ${formik.errors.zip && formik.touched.zip && 'is-invalid'}`} />
                    {formik.errors.zip && formik.touched.zip ? <div className="text-danger">{formik.errors.zip}</div> : null}
                </FormGroup>
                <div style={{ textAlign: 'center' }}>
                    <button type="submit" className="cartOrder" disabled={Loader}>
                        {!Loader ? 'Submit' : <Spinner style={{ color: '#ffc000' }} />}
                    </button>
                </div>
            </form>
        </Col>
    );
};
const MapStateToProps = (state) => {
    return {
        user: state.Pizza.token,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        tokenHandler: (value) => dispatch({ type: 'TOKEN', value: value }),
    };
};
export default connect(MapStateToProps, mapDispatchToProps)(Account);
