import React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { AlertCircle, X } from 'react-feather';
import Header from './Header';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';

const Deals = (props) => {
    const [desc, setdesc] = React.useState(-1);
    const [Deal, setDeal] = React.useState([]);
    const [dealCheck, setDealCheck] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const Deals = async () => {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/v1/deals`,
            headers: {
                Accept: 'application/json',
            },
        };

        const response = await axios(config);
        const types = response.data.response.data.deals.map((i) => ({
            ...i,
            dealItems: i.dealItems.map((deals) => ({
                ...deals,
                productDetail: [deals.productDetail].map((ing) => ({
                    ...ing,
                    groupsIngredients: ing.groupsIngredients.map((l) => ({
                        ...l,
                        addedIngredients: l.name !== 'Meats' && l.name !== 'Veggies' ? l.ingredientsItems.filter((filter) => filter.isSelected) : [],
                        size: 'Small Size',
                    })),
                })),
            })),
        }));
        setDeal(types);
        setLoading(true);
    };
    const checkDeal = async () => {
        var axios = require('axios');
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/v1/verify/deals`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${props.token.jwt}`,
                },
            };

            const response = await axios(config);
            setDealCheck(response.data.response.status);
        } catch (e) {
            setDealCheck(e.response.data.response.status);
        }
    };
    React.useEffect(() => {
        if (props.token) {
            Deals();
            checkDeal();
        }
        return () => {
            setDeal([]);
        };
    }, [props.token]);
    const dealHandler = (i) => {
        if (props.deal) {
            if (props.token.jwt) {
                if (dealCheck) {
                    props.productHandler([i]);
                    props.history.push(`/deal/${i.id}`);
                } else {
                    toast.error('Sorry! Deal buy once per day');
                }
            } else {
                toast.error('Sign In To Add Deal into Cart');
            }
        } else {
            toast.error('1 Deal Alreday Added To Cart');
        }
    };
    return (
        <React.Fragment>
            <Header props={props} />
            {loading ? (
                <Container>
                    <Row className="mt-3">
                        <h1 style={{ fontWeight: '600' }}>Deals</h1>
                    </Row>
                    <Row>
                        {Deal.map((i, k) => {
                            return (
                                <Col sm="6" className="mt-3 position-relative" key={k}>
                                    <div className={`d-flex productDes ${desc === k ? 'fade-in-image' : 'd-none'}`}>
                                        <Col sm="6">
                                            <img src={i.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        </Col>
                                        <Col sm="6" className='overflow-auto'>
                                            <div style={{ paddingLeft: '10px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className="dealDetails">
                                                        <label>{i.name}</label>
                                                        <p>{i.detail}</p>
                                                    </div>
                                                    <X style={{ cursor: 'pointer' }} onClick={() => setdesc(-1)} />
                                                </div>

                                                <span className="d-block">${i.price}</span>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="MenuInner">
                                        <Col sm="8" className="p-0">
                                            <label>{i.name}</label>
                                            <span className="d-block">${i.price}</span>
                                            {i.dealItems.map((dealItem) => {
                                                return dealItem.productDetail.map((d, dK) => {
                                                    return (
                                                        <span className="item" key={dK}>
                                                            {d.name}
                                                        </span>
                                                    );
                                                });
                                            })}
                                            <div className="form-group productConfirm" style={{ marginTop: '130px' }} onClick={() => dealHandler(i)}>
                                                <span>Add To Order</span>
                                            </div>
                                        </Col>
                                        <Col sm="4" className="productImgDiv">
                                            <AlertCircle onClick={() => setdesc(k)} style={{ cursor: 'pointer' }} />
                                            <img src={i.image} className="ProductImg" />
                                        </Col>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <Spinner style={{ color: '#ffc000' }} />
                </div>
            )}
        </React.Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        deals: state.Pizza.deals,
        token: state.Pizza.token,
        deal: state.Pizza.deal,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        productHandler: (value) => dispatch({ type: 'DEALS', value: value }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Deals);
