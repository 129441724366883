import React from "react";
import { connect } from 'react-redux';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkouForm";
import { Col, Row, Modal, ModalFooter, ModalHeader, ModalBody, Button, Collapse, Spinner, Table } from "reactstrap";
import axios from "axios";
import toast from 'react-hot-toast';
import { ChevronDown, ChevronUp } from "react-feather";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST);

const Stripe = (props) => {
    const [method, setMethod] = React.useState("");
    const [data, setData] = React.useState([]);
    const [cards, setCards] = React.useState([]);
    const [selected, setSelected] = React.useState();
    const [selectedCard, setSelectedCard] = React.useState();
    const [modal, setModal] = React.useState(false);
    const [collapse, setCollapse] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [saveCard, setSaveCard] = React.useState(false);
    const [tip, setTip] = React.useState(0);
    const [tipNo, setTipNo] = React.useState();
    const [custom, setCustom] = React.useState(false);

    const getCards = React.useCallback(() => {
        if (props.signedIn) {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/v1/user/cards`,
                headers: {
                    'Authorization': `Bearer ${props.token.jwt}`
                }
            };

            axios(config)
                .then(function (response) {
                    setCards(response.data.response.data);
                    response.data.response.data.length && setModal(true);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }, [props.token.jwt, props.signedIn]);

    React.useEffect(() => {
        if (props.cart) {
            const cart = [...props.cart];
            const isDeal = props.cart.map((i) => i.dealItems);
            if (isDeal[0]) {
                const id = props.cart.map((i) => i.dealItems && i.id);
                const name = props.cart.map((i) => i.dealItems && i.name);
                const deal = cart.map((i) => i.dealItems);
                const result = deal[0].map((i) => i.productDetail[0]).map((a) => {
                    return Object.assign({}, a, {
                        dealId: id[0],
                        price: +a.price ? +a.price : +a.smallPrice,
                        quantity: 1,
                        dealName: name[0]
                    });
                });
                const filter = cart.filter((f) => !f.dealItems);
                const filterData = filter.push(result);
                setData(filter.flat());
            } else {
                setData(props.cart);
            }

            getCards();
        }
    }, [props.token.jwt]);

    const orderHandler = React.useCallback((id) => {
        setLoading(true);
        const product = [...data];
        let order = [];
        order = product;
        let call;
        if (method === "CreditCard") {
            call = {
                subTotal: (data.reduce((a, b) => +a + +b.price, 0)).toFixed(2),
                total: totalAmount,
                discountAmount: props.discount ? props.discount : 0,
                remarks: order[0].remarks ? order[0].remarks : "",
                promoCode: props.coupon,
                isSchedule: props.values.Scheduled,
                scheduleDateTime: props.values.Scheduled ? props.values.time : "",
                contactLess: props.values.contactless,
                vehicleColor: props.values.contactless ? props.values.color : "",
                vehicle: props.values.contactless ? props.values.vehicle : "",
                placeFood: props.values.contactless ? props.values.placeFood : "",
                cardId: id ? id : selected ? selected : "",
                isSaveCreditCard: selectedCard ? true : saveCard,
                paymentType: method,
                tip: tip ? tip.toFixed(2) : 0,
                address: props.token.address,
                apartment: props.token.apartment,
                state: props.token.state,
                city: props.token.city,
                zipCode: props.token.zipCode,
                OrderType: props.option === "CARRYOUT" ? "Carryout" : "Delivery",
                deliveryCharges: props.option === "DELIVERY" && props.delivery,
                extension: props.token.extension,
                tax: ((6 / 100) * data.reduce((a, b) => +a + +b.price, 0)).toFixed(2),
                orderItems: order.map((i) => ({
                    dealId: i.dealId ? i.dealId : null,
                    dealName: i.dealName ? i.dealName : null,
                    productId: i.productId ? i.productId : i.id,
                    quantity: i.quantity ? i.quantity : 1,
                    productPrice: i.price ? Number(i.price).toFixed(2) : Number(i.smallPrice).toFixed(2),
                    price: +i.price ? +((i.price) / i.quantity).toFixed(2) : (+(i.smallPrice) / i.quantity).toFixed(2),
                    productSize: i.size ? i.size : 'Small Size',
                    personName: `${props.token.firstName} ${props.token.lastName}`,
                    customerRemarks: order[0].remarks ? order[0].remarks : "",
                    groups: i.groupsIngredients?.map((ing) => ({
                        name: ing.name,
                        isEnableOnSide: false,
                        notes: '',
                        ingredient: ing.addedIngredients?.map((pre) => ({
                            ingredientId: pre.ingredientId,
                            size: i.dealId ? "Small Size" : pre.size ? ing.name === "Choice of Crust" ? i.size : pre.size : i.size,
                            makingSize: ing.name === "Meats" || ing.name === "Veggies" ? pre.topping
                                ? pre.topping
                                : ""
                                : "",
                            price: pre.smallPrice,
                            name: pre.name
                        }))
                    })),
                })),
            };
        }
        else {
            call = {
                subTotal: (data.reduce((a, b) => +a + +b.price, 0)).toFixed(2),
                total: totalAmount,
                discountAmount: props.discount ? props.discount : 0,
                remarks: null,
                promoCode: props.coupon,
                isSchedule: props.values.Scheduled,
                scheduleDateTime: props.values.Scheduled ? props.values.time : "",
                contactLess: props.values.contactless,
                vehicleColor: props.values.contactless ? props.values.color : "",
                vehicle: props.values.contactless ? props.values.vehicle : "",
                placeFood: props.values.contactless ? props.values.placeFood : "",
                isSaveCreditCard: selectedCard ? true : saveCard,
                paymentType: method,
                tip: tip ? tip.toFixed(2) : 0,
                tax: ((6 / 100) * data.reduce((a, b) => +a + +b.price, 0)).toFixed(2),
                address: props.token.address,
                apartment: props.token.apartment,
                state: props.token.state,
                city: props.token.city,
                zipCode: props.token.zipCode,
                extension: props.token.extension,
                OrderType: props.option === "CARRYOUT" ? "Carryout" : "Delivery",
                deliveryCharges: props.option === "DELIVERY" ? props.delivery : "0",
                orderItems: order.map((i) => ({
                    dealId: i.dealId ? i.dealId : null,
                    dealName: i.dealName ? i.dealName : null,
                    productId: i.productId ? i.productId : i.id,
                    quantity: i.quantity ? i.quantity : 1,
                    productPrice: +i.price ? (+i.price).toFixed(2) : (+i.smallPrice).toFixed(2),
                    productSize: i.size ? i.size : 'Small Size',
                    price: i.price ? +((i.price) / i.quantity).toFixed(2) : (+(i.smallPrice) / i.quantity).toFixed(2),
                    personName: `${props.token.firstName} ${props.token.lastName}`,
                    customerRemarks: order[0].remarks ? order[0].remarks : "",
                    groups: i.groupsIngredients?.map((ing) => ({
                        name: ing.name,
                        isEnableOnSide: false,
                        notes: '',
                        ingredient: ing.addedIngredients?.map((pre) => ({
                            ingredientId: pre.ingredientId,
                            size: i.dealId ? "Small Size" : pre.size ? ing.name === "Choice of Crust" ? i.size : pre.size : i.size,
                            makingSize: ing.name === "Meats" || ing.name === "Veggies" ? pre.topping
                                ? pre.topping
                                : ""
                                : "",
                            price: pre.smallPrice,
                            name: pre.name
                        }))
                    })),
                })),
            };
        }

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/v1/user/orders`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${props.token.jwt}`,
            },
            data: call,
        };

        axios(config).then((response) => {
            if (props.signedIn) {
                toast.success(`Order Placed (Order Id ${response.data.response.order.orderNo})`);
                props.dealHandler(true);
                props.cartHandler([]);
                props.discountHandler("");
                props.history.push('/');
            } else {
                toast.success(`Order Placed (Order Id ${response.data.response.order.orderNo})`);
                props.dealHandler(true);
                props.cartHandler([]);
                props.discountHandler("");
                props.tokenHandler([]);
                props.history.push('/');
            }

        });

    }, [props, data, method, saveCard, tip, selected]);

    const tipHandler = React.useCallback((amount, custom) => {
        if (custom) {
            setTip(Number(amount));
            setCustom(true)
        } else {
            const TipP = Number((data.reduce((a, b) => +a + +b.price, 0)));
            const Tip = (amount / 100) * TipP;
            setTip(Tip);
            setTipNo(amount)
            setCustom(false)
        }
    }, [data, setTip, setTipNo, setCustom]);

    const totalAmount = React.useMemo(() => {
        const total = +(data.reduce((a, b) => +a + +b.price, 0) + (6 / 100) * +data.reduce((a, b) => +a + +b.price, 0) + +tip - +props.discount).toFixed(2)
        if (props.option === "DELIVERY") {
            return (total + props.delivery).toFixed(2);
        } else {
            return total.toFixed(2);
        }

    }, [props.option, props.delivery, data, tip, props.discount]);

    return <div>
        <h1>Payment</h1>
        <Row className="mt-5 mb-5">
            <Row>
                <h4>Pay Online</h4>
                <div d-flex align-items-center>
                    <input type="radio" id="online" name="method" checked={method === "CreditCard"} value="CreditCard" style={{ marginRight: "10px" }} onChange={(e) => setMethod(e.target.value)} />
                    <b for="method">Credit card</b>
                </div>
                <Row>
                    {method === "CreditCard" && <Row className="mt-3">
                        <Col sm="6">
                            {!selected
                                ? <Elements stripe={stripePromise}>
                                    <label className="mb-2 d-flex align-items-center">
                                        <input type="checkbox" name="remember" width="15px" height="15px" onChange={(e) => setSaveCard(e.target.checked)} />
                                        &nbsp; <b>Save Card</b>
                                    </label>
                                    <div className="mb-2">
                                        <h5>Tip</h5>
                                        <div className="d-flex">
                                            <button
                                                onClick={() => tipHandler(25)}
                                                style={{ borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: "10px 10px", background: "#fff", border: tipNo === 25 ? "1px solid #ffc000" : "none", color: "#000", marginRight: "5px" }}
                                            >
                                                25%
                                            </button>
                                            <button
                                                onClick={() => tipHandler(20)}
                                                style={{ borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: "10px 10px", background: "#fff", border: tipNo === 20 ? "1px solid #ffc000" : "none", color: "#000", marginRight: "5px" }}
                                            >
                                                20%
                                            </button>
                                            <button
                                                onClick={() => tipHandler(15)}
                                                style={{ borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: "10px 10px", background: "#fff", border: tipNo === 15 ? "1px solid #ffc000" : "none", color: "#000", marginRight: "5px" }}
                                            >
                                                15%
                                            </button>
                                            <div style={{ marginLeft: "10px" }}>
                                                <label style={{ fontSize: "14px", display: "block" }}>or enter your own:</label>
                                                <input
                                                    pattern="/^-?\d+\.?\d*$/"
                                                    style={{ borderRadius: "10px", border: "1px solid", padding: "5px 15px", width: "150px" }}
                                                    type="tel"
                                                    maxLength="2"
                                                    onChange={(e) => {
                                                        tipHandler(e.target.value, true);
                                                    }}
                                                />
                                                {custom && <span style={{ marginLeft: "5px" }}>
                                                    {Math.trunc((tip / Number((data.reduce((a, b) => +a + +b.price, 0) + (6 / 100) * data.reduce((a, b) => +a + +b.price, 0)).toFixed(2)) * 100))}%
                                                </span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <CheckoutForm cart={props.cart} order={orderHandler} tip={tip} loading={loading} />
                                    </div>
                                </Elements>
                                : <div>
                                    <div className="mb-2">
                                        <h5>Tip</h5>
                                        <div className="d-flex">
                                            <button
                                                onClick={() => tipHandler(25)}
                                                style={{ borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: "10px 10px", background: "#fff", border: tipNo === 25 ? "1px solid #ffc000" : "none", color: "#000", marginRight: "5px" }}
                                            >
                                                25%
                                            </button>
                                            <button
                                                onClick={() => tipHandler(20)}
                                                style={{ borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: "10px 10px", background: "#fff", border: tipNo === 20 ? "1px solid #ffc000" : "none", color: "#000", marginRight: "5px" }}
                                            >
                                                20%
                                            </button>
                                            <button
                                                onClick={() => tipHandler(15)}
                                                style={{ borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", padding: "10px 10px", background: "#fff", border: tipNo === 15 ? "1px solid #ffc000" : "none", color: "#000", marginRight: "5px" }}
                                            >
                                                15%
                                            </button>
                                            <div style={{ marginLeft: "10px" }}>
                                                <label style={{ fontSize: "14px", display: "block" }}>or enter your own:</label>
                                                <input
                                                    pattern="/^-?\d+\.?\d*$/"
                                                    style={{ borderRadius: "10px", border: "1px solid", padding: "5px 15px", width: "150px" }}
                                                    type="tel"
                                                    maxLength="2"
                                                    onChange={(e) => {
                                                        tipHandler(e.target.value, true);
                                                    }}
                                                />
                                                {custom && <span style={{ marginLeft: "5px" }}>
                                                    {Math.trunc((tip / Number((data.reduce((a, b) => +a + +b.price, 0) + (6 / 100) * data.reduce((a, b) => +a + +b.price, 0)).toFixed(2)) * 100))}%
                                                </span>}
                                            </div>
                                        </div>
                                    </div>
                                    {selectedCard && <Table striped>
                                        <thead>
                                            <tr>
                                                <th>Card #</th>
                                                <th>Card</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>****{selectedCard.lastDigits}</td>
                                                <td><img src={selectedCard.cardIcon} /></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    }
                                    <Button
                                        style={{ backgroundColor: "#ffc000", borderColor: "#ffc000" }}
                                        className="w-100 mt-4"
                                        onClick={() => orderHandler()}
                                    >
                                        {loading ? <Spinner style={{ color: "ffc000" }} /> : "CheckOut"}
                                    </Button>
                                </div>
                            }
                        </Col>
                    </Row>
                    }
                </Row>
            </Row>
            {props.option === "CARRYOUT" && <Row className="mt-5 mb-5">
                <h4>Pay at store</h4>
                <div>
                    <input type="radio" id="online" name="method" value="CashOnPickup" style={{ marginRight: "10px" }} onChange={(e) => setMethod(e.target.value)} />
                    <b for="method">pay at the counter using cash, credit or gift card.</b>
                </div>
            </Row>}
            <hr className="mt-4" />
            <div>
                <div role="button" onClick={() => setCollapse(!collapse)} className="mb-2 d-flex">
                    {collapse ? <ChevronUp /> : <ChevronDown />}<h4>Order Summary</h4>
                </div>
                <Collapse isOpen={collapse}>
                    <div>
                        {data.map((item, index) => {
                            const added = item.groupsIngredients.map((m) => {
                                return m.addedIngredients?.map((m1) => {
                                    return m1.name ? m1.name : m1.ingredientName;
                                });
                            });
                            const extra = item.groupsIngredients.map((m) => {
                                return m.addedExtra?.map((m1) => {
                                    return m1.name ? m1.name : m1.ingredientName;
                                });
                            });
                            const array = added.concat(extra);

                            const alterArray = array.flat();
                            return <div className="border p-4 d-flex justify-content-between align-items-center rounded" key={index}>
                                <div className="d-flex">
                                    <div>
                                        <img src={item.image} style={{ width: "60px", height: "60px", marginRight: "20px", borderRadius: 8 }} />
                                    </div>
                                    <h4 style={{ marginRight: 10 }}>{item.name}</h4>
                                    <p>
                                        <>
                                            {alterArray.filter(Boolean).map((ing, ingKey) => {
                                                return (
                                                    <span style={{ fontSize: '14px', color: '#677078' }} key={`demo_snap_${ingKey}`}>
                                                        {(ingKey ? ', ' : '') + ing}
                                                    </span>
                                                );
                                            })}
                                        </>
                                    </p>
                                </div>
                                <div>
                                    <b>${item.price}</b>
                                </div>
                            </div>
                        })}
                    </div>
                </Collapse>
            </div>
            <hr className="mt-3" />
            <Row>
                <h4>Cost Summary</h4>
                <Col sm="3">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                            <b>Subtotal</b>
                            <span>Tax</span>
                            {props.option === "DELIVERY" && props.delivery !== 0 ? <span>Delivery</span> : null}
                            {props.discount !== "" && <span>discount</span>}
                            {tip ? <span>Tip</span> : null}
                            <b>Total</b>
                        </div>
                        <div className="d-flex flex-column">
                            <b>$ {data.reduce((a, b) => +a + +b.price, 0).toFixed(2)}</b>
                            <span>$ {((6 / 100) * data.reduce((a, b) => +a + +b.price, 0)).toFixed(2)}</span>
                            {props.option === "DELIVERY" && props.delivery !== 0 ? <span>$ {props.delivery}</span> : null}
                            {props.discount !== "" && <span>${props.discount}</span>}
                            {tip ? <b>$ {tip.toFixed(2)}</b> : null}
                            {<b>$ {totalAmount}</b>}
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                {method === "CashOnPickup" &&
                    <>
                        <Button
                            style={{ backgroundColor: "#ffc000", borderColor: "#ffc000" }}
                            className="w-25 mt-4"
                            onClick={() => orderHandler()}
                        >
                            {loading ? <Spinner style={{ color: "ffc000" }} /> : "CheckOut"}
                        </Button>
                    </>
                }
            </Row>
        </Row>

        <Modal
            isOpen={modal}
            backdrop
        >
            <ModalHeader>
                Added Cards
            </ModalHeader>
            <ModalBody>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Card #</th>
                            <th>Card</th>
                            <th>Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cards.map((card, index) => (
                            <tr>
                                <th scope="row">{index + 1}</th>
                                <td>****{card.lastDigits}</td>
                                <td><img src={card.cardIcon} /></td>
                                <td><Button
                                    style={{ backgroundColor: "#ffc000", borderColor: "#ffc000" }}
                                    onClick={() => {
                                        setSelected(card.id);
                                        setSelectedCard(card);
                                        setModal();
                                        setMethod("CreditCard");
                                    }}>
                                    Select
                                </Button></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={() => {
                        setSelected();
                        setModal();
                        setMethod("CreditCard");
                    }}
                >
                    Add New Card
                </Button>
            </ModalFooter>
        </Modal>
    </div >
}

const MapStateToProps = (state) => {
    return {
        token: state.Pizza.token,
        cart: state.Pizza.cart,
        option: state.Pizza.option,
        signedIn: state.Pizza.signedIn,
        discount: state.Pizza.discount,
        coupon: state.Pizza.coupon
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        cartHandler: (value) => dispatch({ type: 'CART', value: value }),
        dealHandler: (value) => dispatch({ type: 'DEAL', value: value }),
        discountHandler: (value) => dispatch({ type: 'DISCOUNT', value: value }),
        tokenHandler: (value) => dispatch({ type: 'TOKEN', value: value }),
    };
};
export default connect(MapStateToProps, mapDispatchToProps)(Stripe);