import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Images from './Constants';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { CheckCircle, ChevronDown, ChevronsDown, ChevronUp } from 'react-feather';
import toast from 'react-hot-toast';
import Header from './Header';
const StromboliMaker = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [crust, setCrust] = useState([]);
  const [sauce, setSauce] = useState([]);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalImg, setModalImg] = useState();
  const [modalName, setModalName] = useState();
  const [seeMore, setSeeMore] = useState([3, '']);
  React.useEffect(() => {
    if (props.product) {
      const quantitySet = props.product.map((i) => {
        return Object.assign({}, i, {
          toppingPrice: 0,
          crustPrice: 0,
          saucePrice: 0,
          cheesePrice: 0,
        });
      });
      setData(quantitySet);
    }
  }, [props.product]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    const Crust = Images.Crust.filter((f) => {
      return f.name === props.Crust;
    });
    setCrust(Crust);
    const Sauce = Images.Sauce.filter((f) => {
      return f.name === props.Sauce;
    });
    setSauce(Sauce);
  }, [props]);

  const crustHandler = (l, group) => {
    const product = [...data];
    const quantitySet = product.map((i) => {
      return Object.assign({}, i, {
        price:
          i.size === 'Small Size'
            ? +i.price + +l.smallPrice
            : i.size === 'Medium Size'
              ? +i.price + +l.mediumPrice
              : i.size === 'Large Size'
                ? +i.price + +l.largePrice
                : i.size === 'Extra Large Size'
                  ? +i.price + +l.largePrice
                  : null,
      });
    });
    const filter = quantitySet.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });
    const removedIngredient = filter[0].map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === l.name) }));

    var editedUser = removedIngredient[0];
    const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
    setData(users);
  };
  const sauceHandler = (l, group) => {
    const product = [...data];
    const filter = product.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });

    const removedIngredient = filter.flat().map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === l.name).map((k) => ({ ...k, size: 'Small Size' })) }));
    var editedUser = removedIngredient[0];
    const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
    const crustPrice = removedIngredient.flat().map((k) =>
      k.addedIngredients.map((price) => {
        if (product[0].size === 'Small Size') {
          return price.smallPrice;
        }
      })
    );
    const priceFlat = crustPrice.flat();
    const price = priceFlat.flat()[0];
    const quantitySet = users.map((i) => {
      return Object.assign({}, i, {
        saucePrice: price,
      });
    });
    setData(quantitySet);
  };
  const cheeseHandler = (l, group) => {
    const product = [...data];
    const filter = product.map((i) => {
      return i.groupsIngredients.filter((filter) => {
        return filter.name === group.name;
      });
    });

    const removedIngredient = filter.flat().map((i) => ({ ...i, addedIngredients: i.ingredientsItems.filter((f) => f.name === l.name).map((k) => ({ ...k, size: 'Small Size' })) }));
    var editedUser = removedIngredient[0];
    const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
    const crustPrice = removedIngredient.flat().map((k) =>
      k.addedIngredients.map((price) => {
        if (product[0].size === 'Small Size') {
          return price.smallPrice;
        } else if (product[0].size === 'Medium Size') {
          return price.smallPrice;
        } else if (product[0].size === 'Large Size') {
          return price.smallPrice;
        } else if (product[0].size === 'Extra Large Size') {
          return price.extraLargePrice;
        }
      })
    );
    const priceFlat = crustPrice.flat();
    const price = priceFlat.flat();
    const quantitySet = users.map((i) => {
      return Object.assign({}, i, {
        cheesePrice: price,
      });
    });
    setData(quantitySet);
  };
  //  Topping
  const toppingHandler = (l, group) => {
    const product = [...data];
    const valid = product.map((i) => {
      return i.groupsIngredients
        .filter((filter) => {
          return filter.name === group.name;
        })
        .map((t) => {
          return t.addedIngredients?.find((f) => f.name === l.name);
        });
    });

    if (!valid[0][0]) {
      const quantitySet = data.map((i) => {
        return Object.assign({}, i, {
          toppingPrice: +i.toppingPrice + +l.smallPrice,
        });
      });
      const filter = quantitySet.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const removedIngredient = filter[0].map((ing) => ({
        ...ing,
        addedIngredients:
          ing.addedIngredients === undefined
            ? ing.ingredientsItems.filter((f) => f.name === l.name).map((k) => ({ ...k, size: 'Small Size', topping: 'whole' }))
            : ing.ingredientsItems
              .filter((f) => f.name === l.name)
              .map((k) => ({ ...k, size: 'Small Size', topping: 'whole' }))
              .concat(ing.addedIngredients),
      }));

      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    } else {
      const quantitySet = data.map((i) => {
        return Object.assign({}, i, {
          toppingPrice: i.toppingPrice - l.smallPrice,
        });
      });
      const filter = quantitySet.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const removedIngredient = filter[0].map((ing) => ({ ...ing, addedIngredients: ing.addedIngredients.filter((f) => f.name !== l.name) }));
      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    }
  };
  const ingredientHandler = (i, group) => {
    switch (group.name) {
      case 'Choice of Crust':
        crustHandler(i, group);
        break;
      case 'Choice of Sauce':
        sauceHandler(i, group);
        break;
      case 'Cheese':
        cheeseHandler(i, group);
        break;
      case 'Meats':
        toppingHandler(i, group);
        break;
      case 'Veggies':
        toppingHandler(i, group);
        break;
      case 'Choice of Ingredients':
        ingredientsHandler(i, group);
        break;
    }
  };
  const ingredientsHandler = (l, group) => {
    const product = [...data];
    const valid = product.map((i) => {
      return i.groupsIngredients
        .filter((filter) => {
          return filter.name === group.name;
        })
        .map((t) => {
          return t.addedIngredients?.find((f) => f.name === l.name);
        });
    });

    if (!valid[0][0]) {
      const quantitySet = data.map((i) => {
        return Object.assign({}, i, {
          price: +i.price + +l.smallPrice,
        });
      });
      const filter = quantitySet.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const removedIngredient = filter[0].map((ing) => ({
        ...ing,
        addedIngredients: ing.addedIngredients === undefined ? ing.ingredientsItems.filter((f) => f.name === l.name) : ing.ingredientsItems.filter((f) => f.name === l.name).concat(ing.addedIngredients),
      }));
      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    } else {
      const quantitySet = data.map((i) => {
        return Object.assign({}, i, {
          price: i.price - l.smallPrice,
        });
      });
      const filter = quantitySet.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const removedIngredient = filter[0].map((ing) => ({ ...ing, addedIngredients: ing.addedIngredients.filter((f) => f.name !== l.name) }));
      var editedUser = removedIngredient[0];
      const users = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== removedIngredient[0].name ? u : editedUser)) }));
      setData(users);
    }
  };
  const addtocartHandler = (i) => {
    const cart = props.cart;
    let valid = cart.find((f) => {
      return f.name === i[0].name;
    });
    const quantitySet = data.map((i) => {
      return Object.assign({}, i, {
        price: +data[0].price + +data[0].crustPrice + +data[0].saucePrice + +data[0].toppingPrice + +data[0].cheesePrice,
        basePrice: +data[0].price + +data[0].crustPrice + +data[0].saucePrice + +data[0].toppingPrice + +data[0].cheesePrice,
      });
    });
    if (cart.length === 0) {
      props.cartHandler([quantitySet[0]]);
      toast.success(`${quantitySet[0].name} Added To Cart`);
      props.history.push('/cart');
    } else {
      if (!valid) {
        const data1 = cart.concat(quantitySet[0]);
        props.cartHandler(data1);
        toast.success(`${quantitySet[0].name} Added To Cart`);
        props.history.push('/cart');
      } else {
        const users = props.cart.map((u) => (u.id !== quantitySet[0].id ? u : quantitySet[0]));
        props.cartHandler(users);
        toast.success(`${quantitySet[0].name} Already Added To Cart`);
        props.history.push('/cart');
      }
    }
  };

  const ingredientSizeHandler = (group, i, item, ing) => {
    if (group.addedIngredients?.length) {
      switch (group.name) {
        case 'Choice of Sauce':
          sauceSizeHandler(group, i, item);
          break;
        case 'Cheese':
          cheeseSizeHandler(group, i, item);
          break;
        case 'Meats':
          toppingSizeHandler(group, i, item, ing);
          break;
        case 'Veggies':
          toppingSizeHandler(group, i, item, ing);
          break;
      }
    }
  };
  const toppingSizeHandler = (group, l, i, ing) => {
    if (l !== 'None') {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });

      const small = filter.flat().map((f) => ({ ...f, addedIngredients: f.addedIngredients.map((k) => ({ ...k, size: k.name === ing.name ? l : k.size })) }));
      var editedUser = small[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== small[0].name ? u : editedUser)) }));

      const size = filter.flat().map((k) =>
        k.addedIngredients
          .filter((f) => f.name === ing.name)
          .map((price) => {
            return price.size;
          })
      );
      const quantitySet = users.map((i) => {
        const price = size.flat()[0] === 'Small Size' ? +i.toppingPrice - +ing.smallPrice + +ing.extraLargePrice : +i.toppingPrice - +ing.extraLargePrice + +ing.smallPrice;
        return Object.assign({}, i, {
          toppingPrice: price,
        });
      });
      setData(quantitySet);
    } else {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients
          .filter((f) => f.name === ing.name)
          .map((price) => {
            if (price.size === 'Small Size') {
              if (price.topping === 'whole') {
                return price.smallPrice;
              } else {
                return price.smallPrice / 2;
              }
            } else if (price.size === 'Extra Large Size') {
              if (price.topping === 'whole') {
                return price.extraLargePrice;
              } else {
                return price.extraLargePrice / 2;
              }
            }
          })
      );
      const quantitySet = data.map((i) => {
        const small = i.toppingPrice - crustPrice.flat()[0];
        return Object.assign({}, i, {
          toppingPrice: small,
        });
      });
      const None = quantitySet.map((m) => ({
        ...m,
        groupsIngredients: m.groupsIngredients.map((g) => ({ ...g, addedIngredients: g.addedIngredients?.filter((f) => f.name !== ing.name) })),
      }));
      setData(None);
    }
  };
  const cheeseSizeHandler = (group, l) => {
    if (l !== 'None') {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const small = filter.flat().map((f) => ({ ...f, addedIngredients: f.addedIngredients.map((k) => ({ ...k, size: l })) }));

      var editedUser = small[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== small[0].name ? u : editedUser)) }));
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (l === 'Small Size') {
            return price.smallPrice;
          } else if (l === 'Medium Size') {
            return price.mediumPrice;
          } else if (l === 'Large Size') {
            return price.largePrice;
          } else if (l === 'Extra Large Size') {
            return price.extraLargePrice;
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.flat()[0];
      const quantitySet = users.map((i) => {
        return Object.assign({}, i, {
          cheesePrice: price,
        });
      });
      setData(quantitySet);
    } else {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (price.size === 'Small Size') {
            return price.smallPrice;
          } else if (price.size === 'Medium Size') {
            return price.mediumPrice;
          } else if (price.size === 'Extra Large Size') {
            return price.extraLargePrice;
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.flat()[0];
      const quantitySet = product.map((i) => {
        return Object.assign({}, i, {
          cheesePrice: Number(i.cheesePrice) - Number(price),
        });
      });

      const None = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((g) => ({ ...g, addedIngredients: g.name === group.name ? [] : g.addedIngredients })) }));
      setData(None);
    }
  };
  const sauceSizeHandler = (group, l) => {
    if (l !== 'None') {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const small = filter.flat().map((f) => ({ ...f, addedIngredients: f.addedIngredients.map((k) => ({ ...k, size: l })) }));

      var editedUser = small[0];
      const users = product.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((u) => (u.name !== small[0].name ? u : editedUser)) }));
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (l === 'Small Size') {
            return price.smallPrice;
          } else if (l === 'Medium Size') {
            return price.mediumPrice;
          } else if (l === 'Large Size') {
            return price.largePrice;
          } else if (l === 'Extra Large Size') {
            return price.extraLargePrice;
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.flat()[0];
      const quantitySet = users.map((i) => {
        return Object.assign({}, i, {
          saucePrice: price,
        });
      });
      setData(quantitySet);
    } else {
      const product = [...data];
      const filter = product.map((i) => {
        return i.groupsIngredients.filter((filter) => {
          return filter.name === group.name;
        });
      });
      const crustPrice = filter.flat().map((k) =>
        k.addedIngredients.map((price) => {
          if (price.size === 'Small Size') {
            return price.smallPrice;
          } else if (price.size === 'Extra Large Size') {
            return price.extraLargePrice;
          } else if (price.size === 'Medium Size') {
            return price.mediumPrice;
          }
        })
      );
      const priceFlat = crustPrice.flat();
      const price = priceFlat.flat()[0];
      const quantitySet = product.map((i) => {
        return Object.assign({}, i, {
          saucePrice: Number(i.saucePrice) - Number(price),
        });
      });

      const None = quantitySet.map((i) => ({ ...i, groupsIngredients: i.groupsIngredients.map((g) => ({ ...g, addedIngredients: g.name === group.name ? [] : g.addedIngredients })) }));
      setData(None);
    }
  };
  const seeMoreHandler = (group) => {
    if (seeMore[1] === group.name) {
      setSeeMore([100, '']);
    } else {
      setSeeMore([3, group.name]);
    }
  };
  var Price = undefined;
  if (data.length) {
    Price = +data[0].price + +data[0].crustPrice + +data[0].saucePrice + +data[0].toppingPrice + +data[0].cheesePrice;
  }
  return (
    <div>
      <Header props={props} />
      {Object.keys(data).length ? (
        <Container className="mt-3">
          <Row>
            <h1 className="text-center">{data[0].name}</h1>
          </Row>
          <Row>
            <Col sm="12" lg="6" cl="6" className="mobileHide">
              <div style={{ position: 'relative' }}>
                <img src={data[0].image} style={{ position: 'absolute' }} />
              </div>
            </Col>
            <Col sm="12" lg="6" cl="6">
              <Row className="mt-3">
                <div className="productInnerHeader">
                  <h1 style={{ fontSize: '20px' }}>${Price.toFixed(2)}</h1>
                  <Button size="lg" color="danger" onClick={() => addtocartHandler(data)}>
                    Done
                  </Button>
                </div>
              </Row>
              <Row className="mt-3 tabsBorder">
                <Nav tabs className="nav-justified p-0">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      SAUCE AND CHEESE
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      TOPPINGS
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {data.map((i) => {
                      return i.groupsIngredients
                        ?.filter((filter) => {
                          return filter.name !== 'Meats' && filter.name !== 'Veggies';
                        })
                        .map((group, groupKey) => {
                          return (
                            <React.Fragment key={groupKey}>
                              <Row>
                                <Col sm="12" md="6" lg="12" className="p-0">
                                  <h3 className="crust">
                                    <span>{group.name}</span>
                                  </h3>
                                </Col>
                              </Row>
                              <Row>
                                {group.ingredientsItems?.map((ing, ingKey) => {
                                  return (
                                    <Col key={ingKey} sm="3">
                                      <div className={`${group.addedIngredients?.find((item) => item.name === ing.name) ? 'activeCrust' : ''} crustPreview`} onClick={() => ingredientHandler(ing, group)}>
                                        {group.addedIngredients?.find((item) => item.name === ing.name) ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000' }} /> : null}
                                        <img src={ing.image} />
                                      </div>
                                      <div className="crustDesc">
                                        <span>{`${ing.name}`}</span>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                              {group.name === 'Choice of Crust' ? (
                                <Row className="mt-2">
                                  <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Size</span>
                                  <Col sm="4">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Small', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Small') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Small</p>
                                    </h4>
                                  </Col>
                                  <Col sm="4">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Medium', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Medium') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Medium</p>
                                    </h4>
                                  </Col>
                                  <Col sm="4">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Large', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Large') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Large</p>
                                    </h4>
                                  </Col>
                                  <Col sm="4">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Extra', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Extra') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Extra Large</p>
                                    </h4>
                                  </Col>
                                </Row>
                              ) : group.name === 'Choice of Sauce' ? (
                                <Row className="mt-2">
                                  <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Amount</span>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Extra Large Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Extra Large Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Extra</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Medium Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Medium Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Regular</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Small Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Small Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Light</p>
                                    </h4>
                                  </Col>

                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'None', i)}>
                                      <p className="m-0">None</p>
                                    </h4>
                                  </Col>
                                </Row>
                              ) : group.name === 'Cheese' ? (
                                <Row className="mt-2">
                                  <span style={{ fontSize: '20px', fontWeight: '600', marginLeft: '10px', marginBottom: '10px' }}>Amount</span>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Extra Large Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Extra Large Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Extra</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Medium Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Medium Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Regular</p>
                                    </h4>
                                  </Col>
                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'Small Size', i)}>
                                      {group.addedIngredients?.find((find) => find.size === 'Small Size') ? <CheckCircle style={{ position: 'absolute', right: 0, color: '#ffc000', top: '-10px' }} /> : null}
                                      <p className="m-0">Light</p>
                                    </h4>
                                  </Col>

                                  <Col sm="3">
                                    <h4 className="sizes" onClick={() => ingredientSizeHandler(group, 'None', i)}>
                                      <p className="m-0">None</p>
                                    </h4>
                                  </Col>
                                </Row>
                              ) : null}
                            </React.Fragment>
                          );
                        });
                    })}
                  </TabPane>
                  <TabPane tabId="2">
                    {data.map((i) => {
                      return i.groupsIngredients
                        ?.filter((filter) => {
                          return filter.name === 'Meats';
                        })
                        .map((group, groupKey) => {
                          return (
                            <React.Fragment key={groupKey}>
                              <Row>
                                <Col sm="12" md="6" lg="12" className="p-0">
                                  <h3 className="crust">
                                    <span>{group.name}</span>
                                  </h3>
                                </Col>
                              </Row>
                              <Row>
                                {group.ingredientsItems?.slice(0, seeMore[1] === group.name ? 100 : 3).map((ing, ingKey) => {
                                  return (
                                    <div key={ingKey} className={`peproni p-2 mb-2 mt-2 ${group.addedIngredients?.find((find) => find.name === ing.name) ? 'activeItem' : ''}`}>
                                      {group.addedIngredients?.find((find) => find.name === ing.name) ? <CheckCircle className="circleTop" /> : null}
                                      <div onClick={() => ingredientHandler(ing, group)}>
                                        <img src={ing.thumbnail} className="toppings" />
                                        <label style={{ marginLeft: '20px' }}>{ing.name}</label>
                                      </div>
                                      <Row className={`mt-2 ${group.addedIngredients?.find((find) => find.name === ing.name) ? 'opened' : 'closed'}`}>
                                        <Col sm="5" className="d-flex justify-content-around offset-md-2">
                                          <span
                                            className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Extra Large Size' && find.name === ing.name) ? 'selected' : ''}`}
                                            onClick={() =>
                                              !group.addedIngredients?.find((find) => find.size === 'Extra Large Size' && find.name === ing.name) ? ingredientSizeHandler(group, 'Extra Large Size', i, ing) : null
                                            }
                                          >
                                            Extra
                                          </span>
                                          <span
                                            className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Small Size' && find.name === ing.name) ? 'selected' : ''}`}
                                            onClick={() => (!group.addedIngredients?.find((find) => find.size === 'Small Size' && find.name === ing.name) ? ingredientSizeHandler(group, 'Small Size', i, ing) : null)}
                                          >
                                            Regular
                                          </span>
                                          <span className={`toppingsSize`} onClick={() => ingredientSizeHandler(group, 'None', i, ing)}>
                                            None
                                          </span>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })}
                                <span style={{ textAlign: 'center', color: '#265799', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => seeMoreHandler(group)}>
                                  {`${seeMore[1] === group.name ? 'See Less' : 'See More'}`}
                                  {seeMore[1] === group.name ? <ChevronUp style={{ color: '#ffc000' }} /> : <ChevronDown style={{ color: '#ffc000' }} />}
                                </span>
                              </Row>
                            </React.Fragment>
                          );
                        });
                    })}
                    {data.map((i) => {
                      return i.groupsIngredients
                        ?.filter((filter) => {
                          return filter.name === 'Veggies';
                        })
                        .map((group, groupKey) => {
                          return (
                            <React.Fragment key={groupKey}>
                              <Row>
                                <Col sm="12" md="6" lg="12" className="p-0">
                                  <h3 className="crust">
                                    <span>{group.name}</span>
                                  </h3>
                                </Col>
                              </Row>
                              <Row>
                                {group.ingredientsItems?.slice(0, seeMore[1] === group.name ? 100 : 3).map((ing, ingKey) => {
                                  return (
                                    <div key={ingKey} className={`peproni p-2 mb-2 mt-2 ${group.addedIngredients?.find((find) => find.name === ing.name) ? 'activeItem' : ''}`}>
                                      {group.addedIngredients?.find((find) => find.name === ing.name) ? <CheckCircle className="circleTop" /> : null}
                                      <div onClick={() => ingredientHandler(ing, group)}>
                                        <img src={ing.thumbnail} className="toppings" />
                                        <label style={{ marginLeft: '20px' }}>{ing.name}</label>
                                      </div>
                                      <Row className={`mt-2 ${group.addedIngredients?.find((find) => find.name === ing.name) ? 'opened' : 'closed'}`}>
                                        <Col sm="5" className="d-flex justify-content-around offset-md-2">
                                          <span
                                            className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Extra Large Size' && find.name === ing.name) ? 'selected' : ''}`}
                                            onClick={() =>
                                              !group.addedIngredients?.find((find) => find.size === 'Extra Large Size' && find.name === ing.name) ? ingredientSizeHandler(group, 'Extra Large Size', i, ing) : null
                                            }
                                          >
                                            Extra
                                          </span>
                                          <span
                                            className={`toppingsSize ${group.addedIngredients?.find((find) => find.size === 'Small Size' && find.name === ing.name) ? 'selected' : ''}`}
                                            onClick={() => (!group.addedIngredients?.find((find) => find.size === 'Small Size' && find.name === ing.name) ? ingredientSizeHandler(group, 'Small Size', i, ing) : null)}
                                          >
                                            Regular
                                          </span>
                                          <span className={`toppingsSize`} onClick={() => ingredientSizeHandler(group, 'None', i, ing)}>
                                            None
                                          </span>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                })}
                                <span style={{ textAlign: 'center', color: '#265799', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => seeMoreHandler(group)}>
                                  {`${seeMore[1] === group.name ? 'See Less' : 'See More'}`}
                                  {seeMore[1] === group.name ? <ChevronUp style={{ color: '#ffc000' }} /> : <ChevronDown style={{ color: '#ffc000' }} />}
                                </span>
                              </Row>
                            </React.Fragment>
                          );
                        });
                    })}
                  </TabPane>
                </TabContent>
              </Row>
            </Col>
          </Row>
        </Container>
      ) : null}

      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader className="pb-0" toggle={() => setModal(false)}>
          {modalName}
        </ModalHeader>
        <ModalBody className="d-flex pt-0">
          <Col sm="3" className="p-2">
            <img src={modalImg} width="100%" />
          </Col>
          <Col sm="9" className="p-2">
            <p style={{ fontSize: '14px' }}>The legendary Original Pan® Pizza has a thick crust that’s baked to golden perfection, crispy on the outside, soft and buttery on the inside.</p>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    Crust: state.Pizza.crust,
    Sauce: state.Pizza.sauce,
    Cheese: state.Pizza.cheese,
    product: state.Pizza.product,
    cart: state.Pizza.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    crustHandler: (value) => dispatch({ type: 'CRUST', value: value }),
    sauceHandler: (value) => dispatch({ type: 'SAUCE', value: value }),
    cheeseHandler: (value) => dispatch({ type: 'CHEESE', value: value }),
    cartHandler: (value) => dispatch({ type: 'CART', value: value }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StromboliMaker);
